import Skyflow from 'skyflow-js'
import axios from 'axios'

const skyflowClient = Skyflow.init({
    vaultID: 'a1dc451ba28e4c2f8d946ea5efd7df35',
    vaultURL: 'https://e3540c874ee5.vault.skyflowapis.com',
    getBearerToken: () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}` + 'auctionpay/generateSkyflowToken',
                )
                // console.log(res)
                resolve(res.data.accessToken)
            } catch (err) {
                // console.log(err)
                reject('Cannot get token')
            }
        })
    },
    options: {
        logLevel: Skyflow.LogLevel.ERROR,
        env: Skyflow.Env.DEV,
    },
})

export default skyflowClient
