import React, { useState, useEffect, useContext } from 'react'
// import CommonContext from '../../product/context/common/commonContext'
import { useDropzone } from 'react-dropzone'
import Reorder from 'react-reorder'
import move from 'lodash-move'
import { Tooltip } from '@material-ui/core'
import Resizer from 'react-image-file-resizer'
import { GetFileFromBase64 } from '../../utils/GetFileFromBase64'
import imageCompression from 'browser-image-compression'
const Uploader = (props) => {
    const [reload, setReload] = useState(false)

    // const commonContext = useContext(CommonContext)
    // const { uploadImage, uploadedFiles, resetDropZone } = commonContext
    const formik = props.formik

    const auctionAvatar = useDropzone({
        accept: props.accept,
        multiple: props.multiple,
        onDrop: (acceptedFiles) => {
            let arr = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            )

            // var formData = new FormData()
            // formData.append('folder', props.folder)
            // arr.forEach((value, key) => {
            //     formData.append('file_upload', value)
            // })
            // uploadImage(formData, props.name)
            if (props.compressImages == false) {
                if (props.multiple) {
                    let uploadedImage = []
                    arr &&
                        arr.map((uploaded) => {
                            uploadedImage.push(uploaded)
                        })

                    formik.setFieldValue(props.name, [
                        ...formik.values[props.name],
                        ...uploadedImage,
                    ])
                } else {
                    arr && arr.map((uploaded) => formik.setFieldValue(props.name, [uploaded]))
                }
            } else {
                if (props.multiple) {
                    let uploadedImage = []
                    const options = {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true,
                    }
                    arr &&
                        arr.map(async (uploaded, index) => {
                            if (
                                uploaded.type == 'image/jpeg' ||
                                uploaded.type == 'image/jpg' ||
                                uploaded.type == 'image/png' ||
                                uploaded.type == 'image/webp'
                            ) {
                                try {
                                    const compressedFile = await imageCompression(uploaded, options)
                                    console.log(compressedFile)
                                    var file = new File([compressedFile], compressedFile.name, {
                                        type: compressedFile.type,
                                    })
                                    console.log(file)
                                    uploadedImage.push(file)
                                    formik.setFieldValue(props.name, [
                                        ...formik.values[props.name],
                                        ...uploadedImage,
                                    ])
                                    // Resizer.imageFileResizer(
                                    //     // event.target.files[0],
                                    //     uploaded,
                                    //     800,
                                    //     800,
                                    //     'WEBP',
                                    //     80,
                                    //     0,
                                    //     (uri) => {
                                    //         arr[0].preview = uri
                                    //         let fileGenerated = GetFileFromBase64(
                                    //             uri,
                                    //             arr[0].name.split('.')[0],
                                    //         )
                                    //         uploadedImage.push(fileGenerated)
                                    //         Object.assign(uploadedImage[index], {
                                    //             preview: arr[index].preview,
                                    //         })
                                    //         // console.log(arr[index].name.split('.')[0], 'checkArr')
                                    //         formik.setFieldValue(props.name, [
                                    //             ...formik.values[props.name],
                                    //             ...uploadedImage,
                                    //         ])
                                    //     },
                                    //     'base64',
                                    //     500,
                                    //     500,
                                    // )
                                } catch (err) {
                                    // console.log(err, 'Compression Error')
                                }
                            }
                            if (uploaded.type == 'image/gif') {
                                if (props.multiple) {
                                    let uploadedImage = []
                                    arr &&
                                        arr.map((uploaded) => {
                                            uploadedImage.push(uploaded)
                                        })

                                    formik.setFieldValue(props.name, [
                                        ...formik.values[props.name],
                                        ...uploadedImage,
                                    ])
                                } else {
                                    arr &&
                                        arr.map((uploaded) =>
                                            formik.setFieldValue(props.name, [uploaded]),
                                        )
                                }
                            }
                        })
                } else {
                    arr &&
                        arr.map((uploaded) => {
                            if (
                                uploaded.type == 'image/jpeg' ||
                                uploaded.type == 'image/jpg' ||
                                uploaded.type == 'image/png' ||
                                uploaded.type == 'image/webp'
                            ) {
                                try {
                                    Resizer.imageFileResizer(
                                        // event.target.files[0],
                                        uploaded,
                                        800,
                                        800,
                                        'WEBP',
                                        80,
                                        0,
                                        (uri) => {
                                            arr[0].preview = uri
                                            let fileGenerated = [GetFileFromBase64(uri)]
                                            Object.assign(fileGenerated[0], {
                                                ...uploaded,
                                                original: arr[0].path,
                                            })
                                            formik.setFieldValue(props.name, fileGenerated)
                                        },
                                        'base64',
                                        500,
                                        500,
                                    )
                                } catch (err) {
                                    // console.log(err, 'Compression Error')
                                }
                            }

                            if (uploaded.type == 'image/gif') {
                                if (props.multiple) {
                                    let uploadedImage = []
                                    arr &&
                                        arr.map((uploaded) => {
                                            uploadedImage.push(uploaded)
                                        })

                                    formik.setFieldValue(props.name, [
                                        ...formik.values[props.name],
                                        ...uploadedImage,
                                    ])
                                } else {
                                    arr &&
                                        arr.map((uploaded) =>
                                            formik.setFieldValue(props.name, [uploaded]),
                                        )
                                }
                            }
                        })
                }
            }
            setReload(!reload)
            // resetDropZone(props.name)
        },
    })

    // useEffect(() => {
    //     // console.log('uploadedFiles', props.name, uploadedFiles, formik)
    //     // console.log('props.name === uploadedFiles.from', props.name === uploadedFiles.from)
    //     if (props.name === uploadedFiles.from && uploadedFiles.files.length) {
    //         console.log('uploadedFiles', uploadedFiles)
    //         if (props.multiple) {
    //             let uploadedImage = []
    //             uploadedFiles.files &&
    //                 uploadedFiles.files.map((uploaded) => {
    //                     uploadedImage.push(uploaded.file_name)
    //                 })
    //             formik.setFieldValue(props.name, [...formik.values[props.name], ...uploadedImage])
    //         } else {
    //             uploadedFiles.files &&
    //                 uploadedFiles.files.map((uploaded) =>
    //                     formik.setFieldValue(props.name, [uploaded.file_name]),
    //                 )
    //         }
    //         setReload(!reload)
    //         resetDropZone(uploadedFiles.from)
    //     }
    // }, [uploadedFiles])

    const getFileExtension = (file) => {
        let fileExtent = /[.]/.exec(file) ? /[^.]+$/.exec(file) : undefined
        return fileExtent && fileExtent[0]
    }

    const removeFile = (file, from) => {
        if (props.name === from) {
            let allFiles = formik.values[props.name].filter((fileUploaded) => fileUploaded !== file)
            formik.setFieldValue(props.name, allFiles)
            setReload(!reload)
        }
    }

    const removeExistingFile = (file, from) => {
        if (props.name === from) {
            let allFiles = formik.values[props.name].filter((fileUploaded) => fileUploaded !== file)
            formik.setFieldValue(props.name, allFiles)

            if (props.deleteArray) {
                let deletedImages = formik.values[props.deleteArray]
                deletedImages.push(file)
                formik.setFieldValue(props.deleteArray, deletedImages)
            }

            setReload(!reload)
        }
    }

    const viewFile = (file, from) => {
        if (props.name === from) {
            const newWindow = window.open(
                file.preview && file.type === 'image/gif'
                    ? file.preview
                    : file.preview
                    ? URL.createObjectURL(file)
                    : `${global.images_url}/${props.folder}/${file}`,
                '_blank',
                'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
        }
    }

    const viewExistingFile = (file, from) => {
        if (props.name === from) {
            const newWindow = window.open(
                `${global.images_url}${props.folder}/${file}`,
                '_blank',
                'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
        }
    }

    const onReorder = (e, from, to) => {
        let changedArray = move(formik.values[props.name], from, to)
        formik.setFieldValue([props.name], changedArray)
    }

    return (
        <>
            <div>
                <section>
                    <div {...auctionAvatar.getRootProps({ className: 'dropzone' })}>
                        <input {...auctionAvatar.getInputProps()} />
                        <span className="material-icons">{props.icon}</span>
                        <h4 className="uplodrTitleText">{props.titleText}</h4>
                        <h6>{props.innerText}</h6>
                    </div>
                    <p className="validationError text-left">
                        {formik.touched[props.name] &&
                            formik.errors[props.name] &&
                            formik.errors[props.name]}
                    </p>
                    <aside className="thumbsContainer">
                        <>
                            {formik.values[props.name] && formik.values[props.name].length ? (
                                <>
                                    {props.reorder ? (
                                        <Reorder
                                            reorderId={props.titleText}
                                            reorderGroup={props.titleText}
                                            component="div"
                                            className="reorderCnt"
                                            draggedClassName="dragged"
                                            touchHoldTime={250}
                                            mouseHoldTime={100}
                                            onReorder={onReorder}
                                            autoScroll={true}
                                            disabled={false}
                                            disableContextMenus={true}
                                        >
                                            {formik.values[props.name].map((file, index) => (
                                                <Tooltip title={file.name} key={index}>
                                                    <div className="thumb" key={index}>
                                                        {typeof file == 'string' ? (
                                                            <>
                                                                <div className="thumbInner">
                                                                    <div className="thumbCnt">
                                                                        {file.includes('jpg') ||
                                                                        file.includes('jpeg') ||
                                                                        file.includes('png') ||
                                                                        file.includes('.webp') ? (
                                                                            <img
                                                                                src={`${global.images_url}${props.folder}/${file}`}
                                                                                className="img"
                                                                            />
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                {file.includes(
                                                                                    'pdf',
                                                                                )
                                                                                    ? 'picture_as_pdf'
                                                                                    : file.includes(
                                                                                          'doc',
                                                                                      ) ||
                                                                                      file.includes(
                                                                                          'docx',
                                                                                      )
                                                                                    ? 'description'
                                                                                    : file.includes(
                                                                                          'mp4',
                                                                                      ) ||
                                                                                      file.includes(
                                                                                          'mpeg',
                                                                                      )
                                                                                    ? 'movie'
                                                                                    : 'description'}
                                                                            </span>
                                                                        )}
                                                                        <div className="fileActions">
                                                                            <span
                                                                                onClick={() =>
                                                                                    removeExistingFile(
                                                                                        file,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="cancelBtn material-icons"
                                                                            >
                                                                                delete
                                                                            </span>
                                                                            <span
                                                                                onClick={() =>
                                                                                    viewExistingFile(
                                                                                        file,
                                                                                        props.name,
                                                                                    )
                                                                                }
                                                                                className="viewBtn material-icons"
                                                                            >
                                                                                visibility
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="thumbInner">
                                                                    {file.type === 'image/jpeg' ||
                                                                    file.type === 'image/png' ||
                                                                    file.type === 'image/webp' ||
                                                                    file.type === 'image/jpeg' ||
                                                                    file.type === 'image/gif' ? (
                                                                        <div className="thumbCnt">
                                                                            <img
                                                                                src={
                                                                                    file.preview ||
                                                                                    URL.createObjectURL(
                                                                                        file,
                                                                                    )
                                                                                }
                                                                                className="img"
                                                                                id={
                                                                                    'thumb_' + index
                                                                                }
                                                                            />
                                                                            <div className="fileActions">
                                                                                <span
                                                                                    onClick={() =>
                                                                                        removeFile(
                                                                                            file,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="cancelBtn material-icons"
                                                                                >
                                                                                    delete
                                                                                </span>
                                                                                <span
                                                                                    onClick={() =>
                                                                                        viewFile(
                                                                                            file,
                                                                                            props.name,
                                                                                        )
                                                                                    }
                                                                                    className="viewBtn material-icons"
                                                                                >
                                                                                    visibility
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <div className="thumbCnt">
                                                                                <div className="defaultThumb">
                                                                                    <span className="material-icons">
                                                                                        {getFileExtension(
                                                                                            file,
                                                                                        ) === 'pdf'
                                                                                            ? 'picture_as_pdf'
                                                                                            : getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'doc' ||
                                                                                              getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'docx'
                                                                                            ? 'description'
                                                                                            : getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'mp4' ||
                                                                                              getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'mpeg'
                                                                                            ? 'movie'
                                                                                            : getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'mpga' ||
                                                                                              getFileExtension(
                                                                                                  file,
                                                                                              ) ===
                                                                                                  'mp3'
                                                                                            ? 'volume_up'
                                                                                            : 'description'}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="fileActions">
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            removeFile(
                                                                                                file,
                                                                                                props.name,
                                                                                            )
                                                                                        }
                                                                                        className="cancelBtn material-icons"
                                                                                    >
                                                                                        delete
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            viewFile(
                                                                                                file,
                                                                                                props.name,
                                                                                            )
                                                                                        }
                                                                                        className="viewBtn material-icons"
                                                                                    >
                                                                                        visibility
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}

                                                        {props.fileName === true ? (
                                                            <p className="imgFlNm">{file.name}</p>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </Tooltip>
                                            ))}
                                        </Reorder>
                                    ) : (
                                        formik.values[props.name].map((file, index) => (
                                            <Tooltip
                                                title={
                                                    props.compressImages ? file.original : file.name
                                                }
                                                key={index}
                                            >
                                                <div className="thumb" key={index}>
                                                    <div className="thumbInner">
                                                        {file.type === 'image/jpeg' ||
                                                        file.type === 'image/png' ||
                                                        file.type === 'image/webp' ||
                                                        file.type === 'image/jpeg' ? (
                                                            <div className="thumbCnt">
                                                                <img
                                                                    src={`${file.preview}`}
                                                                    className="img"
                                                                    id={'thumb_' + index}
                                                                />
                                                                <div className="fileActions">
                                                                    <span
                                                                        onClick={() =>
                                                                            removeFile(
                                                                                file,
                                                                                props.name,
                                                                            )
                                                                        }
                                                                        className="cancelBtn material-icons"
                                                                    >
                                                                        delete
                                                                    </span>
                                                                    <span
                                                                        onClick={() =>
                                                                            viewFile(
                                                                                file,
                                                                                props.name,
                                                                            )
                                                                        }
                                                                        className="viewBtn material-icons"
                                                                    >
                                                                        visibility
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="thumbCnt">
                                                                    <div className="defaultThumb">
                                                                        <span className="material-icons">
                                                                            {getFileExtension(
                                                                                file,
                                                                            ) === 'pdf'
                                                                                ? 'picture_as_pdf'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'doc' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'docx'
                                                                                ? 'description'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'mp4' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'mpeg'
                                                                                ? 'movie'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'mpga' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'mp3'
                                                                                ? 'volume_up'
                                                                                : 'description'}
                                                                        </span>
                                                                    </div>
                                                                    <div className="fileActions">
                                                                        <span
                                                                            onClick={() =>
                                                                                removeFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="cancelBtn material-icons"
                                                                        >
                                                                            delete
                                                                        </span>
                                                                        <span
                                                                            onClick={() =>
                                                                                viewFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="viewBtn material-icons"
                                                                        >
                                                                            visibility
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    {props.fileName === true ? (
                                                        <p className="imgFlNm">{file.name}</p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </Tooltip>
                                        ))
                                    )}
                                </>
                            ) : null}
                        </>
                    </aside>
                </section>
            </div>
        </>
    )
}

export default Uploader
