/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Link, useHistory } from 'react-router-dom'
import {
    currencyFormat,
    dateFormatFront,
    handleRedirectInternal,
} from '../../../Product/common/components'
import { Redirect } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { Pagination } from '@material-ui/lab'
import Timer from '../../../Product/common/timer'
import { Button, Tooltip } from '@material-ui/core'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import { getImageurl, imageError } from '../../../utils/commonFunctions'
import Loader from '../../../assets/loader'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useTranslation } from 'react-i18next'

const Invoice = (props) => {
    const productContext = useContext(ProductContext)
    const {
        seller_allproducts,
        getAllSellerProducts,
        get_seller_dashboard,
        seller_dashboard_data,
    } = productContext
    const { t } = useTranslation()
    const authContext = useContext(AuthContext)
    const { isAuthenticated, isAdmin, user } = authContext
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [data, setData] = useState([])
    const [userData, setUserData] = useState({})
    const [sellerData, setSellerData] = useState({})
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const [toInvoice, settoInvoice] = useState(false)
    const [toInvoiceID, settoInvoiceID] = useState({
        inv_id: 0,
        user_id: 0,
    })

    const [search, setSearch] = useState({
        status: 'sold',
        limit: 12,
        page: 1,
        paid: props.page === 'paid' ? 1 : 0,
    })
    useEffect(() => {
        setLoading(false)
    }, [seller_allproducts])
    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    useEffect(() => {
        if (isAuthenticated) {
            setLoading(true)
            if (props.page === 'paid') {
                setLoading(true)
                getAllSellerProducts(search)
            } else if (props.page === 'unpaid') {
                setLoading(true)
                getAllSellerProducts(search)
            }
        }
    }, [search, isAuthenticated])

    return (
        <div>
            {toInvoice ? (
                <Redirect to={`invoice/${toInvoiceID.inv_id}/${toInvoiceID.user_id}`} />
            ) : null}
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : seller_allproducts.result.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="numCell">#</th>
                                    <th className="numCell">{t('invoice_Id')}</th>
                                    <th>{t('Image')}</th>
                                    <th className="invPdtName">{t('product_name')}</th>
                                    <th>{t('shipping_status')}</th>
                                    <th>{t('tracking_number')}</th>
                                    <th>{t('carrier_name')}</th>
                                </tr>
                            </thead>
                            {seller_allproducts.result.map((item, index) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.common_invoice}</td>
                                                <td>
                                                    <img
                                                        className="pro-img"
                                                        src={getImageurl(
                                                            item.store_id.toString(),
                                                            item.avatar,
                                                        )}
                                                        alt={item.title}
                                                        width="65"
                                                        height="65"
                                                        onError={(e) =>
                                                            (e.target.src = imageError())
                                                        }
                                                    />
                                                </td>
                                                <td>{item.title}</td>
                                                <td
                                                    style={
                                                        item.shipping_status == 'Shipped'
                                                            ? { color: 'green' }
                                                            : { color: 'red' }
                                                    }
                                                >
                                                    {item.shipping_status
                                                        ? item.shipping_status
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {item.tracking_number
                                                        ? item.tracking_number
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {item.tracking_service
                                                        ? item.tracking_service
                                                        : '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </>
                                )
                            })}
                        </table>
                    </div>
                    <div className="d-flex mt-3">
                        <div className="ml-auto mt-2">
                            {t('showing')} {search.limit * (search.page - 1) + 1} -{' '}
                            {search.limit * search.page > seller_allproducts.length
                                ? seller_allproducts.length
                                : search.limit * search.page}{' '}
                            of {seller_allproducts.length}
                        </div>
                        <Pagination
                            count={Math.ceil(seller_allproducts.length / search.limit)}
                            page={search.page}
                            onChange={onHandlePage}
                        />
                    </div>
                    <MultisellerSlider
                        storeTheme="ArtAuction"
                        selectedLot={selectedProduct}
                        handleClose={handleClose}
                        type={props.type ? props.type : ''}
                    />
                </>
            ) : (
                <>
                    <NoRecordsFound />
                </>
            )}
        </div>
    )
}

export default Invoice
