import { Button } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import HowBidPopup from '../../../Component/HowBiddingWorks'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Copyrightinfo, Logo } from '../../../utils'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AuthContext from '../../../Product/context/auth/authContext'
import jsonp from 'jsonp'
import queryString from 'query-string'
import './Footer.css'
import Footernav from '../../../utils/FooterNavigation/footernav'
function Footer() {
    const [inpData, setinpData] = useState('')
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext

    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const [hbid, setHBid] = React.useState(false)

    const { user, isAuthenticated, logout } = useContext(AuthContext)

    const forMobile = useMediaQuery('(max-width:1024px)')

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }

    return (
        <>
            {global?.storeConfigration?.subscribe?.value && (
                <div className="homeContact noPrint">
                    <h2>Get the latest deals</h2>
                    <p>Subscribe us to get latest updates</p>
                    <div className="subsBox">
                        <input
                            type="text"
                            name="q"
                            value={inpData}
                            placeholder="Enter email address"
                            onChange={(e) => {
                                setinpData(e.target.value)
                            }}
                        />
                        <Button>
                            <span className="material-icons" onClick={subscribe}>
                                send
                            </span>
                        </Button>
                    </div>
                </div>
            )}
            <footer className="footer-main-standard noPrint">
                <div className="">
                    <div className="ftrDtlCntnr">
                        <div className="customContainer d-flex align-items-center justify-content-between text-center">
                            <div className="footLogoCnt">
                                <Logo className="footerLogo" />
                                <Copyrightinfo />
                            </div>
                            {forMobile ? (
                                <div className="footerMisc">
                                    <ul className="footerLinks d-flex flex-column align-items-center">
                                        <li className="m-0 mb-2">
                                            <NavLink activeClassName="active" to="/contact_us">
                                                Contact Us
                                            </NavLink>
                                        </li>
                                        <li className="m-0">
                                            <NavLink activeClassName="active" to="/termsOfService">
                                                Terms & Conditions
                                            </NavLink>
                                        </li>
                                    </ul>

                                    <ul className="footer-main-standard-social-list">
                                        {facebookLink && (
                                            <li className="facebook">
                                                <a
                                                    href={facebookLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Facebook"
                                                >
                                                    <i className="fab fa-facebook"></i>
                                                </a>
                                            </li>
                                        )}
                                        {facebookLink && (
                                            <li className="pinterest">
                                                <a
                                                    href={twitterLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Pinterest"
                                                >
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        )}
                                        {googleLink && (
                                            <li className="instagram">
                                                <a
                                                    href={googleLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-linkedin"></i>
                                                </a>
                                            </li>
                                        )}
                                        {instagramLink && (
                                            <li className="instagram">
                                                <a
                                                    href={instagramLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                <div className="footerMisc">
                                    <ul className="footerLinks">
                                        {/* <li onClick={() => setHBid(true)}>
                                        <a className="cursorDecoy">How does bidding work</a>
                                    </li> */}
                                        <li>
                                            <NavLink activeClassName="active" to="/how_it_works">
                                                How It Works
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/termsOfService">
                                                Terms & Conditions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/privacy_policy">
                                                Privacy Policy
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/faq">
                                                FAQs
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/aboutus">
                                                About Us
                                            </NavLink>
                                        </li>
                                        <Footernav />
                                    </ul>

                                    <ul className="footer-main-standard-social-list">
                                        {facebookLink && (
                                            <li className="facebook">
                                                <a
                                                    href={facebookLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Facebook"
                                                >
                                                    <i className="fab fa-facebook"></i>
                                                </a>
                                            </li>
                                        )}
                                        {facebookLink && (
                                            <li className="pinterest">
                                                <a
                                                    href={twitterLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Pinterest"
                                                >
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        )}
                                        {googleLink && (
                                            <li className="instagram">
                                                <a
                                                    href={googleLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-linkedin"></i>
                                                </a>
                                            </li>
                                        )}
                                        {instagramLink && (
                                            <li className="instagram">
                                                <a
                                                    href={instagramLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Instagram"
                                                >
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <HowBidPopup open={hbid} handleClose={() => setHBid(false)} />
            </footer>
        </>
    )
}

export default Footer
