import React, { useContext, useState, useEffect } from 'react'
import BioCard from './BioCard'
import UserContext from '../../../Product/context/user/userContext'
import ProductContext from '../../../Product/context/product/productContext'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
const Seller = ({ searchbar, type, index }) => {
    const [loading, setLoading] = useState(false)
    const [cancelRequest, setCancelRequest] = useState(undefined)
    const { t } = useTranslation()
    const cancelToken = axios.CancelToken.source()

    /* Seller Block */
    const { seller_list, getSellerList, responseStatus } = useContext(UserContext)
    const [sellerParams, setSellerParams] = useState({
        limit: 10,
        page: 1,
        searchbar: '',
        type: type === 'reseller' ? 1 : 2,
    })
    const [products, setProducts] = useState({
        data: [],
        total: 0,
    })
    const [artists, setArtists] = useState({
        data: [],
        total: 0,
    })
    const [resellers, setResellers] = useState({
        data: [],
        total: 0,
    })

    /* Product Block */
    const { search_allproducts, getAllSearchProducts } = useContext(ProductContext)
    const [productParams, setProductParams] = useState({
        sh_limit: 10,
        page: 1,
        orderby: '6',
        searchbar: '',
        from: 'auction_only',
        closed_auction_list: 0,
        art_type: 3,
    })
    const [selectedProduct, setSelectedProduct] = useState(null)
    const handleClose = () => setSelectedProduct(null)
    const handleClickpopup = async (id) => {
        setSelectedProduct(id)
    }

    /* Common Blocks */
    const handleClick = () => {
        if (type === 'gallery') {
            setProductParams((prev) => ({ ...prev, page: prev.page + 1 }))
        } else {
            setSellerParams((prev) => ({ ...prev, page: prev.page + 1 }))
        }
    }
    useEffect(() => {
        if (type === 'artist' || type === 'reseller') {
            setSellerParams({
                limit: 10,
                page: 1,
                type: type === 'reseller' ? 1 : 2,
            })
        }
        if (type === 'gallery') {
            setProductParams({ ...productParams, searchbar })
        }
    }, [type])

    useEffect(() => {
        if (type === 'gallery') {
            setProductParams({ ...productParams, searchbar })
        } else {
            setSellerParams({ ...sellerParams, searchbar })
        }
    }, [searchbar])

    useEffect(() => {
        if (cancelRequest) {
            cancelRequest.cancel()
        }
        setCancelRequest(cancelToken)
        if (type === 'gallery') {
            getAllSearchProducts(productParams, cancelToken)
        } else {
            getSellerList(sellerParams, cancelToken)
        }
    }, [sellerParams, productParams])

    useEffect(() => {
        if (type === 'gallery') {
            if (productParams.page === 1) {
                setProducts({
                    ...products,
                    data: search_allproducts.results,
                    total: search_allproducts.total_pagecnt,
                })
            } else {
                setProducts((prev) => ({
                    ...prev,
                    data: [...prev.data, ...search_allproducts.results],
                }))
            }
        }
        if (type === 'artist' && seller_list?.type == 2) {
            if (sellerParams.page === 1) {
                //console.log(seller_list, index, type)
                setArtists({
                    ...artists,
                    data: seller_list.data,
                    total: seller_list.total,
                })
            } else {
                setArtists((prev) => ({
                    ...prev,
                    data: [...prev.data, ...seller_list.data],
                }))
            }
        }
        if (type === 'reseller' && seller_list?.type == 1) {
            if (sellerParams.page === 1) {
                //console.log(seller_list, index, type)
                setResellers({
                    ...resellers,
                    data: seller_list.data,
                    total: seller_list.total,
                })
            } else {
                setResellers((prev) => ({
                    ...prev,
                    data: [...prev.data, ...seller_list.data],
                }))
            }
        }
    }, [seller_list, search_allproducts])
    return (
        <div>
            {/* <h4 className="mb-3 mt-4 mr-2 pgTtle">{t(type)}</h4> */}
            {(type === 'gallery' ? products : type === 'artist' ? artists : resellers).data
                ?.length > 0 ? (
                <>
                    <div className="artstCardGrid">
                        {(type === 'gallery'
                            ? products
                            : type === 'artist'
                            ? artists
                            : resellers
                        ).data.map((data, index) =>
                            type === 'gallery' ? (
                                <BioCard
                                    title={data.title}
                                    avatar={`${process.env.REACT_APP_BASE_URL}uploads/product/${data.file_name}`}
                                    index={index}
                                    redirectUrl={`bio/${data.id}`}
                                    handleClick={() => handleClickpopup(data.id)}
                                />
                            ) : (
                                <>
                                    {console.log(data, 'artits Data')}
                                    <BioCard
                                        title={data.first_name + ' ' + data.last_name}
                                        avatar={
                                            data.avatar
                                                ? `${process.env.REACT_APP_BASE_URL}uploads/${data.avatar}`
                                                : `https://ui-avatars.com/api/?name=${
                                                      data.first_name?.split(' ')[0]
                                                  }+${data.last_name?.split(' ')[0]}`
                                        }
                                        index={index}
                                        redirectUrl={`bio/${data.id}`}
                                    />
                                </>
                            ),
                        )}
                    </div>
                </>
            ) : (
                <>
                    {type === 'gallery' ? (
                        <div className="no-artist-o1">{t('no_gallery_found')}</div>
                    ) : type === 'artist' ? (
                        <div className="no-artist-o1">{t('no_artist_found')}</div>
                    ) : (
                        <div className="no-artist-o1">{t('no_reseller_found')}</div>
                    )}
                </>
            )}
            {(type === 'gallery' ? products : type === 'artist' ? artists : resellers).data
                ?.length &&
            (type === 'gallery' ? products : type === 'artist' ? artists : resellers).total >
                sellerParams.page * sellerParams.limit ? (
                <div className="row">
                    <div className="col-md-3 col-12 mx-auto d-block artLoad">
                        <PrimaryButton
                            disabled={loading}
                            label={
                                loading ? (
                                    <>
                                        <CircularProgress color="inherit" />
                                    </>
                                ) : (
                                    <>
                                        <span className="material-icons mr-2">
                                            arrow_circle_down
                                        </span>{' '}
                                        {t('load_more')}
                                    </>
                                )
                            }
                            onClick={handleClick}
                            className="text-center"
                        />
                    </div>
                </div>
            ) : null}
            <MultisellerSlider
                storeTheme="ArtAuction"
                type="right"
                selectedLot={selectedProduct}
                handleClose={handleClose}
                from={''}
            />
        </div>
    )
}

export default Seller
