import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { Slider } from '@material-ui/core'
import Copy from '../Share/Copy'
import './CategoryLookup.css'

const CategoryLookup = (props) => {
    const [category, setCategory] = useState([])
    const [show, setShow] = useState(true)
    const [open, setOpen] = useState(null)
    useEffect(() => {
        setCategory(props.category)
    }, [props.category])
    // console.log(props.category, props.subCategory)
    return (
        <div className="mt-3">
            {category.length && (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Id #</th>
                                    <th>Name</th>
                                    <th>
                                        {/* <Button
                                            className="text-white dropdown-toggle actionbutton"
                                            // onClick={() => setShow((s) => !s)}
                                        > */}
                                        Action
                                        {/* </Button> */}
                                    </th>
                                </tr>
                            </thead>
                            {category.map((item, index) => {
                                return (
                                    <>
                                        <tbody>
                                            {index < 2 || !show ? (
                                                <tr>
                                                    <td>
                                                        {item.id}{' '}
                                                        <Copy text={item.id} textOnly={1} />
                                                    </td>
                                                    <td>{item.name}</td>
                                                    {item?.filters?.length ? (
                                                        <td>
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() =>
                                                                    setOpen(
                                                                        open === item.id
                                                                            ? null
                                                                            : item.id,
                                                                    )
                                                                }
                                                            >
                                                                Sub Category{' '}
                                                                {open === item.id ? (
                                                                    <span className="material-icons-outlined">
                                                                        expand_less
                                                                    </span>
                                                                ) : (
                                                                    <span className="material-icons-outlined">
                                                                        expand_more
                                                                    </span>
                                                                )}{' '}
                                                            </Button>
                                                        </td>
                                                    ) : null}

                                                    {index == 1 && show ? (
                                                        <td>
                                                            <Button
                                                                className="dropdown-toggle actionbutton"
                                                                onClick={() => setShow((s) => !s)}
                                                            >
                                                                View More
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                            {open === item.id && (
                                                <tr>
                                                    <td colSpan="3">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Id #</th>
                                                                        <th>Name</th>
                                                                    </tr>
                                                                </thead>
                                                                {item.filters.map((val, index) => {
                                                                    return (
                                                                        <>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        {val.id}{' '}
                                                                                        <Copy
                                                                                            text={
                                                                                                val.id
                                                                                            }
                                                                                            textOnly={
                                                                                                1
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        {val.name}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </>
                                                                    )
                                                                })}
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </>
                                )
                            })}
                        </table>
                    </div>
                </>
            )}
        </div>
    )
}
export default CategoryLookup
