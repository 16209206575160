import React, { useContext, useState, useEffect } from 'react'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import AuthContext from '../../Product/context/auth/authContext'
import ProductContext from '../../Product/context/product/productContext'
import NoRecordsFound from '../../Product/components/atoms/NoRecordsFound'
import SlidePanel from '../SlidePanel'
import { connect } from 'react-redux'
import {
    currencyFormat,
    dateFormatFrontDay,
    handleRedirectInternal,
} from '../../Product/common/components'
import { Pagination } from '@material-ui/lab'
import { Link, useHistory } from 'react-router-dom'
import Timer from '../../Product/common/timer'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { imageError, getImageurl } from '../../utils/commonFunctions'
import MultisellerSlider from '../SlidePanel/MultisellerSlider'
import Loader from '../../assets/loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import axios from 'axios'
import AlertContext from '../../Product/context/alert/alertContext'
import RetractBid from './RetractBid'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Popup from '../../Product/components/organisms/Popup'
import UserContext from '../../Product/context/user/userContext'
import Dispute from './Dispute'
import RequestRefund from './RequestRefund'
import AddFeedback from '../../utils/CommonFunctionality/Feedback/AddFeedback'
import AdditionalPayment from './AdditionalPayment'

const AllBids = (props) => {
    const buyerContext = useContext(BuyerContext)
    const productContext = useContext(ProductContext)
    const userContext = useContext(UserContext)
    const { getAllShippingAddress, all_shipping_address } = userContext
    const history = useHistory()
    const { t } = useTranslation()
    const {
        buyer_allproducts,
        getAllBuyerProducts,
        product_view,
        getViewProduct,
        check_get_shipping_rate_api,
        shipping_rate_calculation,
        clear_ship_station_amount,
        getAllMakeRequest,
        list_make_request,
    } = buyerContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const [loading, setLoading] = useState(true)
    const [Items, setData] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [totalItems, setTotalItem] = useState(0)
    const [toInvoice, settoInvoice] = useState(false)
    const [toInvoiceID, settoInvoiceID] = useState(false)
    const [popup_open, setPopupopup] = useState(false)
    const [refundTrigger, setRefundTrigger] = useState(false)
    const [feedbackOpen, setFeedbackOpen] = useState(false)
    const [feedbackItem, setFeedbackItem] = useState({})
    const [additionalPayOpen, setAdditionalPayOpen] = useState(false)
    const [additionalPayItem, setAdditionalPayItem] = useState({})
    const [selectAddress, setSelectAddress] = useState([])
    const [display_address, setDisplayAddress] = useState({})
    const { responseStatus } = productContext
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const [search, setSearch] = useState({
        status: props.page,
        sh_limit: 10,
        page: 1,
        order: props.page == 'bidlost' ? 2 : 1,
        ftpImage: true,
        buynow_only_io: props.page == 'buynow' ? 1 : undefined,
    })
    const [invoiceId, setInvoiceId] = useState([])
    const [checkoutLength, setCheckoutLength] = useState(0)
    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
        if (props.page == 'make_an_offer') {
            getAllMakeRequest({
                user_id: user?.id,
                status: 'Accepted',
                limit: 10,
                page: value,
            })
        }
    }
    useEffect(() => {
        if (props.page != 'make_an_offer') {
            setLoading(false)
            setData(buyer_allproducts.results)
            setTotalItem(buyer_allproducts.total_pagecnt)
        }
    }, [buyer_allproducts])
    useEffect(() => {
        if (responseStatus && responseStatus !== '') {
            if (responseStatus === 'buynow_updated_successfull') {
                getAllBuyerProducts(search)
            } else {
            }
        } else {
        }
    }, [responseStatus])

    useEffect(() => {
        if (props.page != 'make_an_offer') {
            setLoading(true)
            if (isAuthenticated) {
                getAllBuyerProducts(search)
            }
        }
    }, [search, isAuthenticated, refundTrigger])

    useEffect(() => {
        setLoading(true)
        if (props.page == 'make_an_offer' && user) {
            getAllMakeRequest({
                user_id: user?.id,
                limit: 10,
                page: 1,
            })
        }
    }, [user])

    useEffect(() => {
        setLoading(false)
        if (props.page == 'make_an_offer') {
            if (list_make_request?.data?.length > 0) {
                setData(list_make_request.data)
                setTotalItem(list_make_request.totalcount)
            } else {
                setData([])
                setTotalItem(0)
            }
        }
    }, [list_make_request])

    const handleClose = () => {
        getAllBuyerProducts(search)
        setSelectedProduct(null)
    }

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const handleInvoiceChange = (id) => {
        var arr = invoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.common_invoice == inv).map((value) => temp2.push(value))
        })
        setInvoiceId(temp)
        setCheckoutLength(temp2.length)
    }

    const handleInvoiceChangeupdate = (id) => {
        var arr = invoiceId
        var arr2 = Items
        var temp = []
        var temp2 = []
        if (arr.includes(id)) {
            temp = arr.filter((val) => val != id)
        } else {
            temp = [...arr, id]
        }
        temp.map((inv) => {
            arr2.filter((val) => val?.common_invoice == inv && val?.status == 'Accepted').map(
                (value) => temp2.push(value),
            )
        })
        setInvoiceId(temp)
        setCheckoutLength(temp2.length)
    }

    useEffect(() => {
        var arr = invoiceId
        var temp = []
    }, [invoiceId])
    // console.log('user', user, Items)

    const retractBid = async (bid) => {
        // console.log('retractBid', bid)
        if (bid.mybidid && user?.id) {
            const formdata = new FormData()

            formdata.append('pid', bid.id)
            formdata.append('id', bid.mybidid)
            formdata.append('uid', user?.id)
            formdata.append('price', bid.mybid)
            formdata.append('auctionid', bid.auctionid)
            formdata.append('lotof', bid.lotof)
            formdata.append('is_buyer', true)
            // console.log('retractBid', formdata)
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api_bidding/cancelbid_process`,
                formdata,
            )
            if (res.data.msg === 'yes') {
                getAllBuyerProducts(search)
                setAlert('Bid Retracted Successfully', 'success')
            } else {
                setAlert(res.data.msg, 'error')
            }
        }
    }

    useEffect(() => {
        if (global.storeConfigration?.ship_track_info?.value == 1 && props.page == 'bidwon') {
            getAllShippingAddress()
        }
    }, [])
    useEffect(() => {
        if (all_shipping_address.length) {
            setSelectAddress(all_shipping_address)
        }
    }, [all_shipping_address])

    const openshippingtracking = (data) => {
        selectAddress.map((val) => {
            if (parseInt(val.id) === parseInt(data.shipping_addr_id)) {
                setDisplayAddress({ ...val, ...data })
            }
        })
        setPopupopup(true)
    }

    // useEffect(() => {
    //     console.log('99999999999999999999999999999999999999999999999999999999999', display_address)
    //     if (Object.keys(display_address).length > 0) {
    //         setPopupopup(true)
    //     }
    // }, [display_address])

    const redirect_checkout = () => {
        if (global.pluginConfiguration?.shipping?.enable) {
            var check_hasshipping = 1,
                seller_zip = '',
                get_invoice_id = [],
                seller_zipcode = '',
                no_call_api = 1
            Items.map((data, index) => {
                if (invoiceId.includes(data.common_invoice) && parseInt(data.hasshipping)) {
                    if (data.custom_field_8) {
                        if (
                            seller_zipcode != JSON.parse(data.custom_field_8).zipcode &&
                            seller_zipcode != ''
                        ) {
                            no_call_api = 0
                        } else {
                            seller_zipcode = JSON.parse(data.custom_field_8).zipcode
                        }
                    }
                    check_hasshipping = 0
                    if (data.sell_zipcode != seller_zip && seller_zip != '') {
                        no_call_api = 0
                    } else {
                        seller_zip = data.sell_zipcode
                    }
                    if (
                        data.weight != 0 &&
                        data.height != 0 &&
                        data.length != 0 &&
                        data.width != 0
                    ) {
                        get_invoice_id.push(data.common_invoice)
                    }
                }
            })
            if (check_hasshipping) {
                handleRedirectInternal(history, `checkout/auction?id=${invoiceId.join('&id=')}`)
            } else {
                if (no_call_api == 1) {
                    if (get_invoice_id.length > 0) {
                        check_get_shipping_rate_api({
                            invoiceID: get_invoice_id,
                            toPostalCode: user.zip,
                            fromPostalCode: seller_zip ? seller_zip : seller_zipcode,
                        })
                    } else {
                        handleRedirectInternal(
                            history,
                            `checkout/auction?id=${invoiceId.join('&id=')}`,
                        )
                    }
                } else {
                    setAlert(
                        'Your Selected Items Sellers Are Different.We are unable to update shippingcost.please select same sellers item and checkout',
                        'error',
                    )
                    return false
                }
            }
        } else {
            handleRedirectInternal(history, `checkout/auction?id=${invoiceId.join('&id=')}`)
        }
    }

    useEffect(() => {
        if (shipping_rate_calculation.status) {
            if (shipping_rate_calculation.status == 'success') {
                if (shipping_rate_calculation.data?.message?.shipping_error == '') {
                    handleRedirectInternal(history, `checkout/auction?id=${invoiceId.join('&id=')}`)
                } else {
                    if (shipping_rate_calculation.data?.message?.shipping_status == 500) {
                        setAlert(
                            'Product dimensional or Zip Code incorrect.Please check your Zip Code or contact seller.',
                            'error',
                        )
                    } else {
                        setAlert('Please Try Again Later!', 'error')
                    }
                }
            } else {
                setAlert('Please Try Again Later!', 'error')
            }
            clear_ship_station_amount()
        }
    }, [shipping_rate_calculation])
    const getBidText = (data) => {
        if (isAuthenticated && data.latestbid != null && user?.id != undefined) {
            if (data.auction) {
                if (
                    (data.highbid || data.highest_bidder_user_id == user?.id) &&
                    data.latestbid >= data.rprice
                ) {
                    if (data.market_status == 'open') {
                        return 'Winning'
                    } else if (data.market_status == 'sold') {
                        return 'Won'
                    }
                } else if (!data.highbid || data.highest_bidder_user_id != user?.id) {
                    if (data.market_status == 'open') {
                        return 'Outbid'
                    } else if (data.market_status == 'sold') {
                        return 'Lost'
                    }
                } else {
                    return ''
                }
            }
        }
    }
    return (
        <>
            {loading ? (
                <div className="text-center">
                    <Loader />
                </div>
            ) : Items?.length > 0 ? (
                <>
                    <div className="table-responsive">
                        {props.page === 'bidwon' && props.checkout_but_pos == 'top' ? (
                            <div className="row align-items-center mb-3">
                                <div className="col-md-2 col-sm-2"></div>
                                <div className="col-md-6 col-sm-6">
                                    <p className="mb-0" style={{ color: 'red' }}>
                                        * {t('please_click_chec')}.
                                    </p>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <PrimaryButton
                                        label={`${t('checkout')} (${invoiceId.length})`}
                                        className="mbv-btn"
                                        onClick={() => redirect_checkout()}
                                        disabled={!invoiceId.length}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <table className="table table-hover mobile-table">
                            <thead className="hidden-mobile">
                                <tr>
                                    {props.page === 'bidactive' ? (
                                        <>
                                            <th className="numCell">S.no</th>
                                            <th>{t('Image')}</th>
                                            <th>{t('name')}</th>
                                            <th>{t('time_left')}</th>
                                            <th>{t('my_b')}</th>
                                            {!props.noProxy && <th>Proxy Bid</th>}
                                            <th>{t('current_prc')}</th>
                                            <th>{t('Status')}</th>
                                            <th className="text-center">{t('action')}</th>
                                        </>
                                    ) : props.page === 'bidlost' ? (
                                        <>
                                            <th className="numCell">S.no</th>
                                            <th>{t('Image')}</th>
                                            <th>{t('name')}</th>
                                            <th>{t('date_closed')}</th>
                                            <th>{t('winning_bid')}</th>
                                        </>
                                    ) : props.page === 'bidwon' ? (
                                        <>
                                            <th className="numCell">Invoice No</th>
                                            <th>{t('Image')}</th>
                                            <th>{t('name')}</th>
                                            {props.pickupCode ? <th>Pickup Code</th> : null}
                                            {global.pluginConfiguration?.schedule_pickup?.enable ==
                                            1 ? (
                                                <th>Pickup Date</th>
                                            ) : null}
                                            <th>{t('date_closed')}</th>
                                            <th>{t('winning_bid')}</th>
                                            <th>{t('status')}</th>
                                            <th className="text-center">
                                                {props.shippingauction
                                                    ? 'Shipping Action'
                                                    : t('action')}
                                            </th>
                                            {global?.pluginConfiguration?.feedback?.enable == 1 ? (
                                                <th>{t('Feedback')}</th>
                                            ) : null}
                                            {global?.pluginConfiguration?.disputes?.enable == 1 ? (
                                                <th>{t('Dispute')}</th>
                                            ) : null}
                                            {global?.pluginConfiguration?.refund?.enable == 1 &&
                                            global.storeConfigration?.enable_request_refund
                                                ?.value == 1 ? (
                                                <th>{t('Refund')}</th>
                                            ) : null}
                                        </>
                                    ) : props.page === 'buynow' ? (
                                        <>
                                            <th className="numCell">S.no</th>
                                            <th>{t('Image')}</th>
                                            <th>Name</th>
                                            {props.pickupCode ? <th>Pickup Code</th> : null}
                                            {global.pluginConfiguration?.schedule_pickup?.enable ==
                                            1 ? (
                                                <th>Pickup Date</th>
                                            ) : null}
                                            <th>Date Purchased</th>
                                            <th>Price</th>

                                            <th className="text-center">
                                                {props.shippingauction
                                                    ? 'Shipping Action'
                                                    : 'Action'}
                                            </th>
                                            {global?.pluginConfiguration?.feedback?.enable == 1 ? (
                                                <th>{t('Feedback')}</th>
                                            ) : null}
                                            {global?.pluginConfiguration?.disputes?.enable == 1 ? (
                                                <th>{t('Dispute')}</th>
                                            ) : null}
                                            {global?.pluginConfiguration?.refund?.enable == 1 &&
                                            global.storeConfigration?.enable_request_refund
                                                ?.value == 1 ? (
                                                <th>{t('Refund')}</th>
                                            ) : null}
                                        </>
                                    ) : props.page === 'make_an_offer' ? (
                                        <>
                                            <th className="numCell">S.no</th>
                                            <th>{t('Image')}</th>
                                            <th>Name</th>
                                            {props.pickupCode ? <th>Pickup Code</th> : null}
                                            {global.pluginConfiguration?.schedule_pickup?.enable ==
                                            1 ? (
                                                <th>Pickup Date</th>
                                            ) : null}
                                            <th>Date Requested</th>
                                            <th>Price</th>
                                            <th className="text-center">
                                                {props.shippingauction
                                                    ? 'Shipping Action'
                                                    : 'Action'}
                                            </th>
                                            {global?.pluginConfiguration?.disputes?.enable == 1 ? (
                                                <th>{t('Dispute')}</th>
                                            ) : null}
                                            {global?.pluginConfiguration?.refund?.enable == 1 &&
                                            global.storeConfigration?.enable_request_refund
                                                ?.value == 1 ? (
                                                <th>{t('Refund')}</th>
                                            ) : null}
                                        </>
                                    ) : null}
                                </tr>
                            </thead>

                            <tbody>
                                {Items.length !== 0 &&
                                    Items.map((item, index) => {
                                        return (
                                            <>
                                                {item ? (
                                                    <tr key={index}>
                                                        <td className="ml-data top-90">
                                                            <a
                                                                href="#"
                                                                onClick={() =>
                                                                    global.storeDetails?.theme === 9
                                                                        ? history.push({
                                                                              pathname: `/productView/${item.id}`,
                                                                              state: { lotid: [] },
                                                                          })
                                                                        : props.redirect_product_view
                                                                        ? handleRedirectInternal(
                                                                              history,
                                                                              `productView/${item.id}`,
                                                                          )
                                                                        : handleClick(item.id)
                                                                }
                                                                className="tb-link"
                                                            >
                                                                {item.common_invoice || index + 1}
                                                            </a>
                                                        </td>
                                                        <td className="ml-data">
                                                            <LazyLoadImage
                                                                effect="blur"
                                                                placeholderSrc="/assets/svg/imageLoading.svg"
                                                                src={`${
                                                                    item?.content_head1 === '0' ||
                                                                    item?.store_id === 0
                                                                        ? process.env
                                                                              .REACT_APP_BASE_URL +
                                                                          'uploads/product/'
                                                                        : process.env
                                                                              .REACT_APP_PRODUCT_IMAGE_URL
                                                                }${
                                                                    item.file_name ||
                                                                    (Array.isArray(
                                                                        item.lotImages,
                                                                    ) &&
                                                                        item.lotImages?.length &&
                                                                        item.lotImages[0]
                                                                            ?.file_name) ||
                                                                    item.avatar_vc ||
                                                                    item.avatar
                                                                }`}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                width="65"
                                                                onClick={() =>
                                                                    global.storeDetails?.theme === 9
                                                                        ? history.push({
                                                                              pathname: `/productView/${item.id}`,
                                                                              state: { lotid: [] },
                                                                          })
                                                                        : props.redirect_product_view
                                                                        ? handleRedirectInternal(
                                                                              history,
                                                                              `productView/${item.id}`,
                                                                          )
                                                                        : handleClick(item.id)
                                                                }
                                                                onError={(e) =>
                                                                    (e.target.src = imageError())
                                                                }
                                                            />
                                                            {/* {item.avatar != '' &&
                                                            item.avatar != undefined ? (
                                                                <img
                                                                    src={getImageurl(
                                                                        item.content_head1,
                                                                        item.avatar,
                                                                    )}
                                                                    alt=""
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    width="65"
                                                                    onClick={() =>
                                                                        handleClick(item.id)
                                                                    }
                                                                    onError={(e) =>
                                                                        (e.target.src =
                                                                            imageError())
                                                                    }
                                                                />
                                                            ) : item.productImagesName != '' ? (
                                                                <img
                                                                    src={getFilePath(
                                                                        item.productImagesName,
                                                                        item.auctionlot_title,
                                                                        true,
                                                                    )}
                                                                    alt=""
                                                                    onError={(e) =>
                                                                        (e.target.src =
                                                                            `${global?.storeDetails?.logoValue}`)
                                                                    }
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        handleClick(item.id)
                                                                    }
                                                                    width="65"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={imageError()}
                                                                    alt=""
                                                                    width="65"
                                                                    className="pro-img img-fluid"
                                                                />
                                                            )} */}
                                                        </td>
                                                        <td
                                                            data-label="Title:"
                                                            className="pdtNmClmn"
                                                        >
                                                            <span className="hide-web">
                                                                {t('name')}:
                                                            </span>
                                                            {item.title}
                                                        </td>
                                                        {props.pickupCode &&
                                                        (props.page === 'bidwon' ||
                                                            props.page === 'buynow' ||
                                                            props.page === 'make_an_offer') ? (
                                                            <>
                                                                <td>
                                                                    <span className="hide-web">
                                                                        Pickup Code
                                                                    </span>
                                                                    {item.transfer_otp}
                                                                </td>
                                                            </>
                                                        ) : null}
                                                        {global.pluginConfiguration?.schedule_pickup
                                                            ?.enable == 1 &&
                                                        (props.page === 'bidwon' ||
                                                            props.page === 'buynow' ||
                                                            props.page === 'make_an_offer') ? (
                                                            <>
                                                                <td>
                                                                    <span className="hide-web">
                                                                        Pickup Date
                                                                    </span>
                                                                    {item.sche_localpick
                                                                        ? moment(
                                                                              item.sche_localpick,
                                                                          ).format(
                                                                              'YYYY-MM-DD HH:mm',
                                                                          )
                                                                        : '-'}
                                                                </td>
                                                            </>
                                                        ) : null}
                                                        {props.page === 'bidactive' ? (
                                                            <>
                                                                <td>
                                                                    <Timer
                                                                        date_added={item.date_added}
                                                                        date_closed={
                                                                            item.date_closed
                                                                        }
                                                                        withText={1}
                                                                        endText={
                                                                            item.con_check == 1
                                                                                ? 'Auction Started'
                                                                                : 'Ends in' + ': '
                                                                        }
                                                                        startText={
                                                                            'Starts in' + ': '
                                                                        }
                                                                        live_auction={
                                                                            item.con_check
                                                                        }
                                                                    ></Timer>
                                                                </td>
                                                                <td data-label="My Bid: ">
                                                                    <span className="hide-web">
                                                                        {t('my_b')}:
                                                                    </span>
                                                                    <span className="crncyValueinDb">
                                                                        {currencyFormat(
                                                                            item.mybid
                                                                                ? item.mybid
                                                                                : 0,
                                                                        )}
                                                                    </span>
                                                                    <span className="crncyValueinDb">
                                                                        <CurrencyConverterFormat
                                                                            amount={
                                                                                item.mybid
                                                                                    ? item.mybid
                                                                                    : 0
                                                                            }
                                                                        />
                                                                    </span>
                                                                </td>
                                                                {/*eslint-disable*/}
                                                                {item.mybid !== item.maxbidamt ? (
                                                                    <td data-label="Proxy Bid: ">
                                                                        {!props.noProxy && (
                                                                            <span className="hide-web">
                                                                                Proxy Bid
                                                                            </span>
                                                                        )}
                                                                        <span className="pxyBdVl">
                                                                            {currencyFormat(
                                                                                item.maxbidamt
                                                                                    ? item.maxbidamt
                                                                                    : 0,
                                                                                props.currency,
                                                                            )}
                                                                            <CurrencyConverterFormat
                                                                                amount={
                                                                                    item.maxbidamt
                                                                                        ? item.maxbidamt
                                                                                        : 0
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                ) : !props.noProxy ? (
                                                                    <td>-</td>
                                                                ) : (
                                                                    ''
                                                                )}

                                                                <td data-label="Current Bid: ">
                                                                    <span className="hide-web">
                                                                        {t('current_prc')}:
                                                                    </span>
                                                                    {currencyFormat(
                                                                        item.latestbid
                                                                            ? item.latestbid
                                                                            : 0,
                                                                    )}
                                                                    <CurrencyConverterFormat
                                                                        amount={
                                                                            item.latestbid
                                                                                ? item.latestbid
                                                                                : 0
                                                                        }
                                                                    />
                                                                </td>

                                                                {props.page === 'bidactive' ? (
                                                                    <td
                                                                        className={`text-center ${`badgeStatus ${getBidText(
                                                                            item,
                                                                        )}`}`}
                                                                    >
                                                                        {item?.bidtopstatus?.includes(
                                                                            'won',
                                                                        )
                                                                            ? 'Won'
                                                                            : item?.bidtopstatus?.includes(
                                                                                  'lost',
                                                                              )
                                                                            ? 'Lost'
                                                                            : item?.bidtopstatus?.includes(
                                                                                  'winning',
                                                                              )
                                                                            ? 'Winning'
                                                                            : item?.bidtopstatus?.includes(
                                                                                  'outbid',
                                                                              )
                                                                            ? 'Outbid'
                                                                            : getBidText(item)}
                                                                    </td>
                                                                ) : null}

                                                                <>
                                                                    {item.updateBid ? (
                                                                        <td>
                                                                            <a className="ub-btn">
                                                                                Update Bid
                                                                            </a>
                                                                        </td>
                                                                    ) : (
                                                                        <td>
                                                                            {item.con_check ==
                                                                            1 ? null : (
                                                                                <PrimaryButton
                                                                                    label={t(
                                                                                        'view_lot',
                                                                                    )}
                                                                                    btnSize="mb-2"
                                                                                    onClick={() =>
                                                                                        global
                                                                                            .storeDetails
                                                                                            ?.theme ===
                                                                                        9
                                                                                            ? history.push(
                                                                                                  {
                                                                                                      pathname: `/productView/${item.id}`,
                                                                                                      state: {
                                                                                                          lotid: [],
                                                                                                      },
                                                                                                  },
                                                                                              )
                                                                                            : props.redirect_product_view
                                                                                            ? handleRedirectInternal(
                                                                                                  history,
                                                                                                  `productView/${item.id}`,
                                                                                              )
                                                                                            : handleClick(
                                                                                                  item.id,
                                                                                              )
                                                                                    }
                                                                                />
                                                                            )}
                                                                            {props.noView !==
                                                                                true && (
                                                                                <>
                                                                                    {item.lotof && (
                                                                                        <PrimaryButton
                                                                                            label="View Auction"
                                                                                            onClick={() =>
                                                                                                handleRedirectInternal(
                                                                                                    history,
                                                                                                    `${
                                                                                                        item.con_check ==
                                                                                                        1
                                                                                                            ? 'liveauctionview'
                                                                                                            : 'search/product-buyer-auction'
                                                                                                    }/${Number(
                                                                                                        item.lotof,
                                                                                                    )}`,
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            <RetractBid
                                                                                enableRetractBid={
                                                                                    props.enableRetractBid
                                                                                }
                                                                                user={user}
                                                                                item={item}
                                                                                click={() =>
                                                                                    retractBid(item)
                                                                                }
                                                                            />
                                                                            {/* {props.enableRetractBid &&
                                                                            user?.id ===
                                                                                item.highest_bidder_user_id && (
                                                                                <SecondaryButton
                                                                                    label="Retract Bid"
                                                                                    btnSize="mt-2"
                                                                                    onClick={() =>
                                                                                        retractBid(
                                                                                            item,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )} */}
                                                                        </td>
                                                                    )}
                                                                </>
                                                            </>
                                                        ) : null}

                                                        {props.page === 'bidlost' ||
                                                        props.page === 'bidwon' ? (
                                                            <>
                                                                <td>
                                                                    <span className="hide-web">
                                                                        {t('date_closed')}
                                                                    </span>
                                                                    <span className="bwDc">
                                                                        {dateFormatFrontDay(
                                                                            item.date_closed,
                                                                        )}
                                                                    </span>
                                                                </td>
                                                                <td className="amount">
                                                                    <span className="hide-web">
                                                                        {t('winning_bid')}
                                                                    </span>
                                                                    {currencyFormat(
                                                                        parseFloat(
                                                                            item.buynowamount
                                                                                ? item.buynowamount
                                                                                : item.latestbid,
                                                                        ),
                                                                        props.currency,
                                                                    )}
                                                                    <CurrencyConverterFormat
                                                                        amount={
                                                                            item.buynowamount
                                                                                ? item.buynowamount
                                                                                : item.latestbid
                                                                        }
                                                                    />
                                                                </td>
                                                            </>
                                                        ) : null}

                                                        {props.page === 'bidwon' ? (
                                                            <td>
                                                                <span className="hide-web">
                                                                    Status
                                                                </span>
                                                                {parseInt(item.paid) == 0
                                                                    ? 'Unpaid'
                                                                    : 'Paid'}
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {props.page === 'bidwon' ? (
                                                            <>
                                                                <td>
                                                                    {/* {item.buynowpaid &&
                                                                    invoiceId.includes(
                                                                        item.common_invoice,
                                                                    ) > -1
                                                                        ? (global.LiquidationHasAuctionCart = true)
                                                                        : (global.LiquidationHasAuctionCart = false)} */}
                                                                    {item.buynowpaid ||
                                                                    (parseInt(
                                                                        item.auc_custom_field_4,
                                                                    ) == 1 &&
                                                                        item.shipping_info == '' &&
                                                                        parseInt(
                                                                            item.hasshipping,
                                                                        ) !== 1) ? (
                                                                        <PrimaryButton
                                                                            label={t(
                                                                                'view_invoice',
                                                                            )}
                                                                            className="mbv-btn"
                                                                            onClick={() =>
                                                                                handleRedirectInternal(
                                                                                    history,
                                                                                    `invoice/auction?id=${item.common_invoice}`,
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : item.shipping_info != '' &&
                                                                      item.shipping_info !=
                                                                          'Local Pickup' &&
                                                                      parseInt(item.shippingcost) ==
                                                                          0 ? (
                                                                        'Waiting For Seller Response'
                                                                    ) : global.storeConfigration
                                                                          ?.ship_track_info
                                                                          ?.value == 1 ? (
                                                                        <>
                                                                            <PrimaryButton
                                                                                label={
                                                                                    invoiceId.includes(
                                                                                        item.common_invoice,
                                                                                    )
                                                                                        ? 'Remove'
                                                                                        : t('add')
                                                                                }
                                                                                className="mbv-btn"
                                                                                onClick={() =>
                                                                                    handleInvoiceChange(
                                                                                        item.common_invoice,
                                                                                    )
                                                                                }
                                                                            />
                                                                            {/* <br></br> */}
                                                                            {item.ss_orderid ? (
                                                                                <PrimaryButton
                                                                                    label={
                                                                                        'View Shipping Tracking'
                                                                                    }
                                                                                    className="mbv-btn"
                                                                                    onClick={() =>
                                                                                        openshippingtracking(
                                                                                            item,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </>
                                                                    ) : item.buynowpaid === 0 &&
                                                                      item.partial === 1 ? (
                                                                        <SecondaryButton
                                                                            label={`Pay (${currencyFormat(
                                                                                item.ship_offer,
                                                                            )})`}
                                                                            className="mbv-btn"
                                                                            onClick={() => {
                                                                                setAdditionalPayOpen(
                                                                                    true,
                                                                                )
                                                                                setAdditionalPayItem(
                                                                                    item,
                                                                                )
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <PrimaryButton
                                                                            label={
                                                                                invoiceId.includes(
                                                                                    item.common_invoice,
                                                                                )
                                                                                    ? 'Remove'
                                                                                    : t('add')
                                                                            }
                                                                            className="mbv-btn"
                                                                            onClick={() =>
                                                                                handleInvoiceChange(
                                                                                    item.common_invoice,
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </td>

                                                                {global?.pluginConfiguration
                                                                    ?.feedback?.enable == 1 ? (
                                                                    <td>
                                                                        <SecondaryButton
                                                                            label="Feedback"
                                                                            className="mbv-btn"
                                                                            onClick={() => {
                                                                                setFeedbackOpen(
                                                                                    true,
                                                                                )
                                                                                setFeedbackItem(
                                                                                    item,
                                                                                )
                                                                            }}
                                                                        />{' '}
                                                                    </td>
                                                                ) : null}

                                                                {global?.pluginConfiguration
                                                                    ?.disputes?.enable == 1 &&
                                                                item.paid == 1 ? (
                                                                    <td>
                                                                        <Dispute
                                                                            from_id={user?.id}
                                                                            to_id={
                                                                                global?.storeDetails
                                                                                    ?.user_id
                                                                            }
                                                                            lotid={item.id}
                                                                            auctionid={item.lotof}
                                                                        />{' '}
                                                                    </td>
                                                                ) : null}

                                                                {global?.pluginConfiguration?.refund
                                                                    ?.enable == 1 &&
                                                                global.storeConfigration
                                                                    ?.enable_request_refund
                                                                    ?.value == 1 &&
                                                                item.paid == 1 &&
                                                                item.item_returned == 0 ? (
                                                                    <td>
                                                                        {' '}
                                                                        {item.payment_type !=
                                                                        'Wallet' ? (
                                                                            <RequestRefund
                                                                                lot_id={item.id}
                                                                                invoice_id={
                                                                                    item.common_invoice
                                                                                }
                                                                                trigger={
                                                                                    refundTrigger
                                                                                }
                                                                                setTrigger={
                                                                                    setRefundTrigger
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            'Refund Is Not Applicable For Wallet Payment'
                                                                        )}
                                                                    </td>
                                                                ) : null}
                                                                {global?.pluginConfiguration?.refund
                                                                    ?.enable == 1 &&
                                                                global.storeConfigration
                                                                    ?.enable_request_refund
                                                                    ?.value == 1 &&
                                                                item.item_returned == 1 ? (
                                                                    <td>
                                                                        <p>
                                                                            Amount (
                                                                            {currencyFormat(
                                                                                item.refund,
                                                                            )}
                                                                            ) Refeunded on{' '}
                                                                            {dateFormatFrontDay(
                                                                                item.refund_request_date,
                                                                            )}
                                                                        </p>
                                                                    </td>
                                                                ) : null}
                                                            </>
                                                        ) : null}

                                                        {props.page === 'buynow' ||
                                                        props.page === 'make_an_offer' ? (
                                                            <>
                                                                {props.page == 'make_an_offer' ? (
                                                                    <td>
                                                                        <span className="hide-web">
                                                                            Date Purchased
                                                                        </span>
                                                                        {dateFormatFrontDay(
                                                                            item.date_requested,
                                                                        )}{' '}
                                                                    </td>
                                                                ) : (
                                                                    <td>
                                                                        <span className="hide-web">
                                                                            Date Purchased
                                                                        </span>
                                                                        {dateFormatFrontDay(
                                                                            item.buynowdate_added,
                                                                        )}{' '}
                                                                    </td>
                                                                )}
                                                                <td>
                                                                    <span className="hide-web">
                                                                        Price
                                                                    </span>
                                                                    {props.page === 'make_an_offer'
                                                                        ? currencyFormat(
                                                                              parseFloat(
                                                                                  item.proposed_amount,
                                                                              ),
                                                                              props.currency,
                                                                          )
                                                                        : currencyFormat(
                                                                              parseFloat(
                                                                                  item.bprice,
                                                                              ),
                                                                              props.currency,
                                                                          )}
                                                                    <CurrencyConverterFormat
                                                                        amount={item.bprice}
                                                                    />
                                                                </td>
                                                                {parseInt(item.paid) == 0 &&
                                                                item.common_invoice &&
                                                                item.status == 'Accepted' ? (
                                                                    <td>
                                                                        <PrimaryButton
                                                                            label={
                                                                                invoiceId.includes(
                                                                                    item.common_invoice,
                                                                                )
                                                                                    ? 'Remove'
                                                                                    : t('add')
                                                                            }
                                                                            className="mbv-btn"
                                                                            onClick={() =>
                                                                                handleInvoiceChangeupdate(
                                                                                    item.common_invoice,
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                ) : item.status == 'Pending' ||
                                                                  item.status == 'Rejected' ? (
                                                                    <td>{item.status}</td>
                                                                ) : (
                                                                    <td>
                                                                        {item.buynowpaid === 0 &&
                                                                        item.partial === 1 ? (
                                                                            <SecondaryButton
                                                                                label={`Pay (${currencyFormat(
                                                                                    item.ship_offer,
                                                                                )})`}
                                                                                className="mbv-btn"
                                                                                onClick={() => {
                                                                                    setAdditionalPayOpen(
                                                                                        true,
                                                                                    )
                                                                                    setAdditionalPayItem(
                                                                                        item,
                                                                                    )
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <PrimaryButton
                                                                                label="View Invoice"
                                                                                className="mbv-btn"
                                                                                onClick={() =>
                                                                                    handleRedirectInternal(
                                                                                        history,
                                                                                        `invoice/buynow?id=${item.common_invoice}`,
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                )}

                                                                {global?.pluginConfiguration
                                                                    ?.feedback?.enable == 1 ? (
                                                                    <td>
                                                                        <SecondaryButton
                                                                            label="Feedback"
                                                                            className="mbv-btn"
                                                                            onClick={() => {
                                                                                setFeedbackOpen(
                                                                                    true,
                                                                                )
                                                                                setFeedbackItem(
                                                                                    item,
                                                                                )
                                                                            }}
                                                                        />
                                                                    </td>
                                                                ) : null}

                                                                {global?.pluginConfiguration
                                                                    ?.disputes?.enable == 1 ? (
                                                                    <td>
                                                                        <Dispute
                                                                            from_id={user?.id}
                                                                            to_id={
                                                                                global?.storeDetails
                                                                                    ?.user_id
                                                                            }
                                                                            lotid={item.id}
                                                                            auctionid={item.lotof}
                                                                        />
                                                                    </td>
                                                                ) : null}
                                                                {global?.pluginConfiguration?.refund
                                                                    ?.enable == 1 &&
                                                                global.storeConfigration
                                                                    ?.enable_request_refund
                                                                    ?.value == 1 &&
                                                                item.item_returned == 0 ? (
                                                                    <td>
                                                                        {item.payment_type !=
                                                                        'Wallet' ? (
                                                                            <RequestRefund
                                                                                lot_id={item.id}
                                                                                invoice_id={
                                                                                    item.common_invoice
                                                                                }
                                                                                trigger={
                                                                                    refundTrigger
                                                                                }
                                                                                setTrigger={
                                                                                    setRefundTrigger
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            'Refund Is Not Applicable For Wallet Payment'
                                                                        )}
                                                                    </td>
                                                                ) : null}
                                                                {global?.pluginConfiguration?.refund
                                                                    ?.enable == 1 &&
                                                                global.storeConfigration
                                                                    ?.enable_request_refund
                                                                    ?.value == 1 &&
                                                                item.item_returned == 1 ? (
                                                                    <td>
                                                                        <p>
                                                                            Amount (
                                                                            {currencyFormat(
                                                                                item.refund,
                                                                            )}
                                                                            ) Refeunded on{' '}
                                                                            {dateFormatFrontDay(
                                                                                item.refund_request_date,
                                                                            )}
                                                                        </p>
                                                                    </td>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                    </tr>
                                                ) : null}
                                            </>
                                        )
                                    })}
                                {(props.page === 'bidwon' && props.checkout_but_pos != 'top') ||
                                (props.page === 'make_an_offer' &&
                                    props.checkout_but_pos != 'top') ? (
                                    <tr>
                                        <td colSpan="6"></td>
                                        <td colSpan="2" className="mbutton-xs">
                                            <PrimaryButton
                                                label={`Checkout (${checkoutLength})`}
                                                className="mbv-btn"
                                                onClick={() => redirect_checkout()}
                                                disabled={!checkoutLength}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        count={Math.ceil(totalItems / search.sh_limit)}
                        page={search.page}
                        onChange={onHandlePage}
                    />
                </>
            ) : (
                <NoRecordsFound />
            )}

            {props.sliderTheme == 'multiseller' ? (
                <MultisellerSlider
                    storeTheme={props.storeTheme}
                    selectedLot={selectedProduct}
                    handleClose={handleClose}
                    currency={props.currency}
                    sliderTheme={props.sliderTheme}
                    itemDetails={Items}
                    from={'card'}
                    getAllProducts={(e) => {
                        return true
                    }}
                />
            ) : (
                <SlidePanel
                    type="right"
                    selectedLot={selectedProduct}
                    temDetails={Items}
                    handleClose={handleClose}
                    from={'card'}
                    getAllProducts={() => {
                        return true
                    }}
                />
            )}

            <Popup
                open={popup_open}
                handleClose={(e) => setPopupopup(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Shipping Info"
            >
                {Object.keys(display_address).length > 0 ? (
                    <>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Shipping User Info: '}</b>
                                {display_address.first_name + ' ' + display_address.last_name}
                            </div>
                            <div className="col-6">
                                <b>{'Shipping Address: '}</b>
                                {display_address.address}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Email: '}</b>
                                {display_address.email}
                            </div>
                            <div className="col-6">
                                <b>{'Tracking Number: '}</b>
                                {display_address.tracking_number
                                    ? display_address.tracking_number
                                    : '-'}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Phone: '}</b>
                                {display_address.phone}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Tracking Service: '}</b>
                                {global.pluginConfiguration?.shipping?.service_key
                                    ? global.pluginConfiguration.shipping.service_key
                                    : '-'}
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-6">
                                <b>{'Tracking Info: '}</b>
                                {display_address.localpickup ? 'Local-Pick-Up' : 'Shipping'}
                            </div>
                        </div>
                        <br></br>
                        <div className="actionWrapper">
                            <div className="row">
                                <div className="col-3">
                                    {' '}
                                    <PrimaryButton
                                        label={`Close`}
                                        className="mbv-btn"
                                        onClick={(e) => setPopupopup(false)}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>Loading...</>
                )}
            </Popup>
            <Popup
                open={feedbackOpen}
                handleClose={(e) => setFeedbackOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Feedback"
            >
                <AddFeedback
                    item={feedbackItem}
                    closePopup={() => setFeedbackOpen(false)}
                    clearSelectedItem={() => setFeedbackItem({})}
                />
            </Popup>
            <Popup
                open={additionalPayOpen}
                handleClose={(e) => setAdditionalPayOpen(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Additional Pay"
            >
                <AdditionalPayment
                    item={additionalPayItem}
                    closePopup={() => setAdditionalPayOpen(false)}
                    clearSelectedItem={() => setAdditionalPayItem({})}
                    trigger={refundTrigger}
                    setTrigger={setRefundTrigger}
                />
            </Popup>
        </>
    )
}

export default AllBids
