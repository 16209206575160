import React, { useContext, useEffect } from 'react'
import Layout from '../Layout'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import Standalone from '../../../Component/SlidePanel/Standalone'
import './ProductViewStandalone.css'

const ProductView = (props) => {
    return (
        <Layout>
            <div className="productViewStandalone customContainer">
                <Standalone
                    theme="multiAuction"
                    lotDetails={props.location?.state?.lotid ? props.location.state.lotid : 0}
                />
            </div>
        </Layout>
    )
}

export default ProductView
