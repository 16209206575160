import React, { useContext, useEffect, useState } from 'react'
import './MessagePlugin.css'
import { Button, IconButton } from '@material-ui/core'
import FullScreenPopup from '../../../Product/components/organisms/FullScreenPopup'
import FormDialog from '../../../Product/components/organisms/Dialog'
import AuthContext from '../../../Product/context/auth/authContext'
import PluginContext from '../../../Product/context/plugin/pluginContext'

const MessagePlugin = ({ product }) => {
    const [messagePopup, setMessagePopup] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [messages, setMessages] = useState([])
    const [messageInput, setMessageInput] = useState('')
    const { user } = useContext(AuthContext)
    const { getAllMessages, composeMessage, all_messages, responseStatus } =
        useContext(PluginContext)

    const handleChange = (e) => setMessageInput(e.target.value)
    const handleSubmit = (e) => {
        e.preventDefault()
        composeMessage({
            site_id: global?.storeDetails?.site_id,
            from_id: user?.id,
            to_id: product?.user_id,
            lotid: product?.id,
            message: messageInput,
            subject: `Message for a product (${product?.title})`,
        })
    }
    useEffect(() => {
        if (user && product) {
            getAllMessages({
                site_id: global?.storeDetails?.site_id,
                user_id: product?.user_id,
                buyerid: user?.id,
                lotid: product?.id,
            })
        }
    }, [user, product, trigger])
    useEffect(() => {
        setMessages(all_messages.reverse())
    }, [all_messages])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'composeMessage') {
                if (responseStatus.status === 'success') {
                    setMessageInput('')
                    setTrigger(!trigger)
                }
            }
        }
    }, [responseStatus])
    return (
        <div className="msgPluginWrapper">
            <div className="">
                <IconButton className="fltMsgBtn" onClick={() => setMessagePopup(true)}>
                    <span className="material-icons">chat</span>
                </IconButton>
            </div>
            <FormDialog
                className="msgPluginDialog"
                open={messagePopup}
                openFunction={() => setMessagePopup(false)}
                aria-labelledby="form-dialog-title"
                title={
                    <>
                        <div className="messagePopupTitle d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <div className="usrPrflCrcle mr-3">
                                    {product?.first_name.charAt(0) + product?.last_name.charAt(0)}
                                </div>
                                <div className="msgrPrlNm">
                                    <h2 className="mb-0">
                                        {product?.first_name + ' ' + product?.last_name}
                                    </h2>
                                    <p>Seller</p>
                                </div>
                            </div>
                            <IconButton onClick={() => setMessagePopup(false)}>
                                <span className="material-icons">clear</span>
                            </IconButton>
                        </div>
                    </>
                }
                maxWidth={'md'}
            >
                <div className="messagePlgBody">
                    <div className="MessageInrBody">
                        {messages.map((val, key) => (
                            <div
                                className={`${
                                    user?.id == val.from_id ? 'sntMsgWrpr' : 'rcvdMsgWrpr'
                                } msgOtrWrpr`}
                            >
                                <div className="msgContent">{val.message}</div>
                            </div>
                        ))}
                    </div>
                    <div className="msgInputBoxWrpr">
                        <div className="msgInputBox">
                            <textarea
                                placeholder="Enter Message..."
                                value={messageInput}
                                onChange={handleChange}
                            ></textarea>
                            <IconButton className="sndButton" onClick={handleSubmit}>
                                <span className="material-icons">send</span>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </FormDialog>
            {/* <FullScreenPopup open={messagePopup} handleClose={() => setMessagePopup(false)} /> */}
        </div>
    )
}

export default MessagePlugin
