import React, { useContext, useState, useEffect } from 'react'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
import SubLots from '../../../utils/CommonFunctionality/SellerDashboard/SubLots'

const Buynow = (props) => {
    return (
        <Layout>
            <DashboardLayout title="My Buynow" subtitle="Items" seller>
                <div className="mt-5">
                    <SubLots
                        changeStatus={1}
                        searchType="buynow"
                        searchBar={1}
                        status={1}
                        resultsPerPage={1}
                    />
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default Buynow
