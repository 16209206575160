import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import ReadMoreReact from 'read-more-react'
import ImageGallery from 'react-image-gallery'
import Toaster from '../Toaster'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import Bidhistory from '../Bidhistory'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import AuthContext from '../../Product/context/auth/authContext'
import CartContext from '../../Product/context/cart/cartContext'
import AlertContext from '../../Product/context/alert/alertContext'

import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import Timer from '../../Product/common/timer'
import { messageHandlerSingle, timeHandler } from '../../Product/common/socketHandler'
import { socket, socketAuction } from '../../Product/common/socket'

import './slider.css'
import { Button, Drawer } from '@material-ui/core'
import {
    currencyFormat,
    handleRedirectInternal,
    removeHTMLTags,
} from '../../Product/common/components'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import { imageError } from '../../utils/commonFunctions'
import Standalone from './Standalone'
import Loaders from '../../Product/components/molecules/Loaders'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import BidStatus from '../../Product/components/molecules/Bidding/BidStatus'
import axios from 'axios'
import { allCondition, storageOptions, unitOptions, sustainabilityOptions } from '../../utils'
import ShippingRates from '../../utils/CommonFunctionality/ShippingRate'
import Copy from '../../utils/CommonFunctionality/Share/Copy'
import HowBidPopup from '../HowBiddingWorks'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AuctionContext from '../../Product/context/auctionio/auction/auctionContext'
import ReactHtmlParser from 'react-html-parser'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px 16px',
    },
}))(MuiAccordionDetails)

export function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="tabBody">{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const SlidePopup = ({
    type,
    selectedLot,
    handleClose,
    viewPage,
    itemDetails,
    currency,
    from,
    storeTheme,
    reverse_auction,
}) => {
    const buyerContext = useContext(BuyerContext)
    const cartContext = useContext(CartContext)
    const alertContext = useContext(AlertContext)
    const auctionContext = useContext(AuctionContext)
    const matches = useMediaQuery('(max-width:800px)')

    const authContext = useContext(AuthContext)
    const { setAlert } = alertContext
    const [expanded, setExpanded] = useState('panel0')
    const [watchlist, setWatchlist] = useState(true)
    const { user, isAuthenticated } = authContext
    const { buyer_allproducts, getAllBuyerProducts, product_view, getViewProduct } = buyerContext
    const { addToCart, buyer_cartitems, getUserCart, removeFromCart, responseStatus } = cartContext
    const { getAuctionPolicies, auctionPolicies } = auctionContext
    const history = useHistory()
    const [historyData, setHistoryData] = useState({})
    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [selectedProductImage, setSelectedProductImage] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [showDescription, setShowDescription] = useState(false)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { id } = useParams()
    const [howBidPopup, setHowBidPopup] = useState(false)
    const [policies, setPolicies] = useState('')
    useEffect(() => {
        if (selectedLot) {
            setLoading(true)
            var data = {
                product_id: selectedLot,
            }
            if (parseInt(reverse_auction) == 1) {
                data.crop_auction_type = 'buyer'
            }
            getViewProduct(data)
        } else if (id) {
            setLoading(true)
            getViewProduct({ product_id: id })
        }
    }, [selectedLot, id])

    useEffect(() => {
        setLoading(true)
        if (product_view.results) {
            if (product_view.results.custom_field_8) {
                const addr = JSON.parse(product_view.results.custom_field_8)
                product_view.results.address = addr.address
                product_view.results.zipcode = addr.zipcode
            }
            if (product_view.results.custom_field_9) {
                const food = JSON.parse(product_view.results.custom_field_9)
                product_view.results.storage = food.storage
                product_view.results.package_date = food.package_date
                product_view.results.selling_unit = food.selling_unit
                product_view.results.total_weight = food.total_weight
            }
            setSelectedProduct({
                ...product_view.biddetails,
                ...product_view.results,
                incrementamt: product_view.incrementamt,
            })
            if (product_view?.attachments.length) {
                product_view?.attachments.forEach(function (data) {
                    data['original'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
                    }`

                    data['thumbnail'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
                    }`
                })
                setSelectedProductImage(product_view.attachments)
            } else {
                setSelectedProductImage([])
            }
            if (parseInt(product_view.results.buynow) === 1) {
                getUserCart()
            }
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    }, [product_view.results])

    useEffect(() => {
        if (buyer_cartitems.results) {
            if (selectedProduct.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: selectedProduct.id,
                })
                position === -1 ? setDisabled(false) : setDisabled(true)
            }
        }
    }, [buyer_cartitems.results])

    const handleClick = async (e) => {
        addToCart({ id: selectedProduct.id, qty: selectedProduct.qty })
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (auctionPolicies.length) {
            setPolicies(auctionPolicies[0])
        }
    }, [auctionPolicies])

    // console.log('policies', policies)

    const viewProductRef = useRef(selectedProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = selectedProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandlerSingle(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }
    const timehandlersingle = (message, type) => {
        timeHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('sliservertime', (data) => {
            if (moment(data.dTime).isValid()) {
                timehandlersingle(data, 'singletimehandler')
            }
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('sliservertime', (data) => {
                timehandler(data, 'singletimehandler')
            })
        }
    }, [])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const lotInformation = [
        {
            title: 'Auction notes',
            description: selectedProduct?.description,
        },
    ]
    const [countryList, setCountryList] = useState([])

    const getLocationData = () => {
        var body = {
            countryCode: 38,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setCountryList([
                        response.data.country.find((value) => parseInt(value.location_id) === 224),
                        ...response.data.country.filter(
                            (value) => parseInt(value.location_id) !== 224,
                        ),
                    ])
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
        getAuctionPolicies({ auctionid: selectedProduct?.lotof })
    }, [selectedProduct])

    const sliderRef = useRef(null)
    const [rotateValue, setRotateValue] = useState(0)

    const RenderImage = () => {
        return (
            <img
                src={selectedProductImage[sliderRef?.current?.getCurrentIndex()]?.original}
                className="checkRotate"
                id={`imgRef_${sliderRef?.current?.getCurrentIndex()}`}
            />
        )
    }

    const rotateImage = (index) => {
        let newValue = rotateValue + 90
        setRotateValue(newValue)

        if (newValue >= 450) {
            setRotateValue(0)
        } else {
            setRotateValue(newValue)
        }
        document.getElementsByClassName('checkRotate')[
            sliderRef?.current?.getCurrentIndex()
        ].style.transform = `rotate(${rotateValue}deg)`
    }

    const RenderImageZoom = () => {
        return (
            <>
                <Button onClick={rotateImage} className="rotateImage">
                    <span className="material-icons">rotate_right</span>
                </Button>
            </>
        )
    }

    const lotAdditionalInformation = [
        {
            title: 'Payment',
            description: policies?.store_comment_one
                ? policies?.store_comment_one
                : 'No Data Found',
        },
        {
            title: 'Pickup',
            description: policies?.store_comment_three
                ? policies?.store_comment_three
                : 'No Data Found',
        },
        {
            title: 'Shipping',
            description: policies?.store_comment_two
                ? policies?.store_comment_two
                : 'No Data Found',
        },
        {
            title: 'Returns',
            description: policies?.store_comment_five
                ? policies?.store_comment_five
                : 'No Data Found',
        },
        {
            title: 'Terms & Conditions',
            description: policies?.store_comment_four
                ? policies?.store_comment_four
                : 'No Data Found',
        },
        // {
        //     title: (
        //         <>
        //             <span className="material-icons">description</span>Auction Details
        //         </>
        //     ),
        //     description: selectedProduct?.description,
        // },

        // {
        //     title: (
        //         <>
        //             <span className="material-icons">description</span>Documents
        //         </>
        //     ),
        //     description: selectedProduct?.documents?.map((val) => (
        //         <>
        //             <a
        //                 href={`${process.env.REACT_APP_BASE_URL}uploads/${val}`}
        //                 target="_blank"
        //                 rel="noreferrer"
        //             >
        //                 {val}
        //             </a>
        //             <br />
        //         </>
        //     )),
        //     html: 1,
        // },
    ]
    if (global.storeConfigration?.disable_contacts_field?.value != 1) {
        lotInformation.push({
            title: (
                <>
                    <span className="material-icons">contacts</span>Contacts
                </>
            ),
            description: selectedProduct?.custom_field_5,
        })
    }
    if (global.storeConfigration?.disable_tax_field?.value != 1) {
        lotInformation.push({
            title: (
                <>
                    <span className="material-icons">percent</span>Tax/Removal
                </>
            ),
            description: selectedProduct?.custom_field_4,
        })
    }
    if (global.storeConfigration?.disable_terms_field?.value != 1) {
        lotInformation.push({
            title: (
                <>
                    <span className="material-icons">privacy_tip</span>Terms & Conditions
                </>
            ),
            description: selectedProduct?.custom_field_7,
        })
    }
    const [value, setValue] = useState(0)

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }

    const render = (
        <>
            {loading ? (
                <div className="sliderLoader">
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                </div>
            ) : (
                <>
                    {Object.keys(selectedProduct).length !== 0 && (
                        <div
                            className={`msSliderInner ${
                                selectedProduct.bidtopstatus != '' && 'bidStatus'
                            }`}
                            style={{
                                background: '#fff',
                                height: 'auto',
                                overflowY: 'auto',
                                padding: '35px',
                            }}
                        >
                            {/* {console.log('selectedProduct', selectedProduct)} */}
                            <div className="media-body">
                                {!viewPage && (
                                    <Button
                                        onClick={() => {
                                            handleClose()
                                        }}
                                        className="closeBtn"
                                    >
                                        <span className="material-icons">close</span>
                                    </Button>
                                )}
                                <div>
                                    {isAuthenticated ? (
                                        selectedProduct.auction ? (
                                            type === 'buynow' ? null : (
                                                <BidStatus
                                                    bidTopStatus={selectedProduct.bidtopstatus}
                                                />
                                            )
                                        ) : null
                                    ) : null}
                                    <div className="spCnt">
                                        <div className="spLt position-relative">
                                            <ImageGallery
                                                items={selectedProductImage}
                                                thumbnailPosition="bottom"
                                                showThumbnails={
                                                    storeTheme === 'foodindustry' ? false : true
                                                }
                                                showNav={true}
                                                showBullets={false}
                                                showFullscreenButton={true}
                                                showPlayButton={false}
                                                onErrorImageURL={imageError()}
                                                originalClass="slidImg img-fluid"
                                                renderItem={RenderImage}
                                                ref={sliderRef}
                                                renderCustomControls={RenderImageZoom}
                                            />
                                            {selectedProduct.market_status == 'open' ? (
                                                <FavouriteCheckbox
                                                    watchlisted={selectedProduct.wlistpop}
                                                    data={selectedProduct}
                                                    project_id={selectedProduct.id}
                                                    from={from}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="spRt">
                                            <h3
                                                className="plTitle"
                                                dangerouslySetInnerHTML={{
                                                    __html: selectedProduct.title,
                                                }}
                                            ></h3>

                                            <div className="spMisc">
                                                <div className="mssBidItem mssBidIntDet">
                                                    <>
                                                        {/* {selectedProduct.conditionunique ? (
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Condition:</span>
                                                                    {allCondition
                                                                        .filter(
                                                                            (condition) =>
                                                                                condition.id ==
                                                                                selectedProduct.conditionunique,
                                                                        )
                                                                        .map(
                                                                            (data) =>
                                                                                data.description,
                                                                        )}
                                                                </h5>
                                                            </div>
                                                        ) : null} */}
                                                        {/* {selectedProduct.auctionnotes ? (
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Condition:</span>
                                                                    {selectedProduct.auctionnotes}
                                                                </h5>
                                                            </div>
                                                        ) : null} */}
                                                        {/* {selectedProduct.qty ? (
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Quantity:</span>
                                                                    {selectedProduct.qty}
                                                                </h5>
                                                            </div>
                                                        ) : null} */}
                                                        {selectedProduct.selling_unit ? (
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Unit:</span>
                                                                    {
                                                                        unitOptions.find(
                                                                            (val) =>
                                                                                val.id ==
                                                                                selectedProduct.selling_unit,
                                                                        ).description
                                                                    }
                                                                </h5>
                                                            </div>
                                                        ) : null}
                                                        {selectedProduct.total_weight ? (
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Total Weight:</span>
                                                                    {selectedProduct.total_weight}
                                                                    {storeTheme === 'foodindustry'
                                                                        ? 'Kg'
                                                                        : ''}
                                                                </h5>
                                                            </div>
                                                        ) : null}
                                                        {selectedProduct.location ? (
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Location:</span>
                                                                    {countryList
                                                                        .filter(
                                                                            (country) =>
                                                                                country.location_id ==
                                                                                selectedProduct.location,
                                                                        )
                                                                        .map((data) => data.name)}
                                                                </h5>
                                                            </div>
                                                        ) : null}

                                                        {selectedProduct.storage ? (
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Storage:</span>
                                                                    {
                                                                        storageOptions.find(
                                                                            (val) =>
                                                                                val.id ==
                                                                                selectedProduct.storage,
                                                                        ).description
                                                                    }
                                                                </h5>
                                                            </div>
                                                        ) : null}
                                                        {selectedProduct.package_date ? (
                                                            <div className="mssBidInfo">
                                                                <h5>
                                                                    <span>Package Date:</span>
                                                                    {selectedProduct.package_date}
                                                                </h5>
                                                            </div>
                                                        ) : null}

                                                        <div className="mssBidInfo">
                                                            {(selectedProduct.auction == 1 &&
                                                                selectedProduct.buynow == 0) ||
                                                            selectedProduct.auction == 1 ? (
                                                                <div className="mssTimer">
                                                                    {selectedProduct.market_status !=
                                                                    'draft' ? (
                                                                        (selectedProduct.market_status !=
                                                                            'open' &&
                                                                            selectedProduct.market_status !=
                                                                                'future') ||
                                                                        selectedProduct.productClosed ? (
                                                                            ''
                                                                        ) : selectedProduct.extend ||
                                                                          selectedProduct.agent_id ? (
                                                                            <span className="tmm">
                                                                                <Timer
                                                                                    date_added={
                                                                                        selectedProduct.date_added
                                                                                    }
                                                                                    date_closed={
                                                                                        selectedProduct.date_closed
                                                                                    }
                                                                                    withText={1}
                                                                                    endText={
                                                                                        'Ends in' +
                                                                                        ':'
                                                                                    }
                                                                                    startText={
                                                                                        'Starts in' +
                                                                                        ':'
                                                                                    }
                                                                                    // theme="multiseller"
                                                                                ></Timer>
                                                                            </span>
                                                                        ) : (
                                                                            <span>
                                                                                <Timer
                                                                                    date_added={
                                                                                        selectedProduct.date_added
                                                                                    }
                                                                                    date_closed={
                                                                                        selectedProduct.date_closed
                                                                                    }
                                                                                    withText={1}
                                                                                    endText={
                                                                                        'Ends in' +
                                                                                        ':'
                                                                                    }
                                                                                    startText={
                                                                                        'Starts in' +
                                                                                        ':'
                                                                                    }
                                                                                    // theme="multiseller"
                                                                                ></Timer>
                                                                            </span>
                                                                        )
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            {parseInt(selectedProduct.buynow) ==
                                                                1 &&
                                                            parseInt(selectedProduct.auction) ==
                                                                1 ? (
                                                                <>
                                                                    {/* {
                                                                        <div className="mssBidInfo">
                                                                            <h5>
                                                                                <span>
                                                                                    Buy Now Price
                                                                                </span>
                                                                                {currencyFormat(
                                                                                    parseFloat(
                                                                                        selectedProduct.bprice,
                                                                                    ).toFixed(2),
                                                                                    currency,
                                                                                )}
                                                                            </h5>
                                                                        </div>
                                                                    } */}
                                                                    <div className="mssBidInfo">
                                                                        {selectedProduct.latestbid ? (
                                                                            <h5>
                                                                                <span>
                                                                                    {selectedProduct.market_status ==
                                                                                    'sold'
                                                                                        ? 'Sold Bid'
                                                                                        : 'Current Bid'}
                                                                                </span>
                                                                                <span className="crncyValue">
                                                                                    {currencyFormat(
                                                                                        selectedProduct.wprice,
                                                                                        currency,
                                                                                    )}
                                                                                </span>
                                                                            </h5>
                                                                        ) : (
                                                                            <h5>
                                                                                {selectedProduct.market_status ==
                                                                                'closed' ? (
                                                                                    <span>
                                                                                        Unsold
                                                                                    </span>
                                                                                ) : (
                                                                                    <>
                                                                                        <span>
                                                                                            Start
                                                                                            Bid
                                                                                        </span>
                                                                                        <span className="crncyValue">
                                                                                            {currencyFormat(
                                                                                                selectedProduct.sprice,
                                                                                                currency,
                                                                                            )}
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </h5>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : type === 'buynow' ||
                                                              parseInt(selectedProduct.buynow) ==
                                                                  1 ? (
                                                                <div className="mssBidInfo">
                                                                    <h5>
                                                                        <span>Buy Now Price</span>
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                selectedProduct.bprice,
                                                                            ).toFixed(2),
                                                                            currency,
                                                                        )}
                                                                    </h5>
                                                                </div>
                                                            ) : (
                                                                <div className="mssBidInfo">
                                                                    {selectedProduct.latestbid ? (
                                                                        <h5>
                                                                            <span>
                                                                                {selectedProduct.market_status ==
                                                                                'sold'
                                                                                    ? 'Sold Bid'
                                                                                    : 'Current Bid'}
                                                                            </span>
                                                                            <span className="crncyValue">
                                                                                {currencyFormat(
                                                                                    selectedProduct.wprice,
                                                                                    currency,
                                                                                )}
                                                                            </span>
                                                                        </h5>
                                                                    ) : (
                                                                        <h5>
                                                                            {selectedProduct.market_status ==
                                                                            'closed' ? (
                                                                                <span>Unsold</span>
                                                                            ) : (
                                                                                <>
                                                                                    <span>
                                                                                        Start Bid
                                                                                    </span>
                                                                                    <span className="crncyValue">
                                                                                        {currencyFormat(
                                                                                            selectedProduct.sprice,
                                                                                            currency,
                                                                                        )}
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                        </h5>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {selectedProduct.market_status !=
                                                            'draft' ? (
                                                                <>
                                                                    {selectedProduct.auction &&
                                                                    type !== 'buynow' ? (
                                                                        <h5
                                                                            className="mssBidHistory cursorDecoy"
                                                                            onClick={() =>
                                                                                setHistoryData({
                                                                                    product_id:
                                                                                        selectedProduct.id,
                                                                                    end: selectedProduct.date_closed,
                                                                                })
                                                                            }
                                                                        >
                                                                            {selectedProduct?.bids}{' '}
                                                                            bids
                                                                        </h5>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </>
                                                </div>

                                                {
                                                    <>
                                                        <div>
                                                            <div
                                                                className={
                                                                    selectedProduct?.buynow &&
                                                                    'd-none'
                                                                }
                                                            >
                                                                <h5 className="lotNo">
                                                                    <span>Lot Id:</span>
                                                                    {selectedProduct.category_id}
                                                                </h5>
                                                            </div>
                                                            <div className="pvShare">
                                                                <p className="pvMiscLabel">
                                                                    Share with:
                                                                </p>

                                                                <Facebook
                                                                    path={`/product/${selectedProduct.id}`}
                                                                    title={selectedProduct.title}
                                                                />
                                                                <Whatsapp
                                                                    path={`/product/${selectedProduct.id}`}
                                                                    title={selectedProduct.title}
                                                                />
                                                                <Twitter
                                                                    path={`/product/${selectedProduct.id}`}
                                                                    title={selectedProduct.title}
                                                                />
                                                                <Email
                                                                    path={`/product/${selectedProduct.id}`}
                                                                    subject={selectedProduct.title}
                                                                    body={removeHTMLTags(
                                                                        selectedProduct.description,
                                                                    )}
                                                                />
                                                                <Pinterest
                                                                    path={`/product/${selectedProduct.id}`}
                                                                    title={selectedProduct.title}
                                                                    media={
                                                                        `${
                                                                            selectedProduct.content_head1 ===
                                                                            '0'
                                                                                ? process.env
                                                                                      .REACT_APP_BASE_URL +
                                                                                  'uploads/product/'
                                                                                : process.env
                                                                                      .REACT_APP_PRODUCT_IMAGE_URL
                                                                        }` + selectedProduct.avatar
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>

                                            <div className="mssBidItem">
                                                {/* {selectedProduct.market_status != 'draft' &&
                                                selectedProduct.width ? (
                                                    <ShippingRates
                                                        width={selectedProduct.width}
                                                        height={selectedProduct.height}
                                                        length={selectedProduct.length}
                                                        weight={selectedProduct.weight}
                                                        fromzipcode={selectedProduct.zipcode}
                                                    />
                                                ) : null} */}
                                                {selectedProduct.market_status != 'draft' &&
                                                selectedProduct.market_status != 'future' ? (
                                                    <>
                                                        {(selectedProduct.market_status != 'open' ||
                                                            selectedProduct.productClosed) &&
                                                        parseInt(selectedProduct.buynow) == 0 ? (
                                                            <b>Auction Closed</b>
                                                        ) : (
                                                            <div
                                                                className={`sl-pbids slidView ${
                                                                    (!isAuthenticated ||
                                                                        selectedProduct?.buynow) &&
                                                                    'notLogged'
                                                                }`}
                                                            >
                                                                {isAuthenticated ? (
                                                                    <>
                                                                        {selectedProduct.auction ? (
                                                                            type ===
                                                                            'buynow' ? null : (
                                                                                <Fragment>
                                                                                    {selectedProduct?.user_id ==
                                                                                    user?.id ? (
                                                                                        <p>
                                                                                            You
                                                                                            cannot
                                                                                            bid your
                                                                                            own
                                                                                            product!
                                                                                        </p>
                                                                                    ) : (
                                                                                        <>
                                                                                            {global
                                                                                                .storeConfigration
                                                                                                ?.hard_bid
                                                                                                ?.value && (
                                                                                                <div className="bidDet">
                                                                                                    {storeTheme !==
                                                                                                        'ArtAuction' && (
                                                                                                        <p className="slidViewBtnLabel">
                                                                                                            <span className="material-icons">
                                                                                                                info
                                                                                                            </span>
                                                                                                            Place
                                                                                                            Bid
                                                                                                            -
                                                                                                            Bid
                                                                                                            on
                                                                                                            the
                                                                                                            current
                                                                                                            Lot
                                                                                                        </p>
                                                                                                    )}
                                                                                                    {global
                                                                                                        .pluginConfiguration
                                                                                                        ?.reverse_auction
                                                                                                        ?.enable ? (
                                                                                                        <Biddingforwardandreverse
                                                                                                            data={
                                                                                                                selectedProduct
                                                                                                            }
                                                                                                            hard="1"
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <Bidding
                                                                                                            data={
                                                                                                                selectedProduct
                                                                                                            }
                                                                                                            hard="1"
                                                                                                            inputSize={
                                                                                                                storeTheme ===
                                                                                                                'ArtAuction'
                                                                                                                    ? 'large'
                                                                                                                    : 'small'
                                                                                                            }
                                                                                                            enableNextBid={
                                                                                                                storeTheme ===
                                                                                                                'ArtAuction'
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            }
                                                                                                            shortText="Place Bid"
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                            {global
                                                                                                .storeConfigration
                                                                                                ?.proxy_bid
                                                                                                ?.value !=
                                                                                                0 && (
                                                                                                <div className="bidDet">
                                                                                                    <p className="slidViewBtnLabel">
                                                                                                        <span className="material-icons">
                                                                                                            info
                                                                                                        </span>
                                                                                                        Place
                                                                                                        Max
                                                                                                        Bid
                                                                                                        -
                                                                                                        Bid
                                                                                                        on
                                                                                                        the
                                                                                                        current
                                                                                                        lot
                                                                                                    </p>
                                                                                                    {global
                                                                                                        .pluginConfiguration
                                                                                                        ?.reverse_auction
                                                                                                        ?.enable ? (
                                                                                                        <Biddingforwardandreverse
                                                                                                            data={
                                                                                                                selectedProduct
                                                                                                            }
                                                                                                            hard="0"
                                                                                                            status={
                                                                                                                1
                                                                                                            }
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <Bidding
                                                                                                            data={
                                                                                                                selectedProduct
                                                                                                            }
                                                                                                            hard="0"
                                                                                                            status={
                                                                                                                1
                                                                                                            }
                                                                                                            shortText="Place Max Bid"
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </Fragment>
                                                                            )
                                                                        ) : null}

                                                                        {
                                                                            <>
                                                                                {(parseInt(
                                                                                    selectedProduct.buynow,
                                                                                ) == 1 &&
                                                                                    parseInt(
                                                                                        selectedProduct.auction,
                                                                                    ) == 0) ||
                                                                                (parseInt(
                                                                                    selectedProduct.wprice,
                                                                                ) <=
                                                                                    parseInt(
                                                                                        selectedProduct.bprice,
                                                                                    ) &&
                                                                                    parseInt(
                                                                                        selectedProduct.buynow,
                                                                                    ) == 1 &&
                                                                                    parseInt(
                                                                                        selectedProduct.auction,
                                                                                    ) == 1) ? (
                                                                                    <>
                                                                                        <div className="">
                                                                                            {selectedProduct?.user_id ==
                                                                                            user?.id ? (
                                                                                                <p>
                                                                                                    You
                                                                                                    cannot
                                                                                                    buy
                                                                                                    your
                                                                                                    own
                                                                                                    product!
                                                                                                </p>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {itemDetails
                                                                                                        ?.filter(
                                                                                                            (
                                                                                                                i,
                                                                                                            ) =>
                                                                                                                i.id ==
                                                                                                                selectedLot,
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                d,
                                                                                                            ) =>
                                                                                                                d.buynowpaid,
                                                                                                        ) ==
                                                                                                    1 ? (
                                                                                                        <h5 className="itemPurchased">
                                                                                                            You
                                                                                                            have
                                                                                                            purchased
                                                                                                            this
                                                                                                            item
                                                                                                        </h5>
                                                                                                    ) : (
                                                                                                        <PrimaryButton
                                                                                                            onClick={
                                                                                                                selectedProduct.market_status ==
                                                                                                                'open'
                                                                                                                    ? handleClick
                                                                                                                    : ''
                                                                                                            }
                                                                                                            disabled={
                                                                                                                disabled
                                                                                                            }
                                                                                                            btnSize="bynwBtn"
                                                                                                        >
                                                                                                            {selectedProduct.market_status ==
                                                                                                            'sold'
                                                                                                                ? 'Lot Sold'
                                                                                                                : disabled
                                                                                                                ? 'Added to cart'
                                                                                                                : `Buy now for ${currencyFormat(
                                                                                                                      parseFloat(
                                                                                                                          selectedProduct.bprice,
                                                                                                                      ).toFixed(
                                                                                                                          2,
                                                                                                                      ),
                                                                                                                      currency,
                                                                                                                  )}`}
                                                                                                        </PrimaryButton>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                ) : null}
                                                                            </>
                                                                        }
                                                                    </>
                                                                ) : null}
                                                                {!isAuthenticated && (
                                                                    <PrimaryButton
                                                                        btnSize="byorBdBtn"
                                                                        className="my-2"
                                                                        onClick={() =>
                                                                            history.push('/login')
                                                                        }
                                                                    >
                                                                        Login to{' '}
                                                                        {selectedProduct?.buynow &&
                                                                        selectedProduct?.auction
                                                                            ? 'Buy Or Bid'
                                                                            : selectedProduct?.buynow
                                                                            ? 'Buy'
                                                                            : 'Bid'}
                                                                    </PrimaryButton>
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>

                                            {
                                                <>
                                                    {lotInformation.map((data, index) => (
                                                        <div key={index} className="mssDesc mt-3">
                                                            <h6>{data.title}</h6>
                                                            <div
                                                                className="descriptionContainer dscrptnWthScrl mb-1"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: data.description,
                                                                }}
                                                            ></div>
                                                            {/* {data.description &&
                                                            data.description.length > 120 ? (
                                                                <>
                                                                    {showDescription ? (
                                                                        <div
                                                                            className="descriptionContainer mb-1"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: data.description,
                                                                            }}
                                                                        ></div>
                                                                    ) : (
                                                                        <div
                                                                            className="descriptionContainer mb-1"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    data.description.slice(
                                                                                        0,
                                                                                        120,
                                                                                    ) + '...',
                                                                            }}
                                                                        ></div>
                                                                    )}
                                                                    <div className="text-right mt-2">
                                                                        {showDescription ? (
                                                                            <span
                                                                                className="mb-3 cursorDecoy shwMrBtn ml-auto"
                                                                                onClick={() =>
                                                                                    setShowDescription(
                                                                                        false,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <b>
                                                                                    <u>Show Less</u>
                                                                                </b>
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="mb-3 cursorDecoy shwMrBtn ml-auto"
                                                                                onClick={() =>
                                                                                    setShowDescription(
                                                                                        true,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <b>
                                                                                    <u>Show More</u>
                                                                                </b>
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        className="descriptionContainer mb-1"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: data.description,
                                                                        }}
                                                                    ></div>
                                                                </>
                                                            )} */}
                                                            {/* <div className="">
                                                                <p
                                                                    className={`descriptionContainer mb-1 ${
                                                                        showDescription
                                                                            ? ''
                                                                            : 'textHide'
                                                                    }`}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: data.description,
                                                                    }}
                                                                ></p>
                                                                
                                                            </div> */}
                                                        </div>
                                                    ))}
                                                </>
                                            }

                                            <div className="mssDesc mt-3">
                                                <h6 className="mb-2">Payment Info</h6>
                                                <div>
                                                    <p className="mb-0">Credit Card / Debit Card</p>
                                                </div>
                                            </div>

                                            {storeTheme === 'ArtAuction' && (
                                                <>
                                                    {lotInformation.map((data, index) => (
                                                        <Accordion
                                                            square
                                                            expanded={expanded === `panel${index}`}
                                                            key={1}
                                                            onChange={handleChange(`panel${index}`)}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={`panel${index}}d-content`}
                                                                id={`panel${index}}d-header`}
                                                            >
                                                                <h6 className="accTitle">
                                                                    {data.title}
                                                                </h6>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <p
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: data.description,
                                                                    }}
                                                                ></p>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))}
                                                </>
                                            )}

                                            {storeTheme === 'foodindustry' && (
                                                <>
                                                    <div className="d-flex justify-content-between align-items-center pvTitleContainer flex-wrap">
                                                        <div
                                                            className={
                                                                selectedProduct?.buynow && 'd-none'
                                                            }
                                                        >
                                                            <h5 className="lotNo">
                                                                Lot# {selectedProduct.category_id}
                                                            </h5>
                                                            {parseInt(selectedProduct.buynow) ==
                                                                0 &&
                                                            (parseInt(selectedProduct.lowest) > 0 ||
                                                                parseInt(selectedProduct.highest) >
                                                                    0) ? (
                                                                <p>
                                                                    <span>Est:</span>
                                                                    {`${
                                                                        ' ' +
                                                                        currencyFormat(
                                                                            selectedProduct.lowest,
                                                                            currency,
                                                                        ) +
                                                                        ' - ' +
                                                                        currencyFormat(
                                                                            selectedProduct.highest,
                                                                            currency,
                                                                        )
                                                                    }`}
                                                                </p>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                    <Button
                                                        className="d-none productPageAct"
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                `productView/${selectedProduct.id}`,
                                                            )
                                                        }
                                                    >
                                                        View Full Details
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="pvsBottom">
                                        {lotAdditionalInformation &&
                                        Object.keys(lotAdditionalInformation).length ? (
                                            <>
                                                <AppBar position="static">
                                                    <Tabs
                                                        value={value}
                                                        variant={
                                                            matches ? 'scrollable' : 'fullWidth'
                                                        }
                                                        onChange={handleTabChange}
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"
                                                    >
                                                        {lotAdditionalInformation.map(
                                                            (data, index) => (
                                                                <Tab
                                                                    label={data.title}
                                                                    {...a11yProps({ index })}
                                                                />
                                                            ),
                                                        )}
                                                    </Tabs>
                                                </AppBar>
                                                {lotAdditionalInformation.map((data, index) => (
                                                    <TabPanel
                                                        value={value}
                                                        index={index}
                                                        key={index}
                                                    >
                                                        {data.html ? (
                                                            data.description
                                                        ) : (
                                                            <div
                                                                className="content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: data.description,
                                                                }}
                                                            />
                                                        )}
                                                    </TabPanel>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <Bidhistory
                                modaltitle="Bid history"
                                open={Object.keys(historyData).length !== 0}
                                value={historyData}
                                handleClose={() => setHistoryData({})}
                            />
                        </div>
                    )}
                </>
            )}
            <HowBidPopup open={howBidPopup} handleClose={() => setHowBidPopup(false)} />
        </>
    )

    return viewPage ? (
        render
    ) : (
        <Drawer
            className="bottomDrawer slidePopup"
            anchor={'bottom'}
            open={selectedLot}
            onClose={handleClose}
        >
            {render}
        </Drawer>
    )
}

export default SlidePopup
