import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Layout from '../Layout'
import Banner from './Banner'
import UpcomingAuctions from './upcomingAuctions'
import LiveAuctions from './LiveAuction'
import News from './news'
import InstaSlider from './insta'
import './home.css'
import InstaFeeds from './InstagramPosts/InstaFeeds'
import { handleRedirectInternal } from '../../../Product/common/components'
const csinsta = [
    {
        img: '/phill/cs-insta1.png',
    },
    {
        img: '/phill/cs-insta2.png',
    },
    {
        img: '/phill/cs-insta3.png',
    },
    {
        img: '/phill/cs-insta4.png',
    },
    {
        img: '/phill/cs-insta5.png',
    },
    {
        img: '/phill/cs-insta5.png',
    },
]
const insta = [
    {
        img: '/phill/phg-insta1.png',
    },
    {
        img: '/phill/phg-insta2.png',
    },
    {
        img: '/phill/phg-insta3.png',
    },
    {
        img: '/phill/phg-insta4.png',
    },
    {
        img: '/phill/phg-insta5.png',
    },
    {
        img: '/phill/phg-insta5.png',
    },
]

const Home = (props) => {
    const history = useHistory()
    return (
        <Layout>
            <div className="home-container">
                <Banner />
                <div className="fu-head clearfix">Live Auctions</div>
                <LiveAuctions />
                <div className="fu-head clearfix">Upcoming Auctions</div>
                <UpcomingAuctions />
                <div className="container au-container">
                    <div className="row mt-5 mb-5">
                        <div className="col-sm-4">
                            <div className="media">
                                <div className="media-left">
                                    <div className="au-circle">
                                        <img src="/phill/bill-wave.png" alt="" className="au-img" />
                                    </div>
                                </div>
                                <div className="media-bdoy">
                                    <h2>Get Estimate</h2>
                                    <p>
                                        Contact us to get an auction estimate.
                                        <br />
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>
                                                handleRedirectInternal(history, 'contact_us')
                                            }
                                            className="sm-link"
                                        >
                                            here
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="media">
                                <div className="media-left">
                                    <div className="au-circle">
                                        <img src="/phill/gavel.png" alt="" className="au-img" />
                                    </div>
                                </div>
                                <div className="media-bdoy">
                                    <h2>Sell with Us</h2>
                                    <p>
                                        Access your Buyer Guide
                                        <br />
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>
                                                handleRedirectInternal(history, 'contact_us')
                                            }
                                            className="sm-link"
                                        >
                                            here
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="media">
                                <div className="media-left">
                                    <div className="au-circle">
                                        <img src="/phill/gem.png" alt="" className="au-img" />
                                    </div>
                                </div>
                                <div className="media-bdoy">
                                    <h2>Buy From Us</h2>
                                    <p>
                                        Access your seller guide
                                        <br />
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>
                                                handleRedirectInternal(history, 'how_it_works')
                                            }
                                            className="sm-link"
                                        >
                                            here
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fu-head clearfix">Our News</div>
                <News />
                {/* <div className="fu-head clearfix">Follow Us on Instagram</div>

                <InstaSlider title="@thecuriosities_com" data={csinsta} />
                <InstaSlider title="@philipgate.official" data={insta} /> */}
            </div>
        </Layout>
    )
}

export default Home
