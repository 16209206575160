import React, { useEffect, useState, useContext } from 'react'
import MessageBody from './MessageBody'
import './Messages.css'
import UserCard from './UserCard'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'

const UserMessage = ({ project_id, backImage }) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const { list_buyers, chat_history, listAllBuyers, getChatHistory } = buyerContext
    const { isAuthenticated } = authContext
    const [chated_history, setChatHistory] = useState([])
    const [chat_display, setChatDisplay] = useState(true)
    const [viewUsers, setViewUsers] = useState(false)
    const isMobile = useMediaQuery({
        query: '(max-width: 700px)',
    })
    const [buyersValue, setBuyersValue] = useState({
        limit_value: 0,
        search_key: ' ',
        user_role: project_id ? undefined : 'both',
        project_id,
    })
    const { t } = useTranslation()
    const [chatUser, setChatUser] = useState({})

    const updatechatuser = (data) => {
        if (parseInt(data.msg_count) > 0) {
            data.msg_count = 0
        }
        setChatDisplay(false)
        setChatUser(data)
        setTimeout(() => {
            setChatDisplay(true)
        }, 500)
    }

    // useEffect(() => {
    //     setChatDisplay(true)
    // }, [chatUser])

    useEffect(() => {
        if (isAuthenticated) {
            listAllBuyers(buyersValue)
        }
    }, [buyersValue, isAuthenticated])
    useEffect(() => {
        if (isAuthenticated) {
            if (Object.keys(chatUser).length) {
                getChatHistory({ chat_id: chatUser.id, project_id: project_id })
            }
        }
    }, [chatUser, isAuthenticated])
    useEffect(() => {
        if (list_buyers.length) {
            list_buyers.map((data) => {
                if (parseInt(data.msg_count) > 0 && parseInt(chatUser.id) == parseInt(data.id)) {
                    data.msg_count = 0
                    setChatUser(data)
                }
            })
            if (!Object.keys(chatUser).length) {
                setChatUser(list_buyers[0])
            }
        }
    }, [list_buyers])

    useEffect(() => {
        if (chat_history?.length > 0) {
            setChatHistory(chat_history)
        } else {
            setChatHistory([])
        }
    }, [chat_history])

    return (
        <>
            {isMobile && (
                <Button className="mb-2" onClick={() => setViewUsers(true)}>
                    <span className="material-icons mr-1">contacts</span> User List
                </Button>
            )}
            <div className="msgPgWrpr">
                <div className={`lftMsgWrpr ${viewUsers ? 'showUsers' : ''}`}>
                    <div className="topCntnr">
                        <div className="srchBoxCntnr">
                            <span className="material-icons">search</span>
                            <input
                                placeholder={t('search')}
                                onChange={(e) =>
                                    setBuyersValue({
                                        ...buyersValue,
                                        search_key: e.target.value === '' ? ' ' : e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="usrListWrpr" onClick={() => setViewUsers(false)}>
                        {list_buyers
                            .sort((a, b) => b.msg_count - a.msg_count)
                            .map((data) => (
                                <UserCard
                                    id={data.id}
                                    f_name={data.first_name}
                                    l_name={data.last_name}
                                    msg={data.message}
                                    time={data.date_sent}
                                    msg_count={data.msg_count}
                                    onClick={() => updatechatuser(data)}
                                    selected_id={chatUser.id}
                                />
                            ))}
                    </div>
                </div>
                <div className="rtMsgWrpr">
                    {chat_display ? (
                        <MessageBody
                            chatHistory={chated_history}
                            chatUser={chatUser}
                            backImage={backImage ? backImage : ''}
                            project_id={project_id}
                            chat_display={chat_display}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </>
    )
}

export default UserMessage
