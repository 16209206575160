import React, { useEffect, useState, useContext } from 'react'
import './gallery.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import Layout from '../Layout'
import { Pagination } from '@material-ui/lab'
import Loaders from '../../../Product/components/molecules/Loaders'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'

const GalleryComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])

    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)

    const { search_allproducts, getAllSearchProducts } = productContext

    const { isAuthenticated } = authContext

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalViewProduct, setTotalViewProduct] = useState(0)

    const [productSearch, setProductSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    useEffect(() => {
        getAllSearchProducts(productSearch, 'galleryPage')
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        setLoading(true)
        if (search_allproducts.from === 'galleryPage') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalViewProduct(search_allproducts.total_pagecnt)
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        setProductSearch({ ...productSearch, page: value })
        window.scrollTo(0, 0)
    }

    return (
        <Layout props={props}>
            <UserActivity page="buynow_page" />
            <div className="container mt-5">
                {isLoading ? (
                    <Loaders name="product_grid_view" isLoading={isLoading} loop={4} />
                ) : viewProduct.length > 0 ? (
                    <>
                        <h2 className="buyNowTitle">Buy Now Items</h2>
                        <SearchProductList
                            listview={listview}
                            searchResult={viewProduct}
                            className="liquidationBuyCard"
                            type="buynow"
                            noTimer={true}
                            enableZoomOnHover={true}
                            setViewProduct={setViewProduct}
                        />
                        {totalViewProduct > productSearch.sh_limit ? (
                            <Pagination
                                count={Math.ceil(totalViewProduct / productSearch.sh_limit)}
                                page={productSearch.page}
                                onChange={onHandlePage}
                            />
                        ) : null}
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
        </Layout>
    )
}

export default GalleryComponent
