/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Button, Popover, ListItem, Divider, SwipeableDrawer, MenuItem } from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../../../utils/index'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import BecomeSeller from '../../../utils/CommonFunctionality/BecomeSeller'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import PaymentPendingHeader from '../../../utils/CommonFunctionality/PaymentPendingHeader'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'

const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const linkdinLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [open, setOpen] = useState(false)

    const { user, isAuthenticated, logout } = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
        left: false,
    })

    const [listingAnchor, setListingAnchor] = useState(null)
    const listingOpen = Boolean(listingAnchor)

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }
    const redirectToStripe = () => {
        history.push('/dashboard/profile')
        window.scrollTo({ top: 720, left: 0, behavior: 'smooth' })
    }
    const handleListingClick = (type) => {
        if (global.pluginConfiguration?.paypal_payout?.enable == 1 && user?.verify_paypal == 0) {
            setAlert('Kindly login with paypal to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return global.storeDetails.theme === 7
                ? redirectToStripe()
                : handleRedirectInternal(history, '/dashboard/profile')
        } else if (type == 'auction') {
            return handleRedirectInternal(history, 'post-auction')
        } else {
            return handleRedirectInternal(history, 'post-buynow?type=buynow')
        }
    }
    const handleListingClose = () => {
        setListingAnchor(null)
    }
    const toggleOpen = () => {
        setOpen(!open)
    }

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const logoutUser = () => {
        logout()
        history.push('/login')
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search?cat=${catId}`)
    }

    return (
        <>
            {isDesktop ? (
                /* DESKTOP NAVIGATION */
                <header className="mainHeader noPrint">
                    <div className="customContainer d-flex justify-content-between align-items-center h-100">
                        <div className="headLt d-flex justify-content-start align-items-center">
                            <Logo className="headerLogo" />
                        </div>
                        <HeaderSearch />
                        <div className="headRt d-flex justify-content-start align-items-center">
                            <ul className="d-flex justify-content-start align-items-center">
                                {isAuthenticated ? (
                                    <>
                                        {global.storeConfigration?.need_post_buynow?.value != 0 ? (
                                            <li>
                                                <CartIcon />
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                        <li>
                                            <Button
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={handleMenu}
                                            >
                                                My Account
                                                <span className="material-icons">
                                                    account_circle
                                                </span>
                                            </Button>

                                            <Popover
                                                id={'simple-menu'}
                                                open={Boolean(anchormenu)}
                                                anchorEl={anchormenu}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <ul className="headerDropMenu">
                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleClose(
                                                                history,
                                                                `${
                                                                    user && user.role == 1
                                                                        ? 'dashboard/overview'
                                                                        : 'dashboard/mybids'
                                                                }`,
                                                            )
                                                        }
                                                    >
                                                        Dashboard
                                                    </ListItem>
                                                    {/* <ListItem
                                                    button
                                                    onClick={() => handleClose(history, 'cart')}
                                                >
                                                    Cart
                                                </ListItem> */}

                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleClose(
                                                                history,
                                                                'dashboard/profile',
                                                            )
                                                        }
                                                    >
                                                        Profile
                                                    </ListItem>
                                                    <ListItem button onClick={logoutUser}>
                                                        Logout
                                                    </ListItem>
                                                </ul>
                                            </Popover>
                                        </li>
                                    </>
                                ) : (
                                    <li className="headerAuthBtn">
                                        <span className="material-icons">account_circle</span>
                                        <NavLink activeClassName="active" to="/login">
                                            Login
                                        </NavLink>{' '}
                                        /{' '}
                                        <NavLink activeClassName="active" to="/sign-up">
                                            Sign up
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="customContainer">
                        <div className="headBottomNav">
                            <ul className="d-flex justify-content-start align-items-center">
                                <li>
                                    <PrimaryButton onClick={toggleDrawer('left', true)}>
                                        <span className="material-icons">menu</span> Shop By
                                        Category
                                    </PrimaryButton>
                                </li>
                                {global.storeConfigration?.disabled_auc_mulsel2?.value != 1 ? (
                                    <li className="ho-orange">
                                        <NavLink activeClassName="active" exact to="/auctions">
                                            Current Auctions
                                        </NavLink>
                                    </li>
                                ) : (
                                    ''
                                )}

                                {global.storeConfigration?.need_post_buynow?.value != 0 ? (
                                    <li className="ho-orange">
                                        <PrimaryButton
                                            onClick={(e) =>
                                                handleRedirectInternal(history, 'gallery')
                                            }
                                        >
                                            <span class="material-icons">collections</span>
                                            Buy Now
                                        </PrimaryButton>
                                    </li>
                                ) : (
                                    ''
                                )}
                                {/* {user && user.role == 0 && (
                                    <li
                                        onClick={() => {
                                            setOpen(true)
                                        }}
                                    >
                                        Sell
                                    </li>
                                )} */}

                                {user && (
                                    <>
                                        {global.storeConfigration?.disabled_auc_mulsel2?.value !=
                                        1 ? (
                                            <li>
                                                <PrimaryButton
                                                    onClick={(e) => handleListingClick('auction')}
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Auction Setup
                                                </PrimaryButton>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                        {global.storeConfigration?.need_post_buynow?.value != 0 ? (
                                            <li onClick={() => handleListingClick('buynow')}>
                                                <NavLink activeClassName="active" exact to="#">
                                                    Post Buynow
                                                </NavLink>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}

                                <li>
                                    <NavLink activeClassName="active" exact to="/contact_us">
                                        Contact Us
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink activeClassName="active" exact to="/aboutus">
                                        About
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink activeClassName="active" exact to="/how_it_works">
                                        How To Sell
                                    </NavLink>
                                </li>
                                <Headernavmenu type="desk" />
                            </ul>
                            <ul className="contactNav d-flex justify-content-start align-items-center">
                                {global.storeConfigration?.disable_phone_number?.value == 1 ? (
                                    <li>
                                        <a href={`tel: ${contactPhone}`}>{contactPhone}</a>
                                    </li>
                                ) : (
                                    ''
                                )}
                                <li className="socialIcons">
                                    {facebookLink && (
                                        <a
                                            href={facebookLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Facebook"
                                        >
                                            <i className="fab fa-facebook"></i>
                                        </a>
                                    )}
                                    {twitterLink && (
                                        <a
                                            href={twitterLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Twitter"
                                        >
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    )}
                                    {linkdinLink && (
                                        <a
                                            href={linkdinLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="LinkedIn"
                                        >
                                            <i className="fab fa-linkedin"></i>
                                        </a>
                                    )}
                                    {instagramLink && (
                                        <a
                                            href={instagramLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Instagram"
                                        >
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader mobHeader customContainer noPrint d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('top', true)}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>

                                <li className="headCart ml-3">
                                    {global.storeConfigration?.need_post_buynow?.value != 0 ? (
                                        <CartIcon />
                                    ) : (
                                        ''
                                    )}
                                    {/* <Button
                                        className="respNavBtn"
                                        onClick={() => handleRedirectInternal(history, 'cart')}
                                    >
                                        <span className="material-icons">shopping_cart</span>
                                    </Button> */}
                                </li>

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <HeaderSearch />
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/" exact>
                                            <span className="material-icons">home</span> Home
                                        </NavLink>
                                    </ListItem>
                                    {!isAuthenticated && (
                                        <>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/login">
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        how_to_reg
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}
                                    {isAuthenticated && (
                                        <div className="sprtCntnr">
                                            <h5>Buy</h5>
                                        </div>
                                    )}
                                    {global.storeConfigration?.disabled_auc_mulsel2?.value != 1 ? (
                                        <ListItem button>
                                            <NavLink activeClassName="active" exact to="/auctions">
                                                <span class="material-icons">manage_search</span>
                                                Auctions
                                            </NavLink>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    {global.storeConfigration?.need_post_buynow?.value != 0 ? (
                                        <ListItem button>
                                            <NavLink activeClassName="active" exact to="/gallery">
                                                <span class="material-icons">collections</span>
                                                Buy Now
                                            </NavLink>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    {isAuthenticated && (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/profile"
                                                >
                                                    <span className="material-icons">person</span>
                                                    Profile
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/favorites"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    Favorites
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mybids"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Bids
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/transactions"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    Transactions
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    Saved Cards
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/savedSearch"
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    Saved Search
                                                </NavLink>
                                            </ListItem>
                                            {/* {user && user.role == 0 && (
                                                <ListItem button>
                                                    <NavLink
                                                        exact
                                                        to="#"
                                                        activeClassName="active"
                                                        onClick={() => {
                                                            setOpen(true)
                                                        }}
                                                    >
                                                        Sell
                                                    </NavLink>
                                                </ListItem>
                                            )} */}
                                        </>
                                    )}

                                    {isAuthenticated && user && user.role == 1 && (
                                        <>
                                            <div className="sprtCntnr">
                                                <h5>Sell</h5>
                                            </div>
                                            {user ? (
                                                <>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/overview"
                                                        >
                                                            <span className="material-icons">
                                                                dashboard
                                                            </span>
                                                            Dashboard
                                                        </NavLink>
                                                    </ListItem>
                                                    {global.storeConfigration?.disabled_auc_mulsel2
                                                        ?.value != 1 ? (
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleListingClick('auction')
                                                            }
                                                        >
                                                            <NavLink
                                                                activeClassName="inactive"
                                                                className={
                                                                    window.location.pathname.includes(
                                                                        '/post-auction',
                                                                    )
                                                                        ? 'active'
                                                                        : ''
                                                                }
                                                                exact
                                                                to="#"
                                                            >
                                                                <span class="material-icons">
                                                                    post_add
                                                                </span>
                                                                Auction Setup
                                                            </NavLink>
                                                        </ListItem>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {global.storeConfigration?.need_post_buynow
                                                        ?.value != 0 ? (
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleListingClick('buynow')
                                                            }
                                                        >
                                                            <NavLink
                                                                activeClassName="inactive"
                                                                className={
                                                                    window.location.pathname.includes(
                                                                        '/post-buynow',
                                                                    )
                                                                        ? 'active'
                                                                        : ''
                                                                }
                                                                exact
                                                                to="#"
                                                            >
                                                                <span class="material-icons">
                                                                    post_add
                                                                </span>
                                                                Post Buynow
                                                            </NavLink>
                                                        </ListItem>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {global.storeConfigration?.disabled_auc_mulsel2
                                                        ?.value != 1 ? (
                                                        <ListItem button>
                                                            <NavLink
                                                                activeClassName="active"
                                                                to="/sellerdashboard"
                                                            >
                                                                <span className="material-icons">
                                                                    gavel
                                                                </span>
                                                                My Auctions
                                                            </NavLink>
                                                        </ListItem>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/reports"
                                                        >
                                                            <span className="material-icons">
                                                                description
                                                            </span>
                                                            Reports
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/photouploader"
                                                        >
                                                            <span class="material-icons">
                                                                add_photo_alternate
                                                            </span>
                                                            Photo Uploader
                                                        </NavLink>
                                                    </ListItem>

                                                    {/* <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/onboard"
                                                        >
                                                            <span className="material-icons">
                                                                payments
                                                            </span>
                                                            Payment Gateway Onboard
                                                        </NavLink>
                                                    </ListItem> */}
                                                </>
                                            ) : (
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/mybids"
                                                    >
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                        My Bids
                                                    </NavLink>
                                                </ListItem>
                                            )}

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/invoices"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    Invoice
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/dashboard/preference"
                                        >
                                            <span className="material-icons">notifications</span>
                                            Notification Preferences
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            <span className="material-icons">groups</span>
                                            About Us
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/faq">
                                            <span className="material-icons">quiz</span>
                                            FAQs
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            <span className="material-icons">auto_stories</span>
                                            Terms & Conditions
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/contact_us">
                                            <span className="material-icons">contact_support</span>
                                            Contact Us
                                        </NavLink>
                                    </ListItem>

                                    <Headernavmenu type="mobile" />

                                    {isAuthenticated && (
                                        <ListItem button onClick={logoutUser}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItem>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <PaymentPendingHeader />
            <React.Fragment>
                <SwipeableDrawer
                    className="catgDrawer"
                    anchor={'left'}
                    open={state['left']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                >
                    <div className="catgHead">
                        <h3>Categories</h3>
                        <Button onClick={toggleDrawer('left', false)}>
                            <span className="material-icons">chevron_left</span>
                        </Button>
                    </div>
                    {filterValues[0].options.length ? (
                        <ul className="catgLinks" onClick={toggleDrawer('left', false)}>
                            {filterValues[0].options.map((data, index) => (
                                <ListItem
                                    button
                                    className="homeCatgInner"
                                    key={index}
                                    onClick={() => handleClick(data.id)}
                                >
                                    {data.name}
                                </ListItem>
                            ))}
                        </ul>
                    ) : (
                        <div className="noCatg">
                            <img src="/assets/svg/noProduct.svg" alt="No Categories" />
                            <h6>No Categories Found</h6>
                        </div>
                    )}
                </SwipeableDrawer>
            </React.Fragment>
            <CustomDialog title={'Become A Seller'} open={open} function={toggleOpen}>
                <BecomeSeller open={open} setOpen={setOpen} />
            </CustomDialog>
        </>
    )
}

export default React.memo(Header)
