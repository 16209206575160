import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import ReadMoreReact from 'read-more-react'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import Timer from '../../../Product/common/timer'
import { capitalize } from '../../../Product/common/components'
import ReactHtmlParser from 'react-html-parser'
const ProductAuctionTopComponent = (props) => {
    const [image, setImage] = useState([])

    useEffect(() => {
        let imageUrl = `${process.env.REACT_APP_PRODUCT_IMAGE_URL}${props.auctionData.avatar}`
        setImage([{ original: imageUrl, thumbnail: imageUrl }])
    }, [props.auctionData])

    return (
        <div className="media pv-media product-media-wrapper flex-wrap">
            <div className="media-left">
                {props.auctionData.avatar ? (
                    <ImageGallery
                        items={image}
                        thumbnailPosition="bottom"
                        showNav={false}
                        showBullets={false}
                        showFullscreenButton={true}
                        showPlayButton={false}
                        originalClass="pro-img img-fluid"
                        showThumbnails={false}
                    />
                ) : (
                    // <Skeleton  height={345} width={475} />

                    //  src = `${global?.storeDetails?.logoValue}`
                    <img src={`${global?.storeDetails?.logoValue}`} className="pro-img img-fluid" />
                )}
                {/* <img src={ `https://forwardapi.auctionsoftware.com/uploads/product/${props.auctionData.avatar}`} className="pro-img img-fluid"/> */}
            </div>
            {props.auctionData.title !== undefined && (
                <div className="media-body">
                    <div className="row">
                        <div className="col-md-8">
                            <h2
                                className="pv-title mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: capitalize(props.auctionData.title),
                                }}
                            />
                            {props.auctionData.date_added ? (
                                <div className="pv-con">
                                    <span>Auction Start Date: </span>
                                    {moment(props.auctionData.date_added)
                                        .tz(moment.tz.guess())
                                        .format('MMM Do YYYY, h:mm a z')}{' '}
                                </div>
                            ) : (
                                <Skeleton width={80} />
                            )}
                            <div className="pv-con">
                                <span>Auction Begins To End: </span>
                                {moment(props.minMaxDateClosed.min_date_closed)
                                    .tz(moment.tz.guess())
                                    .format('MMM Do YYYY, h:mm a z')}
                            </div>
                            <div className="pv-con">
                                <span>Last Item Approx Ends On: </span>
                                {moment(props.minMaxDateClosed.max_date_closed)
                                    .tz(moment.tz.guess())
                                    .format('MMM Do YYYY, h:mm a z')}
                            </div>

                            {global.storeConfigration?.show_buyer_premium?.value == 1 ? (
                                <div className="pv-con">
                                    <span>{"Buyer's Premium: "}</span>
                                    {props.auctionData.la_seller_id + ' %'}
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="pv-con aucDesc">
                                <span>Auction Description:</span>
                            </div>
                            {/* <ReadMoreReact */}
                            {ReactHtmlParser(props.auctionData.description)}
                            {/* //     min={240}
                            //     ideal={300}
                            //     max={600}
                            //     readMoreText={'READ MORE'}
                            // /> */}
                        </div>
                        <div className="col-md-4 text-center-md">
                            <div className="timer-wrapper">
                                <div>
                                    {/* <img src="/image/timer.svg" alt="" className="lv-timer-new" /> */}
                                </div>
                                <div className="li-timer">
                                    <Timer
                                        date_added={props.auctionData.date_added}
                                        date_closed={props.auctionData.date_closed}
                                        withText={1}
                                        icon={true}
                                        endText={'Ends in' + ': '}
                                        startText={'Starts in' + ': '}
                                    ></Timer>
                                </div>
                            </div>

                            {parseInt(props.auctionData.stage_id) > 0 ? (
                                <div className="ndCTA">
                                    <PrimaryButton
                                        label="Buy Tickets"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `ticketing/${props.auctionData.stage_id}/${props.auctionData.id}`,
                                            )
                                        }
                                    />
                                    <br></br>
                                </div>
                            ) : null}

                            <div className="ndCTA">
                                <PrimaryButton label="Browse lots" onClick={props.executeScroll} />
                                <div className="arrow">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProductAuctionTopComponent
