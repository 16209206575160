import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import './feedback.css'
import authContext from '../../../Product/context/auth/authContext'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import { Box, Typography } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { mapData } from '../../../Product/common/components'
import alertContext from '../../../Product/context/alert/alertContext'
import { imageError } from '../../commonFunctions'

const AddFeedback = ({ item, closePopup, clearSelectedItem }) => {
    const { user } = useContext(authContext)
    const { setAlert } = useContext(alertContext)
    const {
        getFeedbackField,
        feedback_fields,
        single_feedback,
        addFeedback,
        getproFeedback,
        responseStatus: pluginResponseStatus,
    } = useContext(pluginContext)
    const [feedbackerror, setFeedError] = useState('')

    const [loading, setLoading] = useState(false)
    const [initialLoad, setInitialLoad] = useState(true)
    const [feedbackFields, setFeedbackFields] = useState([])
    const [proFeedback, setProFeedback] = useState({})

    const validationArray = Yup.object({
        subject: Yup.string().trim().required('Required'),
        message: Yup.string().trim().required('Required'),
    })

    useEffect(() => {
        getFeedbackField({ site_id: global?.storeDetails?.site_id })
        getproFeedback({
            user_id: user?.id,
            site_id: global?.storeDetails?.site_id,
            lotid: item.id,
            auction_id: '',
            // seller_id: item.user_id,
        })
    }, [])
    useEffect(() => {
        let arr = feedback_fields
        arr.sort((a, b) => parseInt(a.position) - parseInt(b.position)).map((val, index) => {
            if (Object.keys(single_feedback).length) {
                val.rating = JSON.parse(single_feedback.overall_rating)[index]['rating']
            } else {
                val.rating = 0
            }
        })
        setFeedbackFields(arr)
    }, [feedback_fields, single_feedback])

    const handleRating = (value, index) => {
        let temp = [...feedbackFields]
        temp[index].rating = value
        setFeedbackFields([...temp])
    }
    const formik = useFormik({
        initialValues: {
            subject: '',
            messgae: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            var feedbackerrornew = ''
            feedbackFields.map((values) => {
                if (parseInt(values.rating ? values.rating : 0) == 0) {
                    feedbackerrornew = 'Please rate all the fields'
                }
            })

            setFeedError(feedbackerrornew)
            if (feedbackerrornew) {
                return false
            } else {
                const body = {
                    site_id: global?.storeDetails?.site_id,
                    lotid: item.id,
                    auction_id: item.auction_id,
                    user_id: user?.id,
                    seller_id: item.user_id,
                    lot_title: item.title,
                    overall_avg:
                        feedbackFields.reduce((total, next) => total + next.rating, 0) /
                        feedbackFields.length,
                    overall_rating: feedbackFields.map((val) => ({
                        feedback_filed: val.field_name,
                        rating: val.rating,
                    })),
                    ...values,
                }
                setLoading(true)
                addFeedback(body)
            }
        },
    })

    useEffect(() => {
        setProFeedback(single_feedback)
    }, [single_feedback])
    useEffect(() => {
        formik.setFieldValue('subject', proFeedback?.subject)
        formik.setFieldValue('message', proFeedback?.message)
    }, [proFeedback])

    // useEffect(() => {
    //     if (Object.keys(proFeedback).length && feedbackFields.length && initialLoad) {
    //         let temp_field = feedbackFields
    //         temp_field.map((val, index) => {
    //             val.rating = JSON.parse(proFeedback.overall_rating)[index]['rating']
    //         })
    //         setFeedbackFields([...temp_field])
    //         setInitialLoad(false)
    //     }
    // }, [proFeedback, feedbackFields])
    useEffect(() => {
        if (pluginResponseStatus) {
            if (pluginResponseStatus.from === 'addFeedback') {
                if (pluginResponseStatus.status === 'success') {
                    if (closePopup) {
                        closePopup()
                    }
                    if (clearSelectedItem) {
                        clearSelectedItem()
                    }
                    setAlert('Feedback Added Successfully!', 'success')
                }
                setLoading(false)
            }
        }
    }, [pluginResponseStatus])

    const feedbackInfo = {
        formik: formik,
        data: [
            {
                label: 'Review Headline* (if you could say it in one sentence, what would you say?)',
                name: 'subject',
                type: 'text',
                placeholder: 'Enter your review headline',
                upperLabel: true,
                class: 'col-12',
                autoFocus: true,
            },
            {
                label: 'Write Your Review* ',
                name: 'message',
                type: 'textarea',
                placeholder: 'Enter your review',
                upperLabel: true,
                class: 'col-12',
            },
        ],
    }

    return (
        <>
            <div className="custom-container-box Extra profile-form-wrapper">
                <div className="form-details profile-form-wrap mx-auto">
                    <form className="reg-step1 flex-row" onSubmit={formik.handleSubmit}>
                        <img
                            src={`${
                                item?.content_head1 === '0' || item?.store_id === 0
                                    ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                                    : process.env.REACT_APP_PRODUCT_IMAGE_URL
                            }${
                                item.file_name ||
                                (Array.isArray(item.lotImages) &&
                                    item.lotImages?.length &&
                                    item.lotImages[0]?.file_name) ||
                                item.avatar_vc
                            }`}
                            onError={(e) => (e.target.src = imageError())}
                            width="320"
                            height="320"
                        />
                        <div className="row column ml-10">
                            <div>
                                <p className="label-header blue">{item.title}</p>
                                <p className="label-header">
                                    <span>Seller: </span>
                                    {item.seller_name}
                                </p>
                                <p className="label-header">Leave feedback for Seller</p>
                            </div>
                            <div className="row">
                                {Object.values(mapData(feedbackInfo))}
                                <div className="column">
                                    <p className="label-description">Your Ratings*</p>
                                    <div className="flex-row">
                                        <div className="row">
                                            {feedbackFields.map((val, index) => (
                                                <div className="col-6">
                                                    <Box
                                                        component="fieldset"
                                                        mb={3}
                                                        borderColor="transparent"
                                                    >
                                                        <Typography component="legend">
                                                            {val.field_name}
                                                        </Typography>
                                                        <Rating
                                                            name={val.id}
                                                            value={val.rating}
                                                            onChange={(event, newValue) =>
                                                                handleRating(newValue, index)
                                                            }
                                                        />
                                                    </Box>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {feedbackerror ? (
                                        <p style={{ color: 'red' }}>{feedbackerror}</p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="col-12 button-container">
                                    <PrimaryButton
                                        label="Leave Feedback"
                                        type="submit"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddFeedback
