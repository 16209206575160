import React, { useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import UserContext from '../../Product/context/user/userContext'
import { handleRedirectInternal, mapData } from '../../Product/common/components'
import { useTranslation } from 'react-i18next'

function ForgotPasswordFunction() {
    const userContext = useContext(UserContext)
    const { forgotPassword, responseStatus } = userContext
    const { t } = useTranslation()
    const history = useHistory()
    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            values.base_url = `${window.location.origin}/${global.storeDetails.id}`
            forgotPassword(values)
        },
    })

    const forgotValues = [
        {
            label: t('email_address'),
            name: 'email',
            type: 'email',
            placeholder: t('enter_email'),
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'forgotPassword') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, '')
                    formik.resetForm()
                }
            }
        }
    }, [responseStatus])

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
            <h1>Reset Password</h1>
            <div className="row">{Object.values(mapData(forgotValues))}</div>
            <div className="d-flex justify-content-center forgotPasswordCta">
                <PrimaryButton label={t('password_button')} type="submit" />
            </div>
            <div className="d-flex forgotPasswordCta fpRm">
                <Link to="/login">{t('back_to_log')}</Link>
            </div>
        </form>
    )
}

export default ForgotPasswordFunction
