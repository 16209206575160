import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Loader from '../../../assets/loader'
import Loaders from '../../../Product/components/molecules/Loaders'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../../Product/common/components'
import { Pagination } from '@material-ui/lab'
const Auctions = (props) => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [auctionView, setAuctionView] = useState('Grid')
    const [subLotData, setSubLotData] = useState([])
    const [auction_type, setAuction_type] = useState('')
    const [totalViewProduct, setTotalViewProduct] = useState(0)
    const searchbar = new URLSearchParams(window.location.search.substring(1)).get('q')
    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '12',
        orderby: '2',
        searchbar: searchbar ? searchbar : '',
        auc_type: '',
        lot_images: 1,
        type: 'all',
        multi_seller_closed: 'closed',
        is_auction: 1,
    })

    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 50,
        market_status: 'open',
        auction_io: 1,
    })

    const [searchValues, setSearchValues] = useState({
        searchbar: searchbar ? searchbar : '',
    })

    useEffect(() => {
        setLoading(true)
        var data = auctionSearch

        if (props?.match?.params?.type) {
            var auction_type_loc = props.match.params.type
            setAuction_type(auction_type_loc)
            if (parseInt(auction_type_loc) == 0) {
                data.auc_type = '2'
            } else if (parseInt(auction_type_loc) == 1) {
                data.auc_type = '1'
            } else {
                data.auc_type = '3'
                data.type = 'closed'
            }
        }
        if (Object.keys(auctionSearch).length > 0) {
            getAllSearchAuctions(auctionSearch, 'activeHome')
        }
    }, [auctionSearch])
    useEffect(() => {
        if (viewAuction.length) {
            setSubLotSearch({ ...subLotSearch, lotof: viewAuction[0].id })
        }
    }, [viewAuction])

    useEffect(() => {
        if (subLotSearch.lotof) {
            getLotList(subLotSearch, 'homePageAuction')
        }
    }, [subLotSearch])

    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
            setTotalViewProduct(search_allauctions.total_pagecnt)
        }
    }, [search_allauctions])

    // const onHandlePage = (event, value) => {
    //     setAuctionSearch({ ...auctionSearch, page: value })
    // }

    useEffect(() => {
        if (search_allauctionproducts.from === 'homePageAuction') {
            setSubLotData(search_allauctionproducts.results)
        }
    }, [search_allauctionproducts])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            id: '0',
            description: 'All',
        },
        {
            id: 1,
            description: 'Upcoming',
        },
        {
            id: 2,
            description: 'Ongoing',
        },
        {
            id: 3,
            description: 'Closed',
        },
    ]

    const formik = useFormik({
        initialValues: {
            view_type: '0',
        },
    })

    useEffect(() => {
        if (formik.values.view_type != '0' || auctionSearch.type != 'all') {
            setAuctionSearch({
                ...auctionSearch,
                auc_type: formik.values.view_type == '0' ? '' : formik.values.view_type,
                type:
                    formik.values.view_type == '3'
                        ? 'closed'
                        : formik.values.view_type == '0'
                        ? 'all'
                        : '',
                multi_seller_closed:
                    formik.values.view_type == '3' || formik.values.view_type == '0'
                        ? 'closed'
                        : '',
                is_auction: 1,
            })
            if (formik.values.view_type == '3') {
                setAuction_type('3')
            }
        }
    }, [formik.values.view_type])

    const filterType = [
        {
            title: 'View',
            type: 'radio',
            noLabel: true,
            name: 'view_type',
            int: 1,
            class: 'aucTypeFilter mblFltrs',
            item: sortShow,
            formik: formik,
        },
    ]

    const handleChange = (e) => {
        setSearchValues({ ...searchValues, [e.target.name]: e.target.value })
    }

    const handleClick = (e) => {
        e.preventDefault()
        setAuctionSearch({ ...auctionSearch, searchbar: searchValues.searchbar })
    }

    return (
        <Layout props={props}>
            <div className="customContainer auctionsSearch">
                <div className="auctionSearchCnt">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mt-5">
                        <h2 className="auctionListTitle">
                            {auctionSearch.auc_type == 1
                                ? 'Upcoming'
                                : auctionSearch.auc_type == 2
                                ? 'Ongoing'
                                : 'All'}{' '}
                            Auctions
                        </h2>
                        {mapData(filterType)}
                    </div>

                    <div className="auctionsMiscFilter d-flex justify-content-between align-items-center flex-wrap mt-3 mb-3">
                        <form className="nav-standard-top-area-search">
                            <div className="nav-standard-top-area-search-inner">
                                <div>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        name="searchbar"
                                        value={searchValues.searchbar}
                                        className="nav-standard-top-area-search-input open-sans"
                                        placeholder="Search"
                                    />
                                </div>
                                <button
                                    onClick={handleClick}
                                    className="lin lin-magnifier nav-standard-top-area-search-sumbit"
                                >
                                    <span className="material-icons">search</span>
                                </button>
                            </div>
                        </form>
                        <div className="gridListToggle">
                            <Button
                                className={auctionView === 'Grid' ? 'active' : ''}
                                onClick={() => setAuctionView('Grid')}
                            >
                                <span className="material-icons">apps</span>
                            </Button>
                            <Button
                                className={auctionView === 'List' ? 'active' : ''}
                                onClick={() => setAuctionView('List')}
                            >
                                <span className="material-icons">view_list</span>
                            </Button>
                        </div>
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={auctionSearch.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                setAuctionSearch({
                                    ...auctionSearch,
                                    page: 1,
                                    sh_limit: event.target.value,
                                })
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                        {/* <CustomSelect
                            label="Auction Type"
                            value={auctionSearch.auc_type}
                            size="small"
                            selectType="noBorder"
                            name="auc_type"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, auc_type: event.target.value })
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect> */}
                    </div>
                </div>
                <>
                    <>
                        {loading ? (
                            <div className="text-center">
                                <Loaders name="product_grid_view" isLoading={loading} loop={4} />
                            </div>
                        ) : viewAuction.length === 0 ? (
                            <NoRecordsFound />
                        ) : viewAuction.length > 0 ? (
                            <div className={`searchResults ${auctionView}`}>
                                {viewAuction.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <GridAuction
                                            key={index}
                                            data={data}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}`}
                                            timerTheme="multiseller"
                                            theme="multiseller"
                                            addTheme="multiAuction"
                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                        />
                                    ) : (
                                        <MultisellerListCard
                                            key={index}
                                            data={data}
                                            allsearchitems={viewAuction}
                                            // lotCount={data.lotCount ? data.lotCount : 0}
                                            details={`${
                                                data.con_check
                                                    ? 'liveauctionview'
                                                    : 'search/product-buyer-auction'
                                            }/${data.id}`}
                                            addTheme="multiAuction"
                                        />
                                    ),
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                </>
                {totalViewProduct > auctionSearch.sh_limit && (
                    <Pagination
                        count={Math.ceil(totalViewProduct / auctionSearch.sh_limit)}
                        page={auctionSearch.page}
                        onChange={(e, v) => setAuctionSearch({ ...auctionSearch, page: v })}
                    />
                )}
            </div>
        </Layout>
    )
}

export default Auctions
