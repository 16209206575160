import React, { useState, useContext, useEffect } from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { PrevArrow, NextArrow } from './Arrows'
import ProductContext from '../../../Product/context/product/productContext'
import { dateFormatFront } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import moment from 'moment'
const settings = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
}

const Banner = () => {
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions } = productContext
    const [viewAuction, setViewAuction] = useState([])
    const [auction_type, setAuction_type] = useState('')

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '20',
        orderby: '2',
        searchbar: '',
        auction_only: 1,
        is_auction: 1,
        lot_images: 1,
        // type: '',
        // auc_type: 0,
    })

    useEffect(() => {
        var data = auctionSearch

        getAllSearchAuctions(auctionSearch, 'banner')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'banner') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    return (
        <>
            {/* {viewAuction?.length > 0 ? (
                <Slider {...settings} className="home-banner customContainer">
                    {viewAuction.slice(0, 4).map((data, index) => (
                        <div className="banner-wrapper clearfix" key={index}>
                            <div className="bannerInner">
                                <div className="banner-content-wrapper">
                                    <div>
                                        <h3 className="banner-tn">{data.title}</h3>
                                        <h1 className="banner-thead">{data.description}</h1>
                                        <p className="ua-date">
                                            <i className="material-icons">calendar_today</i>
                                            {`${moment(data.date_added)
                                                .tz(moment.tz.guess())
                                                .format('MMM Do')}, ${moment(data.date_added)
                                                .tz(moment.tz.guess())
                                                .format('h:mm a z')}`}{' '}
                                            to{' '}
                                            {`${moment(data.date_closed)
                                                .tz(moment.tz.guess())
                                                .format('MMM Do')}, ${moment(data.date_closed)
                                                .tz(moment.tz.guess())
                                                .format('h:mm a z')}`}
                                        </p>
                                        <Link
                                            className="btn btn-white-trans"
                                            to={`search/product-buyer-auction/${data.id}?${data.description}`}
                                        >
                                            View Auction
                                        </Link>
                                    </div>
                                </div>
                                <div className="bannerGrid">
                                    {data.lot_images.map((d, i) => (
                                        <LazyLoadImage
                                            src={
                                                process.env.REACT_APP_PRODUCT_IMAGE_URL +
                                                d.file_name
                                            }
                                            key={i}
                                            alt={data.title}
                                            className="banner-img"
                                            effect="blur"
                                            height="100%"
                                            width="100%"
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                ''
            )} */}
            {global.multipleBanners.length ? (
                <div className="siteBanner multiple">
                    {
                        <Slider {...settings}>
                            {global.multipleBanners.map((data, index) => (
                                <>
                                    <img
                                        src={process.env.REACT_APP_AUCTION_IMAGE_URL + '/' + data}
                                    />
                                    <div className="bannerContent">
                                        <h2>{global?.storeDetails?.description}</h2>
                                        <PrimaryButton
                                            label="View Auction"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `search/product-buyer-auction/${data.id}?${data.description}`,
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            ))}
                        </Slider>
                    }
                </div>
            ) : (
                //Fallback in case there is no banner and no active auctions.
                <div className="siteBanner">
                    <LazyLoadImage
                        src={global?.storeDetails?.bannerValue}
                        alt={`${global?.storeDetails?.name} banner`}
                        effect="blur"
                        height="100%"
                        width="100%"
                    />
                    <div className="bannerContent">
                        <h2>{global?.storeDetails?.description}</h2>
                        <PrimaryButton
                            label="View Auction"
                            onClick={() =>
                                handleRedirectInternal(
                                    history,
                                    `search/product-buyer-auction/${data.id}?${data.description}`,
                                )
                            }
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default Banner
