import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import { socket } from './socket.js'
import {
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker,
} from '@material-ui/pickers'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import CustomSelect from '../components/atoms/Inputs/CustomSelect'
import CustomMultiSelect from '../components/atoms/Inputs/CustomMultiSelect'
import RadioBox from '../components/atoms/RadioBox'

import MenuItem from '@material-ui/core/MenuItem'
import CustomInput from '../components/atoms/Inputs/CustomInput'
import CustomMaskInput from '../components/atoms/Inputs/CustomMaskInput'
import CustomPhone from '../components/atoms/Inputs/CustomPhone.js'
import PasswordStrength from './passwordStrength'
import './productCommon.css'

import CheckBox from '../components/atoms/CheckBox'
import CustomTextArea from '../components/atoms/Inputs/CustomTextArea'
import { CKEditor } from 'ckeditor4-react'
import Uploader from '../../Product/common/uploader'
import StaticUploader from '../../Product/common/staticuploader'

import FormHelperText from '@material-ui/core/FormHelperText'
import CustomAutocomplete from '../components/atoms/Inputs/CustomAutocomplete.js'
import { Checkbox } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import { CKEditor as CKEditor1 } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import GoogleMaps from '../../Component/MapInput/index.js'

let serverTime = new Date()
const monthFormat = 'YYYY-MM'
const dateFormat = 'YYYY-MM-DD'
const dateTimeFormat = 'YYYY-MM-DD h:mm a'
const timeFormat = 'h:mm a'

if (socket) {
    socket.on('sliservertime', (data) => {
        if (moment(data.dTime).isValid()) {
            serverTime = new Date(data.dTime)
        }
    })
}

const errorCheck = (data, formik) => {
    return data.filter &&
        formik &&
        formik.touched &&
        formik.touched.filters &&
        formik.touched.filters[data.name] &&
        formik.errors &&
        formik.errors.filters &&
        formik.errors.filters[data.name]
        ? formik.errors.filters[data.name]
        : formik.touched[data.name] && formik.errors[data.name]
}

const multiSelectValue = (data, formik) => {
    return data.filter
        ? formik.values.filters[data.name].value.length <= data.options.length
            ? data.options
                  .filter((d) =>
                      formik.values.filters[data.name].value.length
                          ? !formik.values.filters[data.name].value.includes(d.value)
                          : true,
                  )
                  .map((d) => d.value)
                  .flat()
            : (formik.values.filters[data.name].value.length = 0 && null)
        : formik.values[data.name].length <= data.options.length
        ? data.options
              .filter((d) =>
                  formik.values[data.name].length
                      ? !formik.values[data.name].includes(d.value)
                      : true,
              )
              .map((d) => d.value)
              .flat()
        : (formik.values[data.name].length = 0 && null)
}

const helperText = (data, formik) => {
    return data.filter &&
        formik &&
        formik.touched &&
        formik.touched.filters &&
        formik.touched.filters[data.name] &&
        formik.errors &&
        formik.errors.filters &&
        formik.errors.filters[data.name]
        ? formik.errors.filters[data.name]
        : formik.touched[data.name] && formik.errors[data.name]
}

export const calculateAge = (birthday) => {
    let compareDate = moment(moment(birthday, dateFormat).format(dateFormat), dateFormat).toDate()
    let ageDifMs = Date.now() - compareDate.getTime()
    let ageDate = new Date(ageDifMs) // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const calculatePercentage = (amount, percent) => {
    let percentAmount = 0
    let totalAmount = 0

    percentAmount = parseFloat(parseFloat(amount) * parseFloat(percent / 100)).toFixed(2)
    totalAmount = parseFloat(parseFloat(amount) + parseFloat(percentAmount)).toFixed(2)

    return { percentAmount, totalAmount }
}

const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}` : key)
        })
    } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
    }
}
export const jsonToFormData = (data) => {
    const formData = new FormData()
    buildFormData(formData, data)
    return formData
}

export const converDateTime = (data, utc) => {
    let dataReturn = null
    if (data) {
        if (moment(data).isValid()) {
            if (utc) {
                dataReturn = `${moment(data).utc()}`
            } else {
                dataReturn = `${moment(data)}`
            }
        }
    }
    return dataReturn
}

export const converDate = (data, format) => {
    let dataReturn = ''
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(format || dateFormat)}`
        }
    }
    return dataReturn
}

const UPLOAD_ENDPOINT = 'product/img-upload-auction-io'

function uploadAdapter(loader) {
    return {
        upload: () => {
            return new Promise((resolve, reject) => {
                const body = new FormData()
                loader.file.then((file) => {
                    body.append('product_images', file)
                    fetch(`${process.env.REACT_APP_BASE_URL}${UPLOAD_ENDPOINT}`, {
                        method: 'post',
                        body: body,
                    })
                        .then((res) => res.json())
                        .then((res) => resolve({ default: `${res.data}` }))
                        .catch((err) => {
                            reject(err)
                        })
                })
            })
        },
    }
}

function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return uploadAdapter(loader)
    }
}

export const mapData = (page) => {
    let formik = page.formik ? page.formik : page[0].formik
    let pageData = page.data ? page.data : page

    let data = pageData.map((data, index) => (
        <>
            <div key={index} className={data.class}>
                {data.type === 'select' ? (
                    <>
                        <CustomSelect
                            label={data.label}
                            id={data.id}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            size={data.size}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            placeholder={data.placeholder}
                            disabled={data.disabled}
                            onBlur={formik.handleBlur}
                            type={data.type}
                            error={errorCheck(data, formik)}
                            helperText={helperText(data, formik)}
                            upperLabel={data.upperLabel}
                        >
                            {!data.isNoDefaultOptionValue && (
                                <option value="">{data.placeholder}</option>
                            )}
                            {data.options &&
                                data.options.map((opt, optindex) => (
                                    <option
                                        key={optindex}
                                        value={
                                            opt.value?.country_code
                                                ? [opt.value?.country_code, opt.value.location_id]
                                                : opt.value
                                        }
                                    >
                                        {opt.show}
                                    </option>
                                ))}

                            {data.groupoptions &&
                                data.groupoptions.map((opt, optindex) => (
                                    <>
                                        <optgroup label={opt.head}>
                                            <>
                                                {opt.options.map((opt, optindex) => (
                                                    <option key={optindex} value={opt.value}>
                                                        {opt.show}
                                                    </option>
                                                ))}
                                            </>
                                        </optgroup>
                                    </>
                                ))}
                        </CustomSelect>
                    </>
                ) : data.type === 'autocomplete' ? (
                    <>
                        <CustomAutocomplete
                            label={data.label}
                            id={data.id}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            size={data.size}
                            onChange={(val) => {
                                data.filter
                                    ? formik.setFieldValue(`filters.${data.name}.value`, val)
                                    : formik.setFieldValue(data.name, val)
                            }}
                            placeholder={data.placeholder}
                            disabled={data.disabled}
                            onBlur={formik.handleBlur}
                            options={data.options}
                            type={data.type}
                            error={errorCheck(data, formik)}
                            helperText={helperText(data, formik)}
                        />
                    </>
                ) : data.type === 'misc' ? (
                    <>{data.content}</>
                ) : data.type === 'switch' ? (
                    <>
                        <FormGroup row className="partialPaymentToggle">
                            <FormControlLabel
                                label={data.label}
                                control={
                                    <Switch
                                        checked={formik.values[data.name]}
                                        onChange={formik.handleChange}
                                        name={data.name}
                                        color={data.color}
                                    />
                                }
                            />
                        </FormGroup>
                    </>
                ) : data.type === 'check' ? (
                    <>
                        {data.options &&
                            data.options.map((opt, optindex) => (
                                <CheckBox
                                    key={optindex}
                                    name={data.name}
                                    disabled={data.disabled}
                                    label={opt.description}
                                    dataCount={data.dataCount}
                                    checked={
                                        formik.values[data.name].indexOf(opt.id?.toString()) !== -1
                                            ? true
                                            : false
                                    }
                                    value={opt.id?.toString()}
                                    onChange={formik.handleChange}
                                />
                            ))}
                        <div className="checkboxError">
                            <FormHelperText>
                                {formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]}
                            </FormHelperText>
                        </div>
                    </>
                ) : data.type === 'uploadDropZone' ? (
                    <>
                        <Uploader
                            formik={formik}
                            name={data.name}
                            icon={data.icon}
                            titleText={data.titleText}
                            innerText={data.innerText}
                            folder={data.folder}
                            multiple={data.multiple}
                            reorder={data.reorder}
                            compressImages={data.compressImages}
                            accept={data.accept}
                            fileName={data.fileName}
                            deleteArray={data.deleteArray}
                        ></Uploader>
                    </>
                ) : data.type === 'previousImages' ? (
                    <>
                        <StaticUploader
                            formik={formik}
                            name={data.name}
                            folder={data.folder}
                            reorder={data.reorder}
                            deleteArray={data.deleteArray}
                            fileName={data.fileName}
                        ></StaticUploader>
                    </>
                ) : data.type === 'phone' ? (
                    <>
                        <CustomPhone
                            id={data.id}
                            value={formik.values[data.name]}
                            autoFocus={data.autoFocus}
                            name={data.name}
                            disabled={data.disabled}
                            shrink={formik.values[data.name] && true}
                            onBlur={formik.handleBlur}
                            onChange={(phone) => formik.setFieldValue(data.name, phone)}
                            label={data.label}
                            placeholder={data.placeholder}
                            type={data.type}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                            countryCodeEditable={data.countryCodeEditable}
                            upperLabel={data.upperLabel}
                            disableCountryCode={data.disableCountryCode}
                            enableLongNumbers={data.enableLongNumbers}
                        />
                    </>
                ) : data.type === 'multiselect' ? (
                    <>
                        <CustomMultiSelect
                            label={data.label}
                            id={data.id}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            onChange={(event, value) => {
                                let arrayValue = event.target.value.flat()
                                let allLength = data.filter
                                    ? formik.values.filters[data.name].value.length ===
                                      data.options.length
                                    : formik.values[data.name].length === data.options.length
                                if (allLength && arrayValue.length === data.options.length) {
                                    arrayValue = []
                                }
                                data.filter
                                    ? formik.setFieldValue(`filters.${data.name}.value`, arrayValue)
                                    : formik.setFieldValue(data.name, arrayValue)
                            }}
                            placeholder={data.placeholder}
                            onBlur={formik.handleBlur}
                            disabled={data.disabled}
                            type={data.type}
                            error={errorCheck(data, formik)}
                            helperText={helperText(data, formik)}
                            options={data.options}
                        >
                            <MenuItem value={multiSelectValue(data, formik)}>
                                <Checkbox
                                    checked={
                                        data.filter
                                            ? formik.values.filters[data.name].value?.flat()
                                                  .length == data.options.length
                                                ? true
                                                : false
                                            : formik.values[data.name]?.flat().length ==
                                              data.options.length
                                            ? true
                                            : false
                                    }
                                    onChange={(val) => {
                                        val.target.checked
                                            ? data.filter
                                                ? formik.setFieldValue(
                                                      `filters.${data.name}.value`,
                                                      formik.values.filters[data.name].value.concat(
                                                          multiSelectValue(data, formik),
                                                      ),
                                                  )
                                                : formik.setFieldValue(
                                                      data.name,
                                                      formik.values[data.name].concat(
                                                          multiSelectValue(data, formik),
                                                      ),
                                                  )
                                            : data.filter
                                            ? formik.setFieldValue(`filters.${data.name}.value`, [])
                                            : formik.setFieldValue(data.name, [])
                                    }}
                                />
                                <ListItemText primary={'All'} />
                            </MenuItem>
                            {data.options.map((opt, optindex) => (
                                <MenuItem key={optindex} value={opt.value}>
                                    <Checkbox
                                        checked={
                                            data.filter
                                                ? formik?.values?.filters[data.name].value
                                                      ?.flat()
                                                      .indexOf(opt.value) > -1
                                                : formik?.values[data.name]
                                                      ?.flat()
                                                      .indexOf(opt.value) > -1
                                        }
                                        onChange={
                                            data.onChange ? data.onChange : formik.handleChange
                                        }
                                    />
                                    {opt.show}
                                </MenuItem>
                            ))}
                        </CustomMultiSelect>
                    </>
                ) : data.type === 'ckeditor1' ? (
                    <>
                        <label>{data.label}</label>
                        <CKEditor1
                            config={{
                                mediaEmbed: { previewsInData: true },
                                extraPlugins: [uploadPlugin],
                            }}
                            editor={ClassicEditor}
                            data={formik.values[data.name] ? formik.values[data.name] : ''}
                            onChange={(event, editor) => {
                                formik.setFieldValue(data.name, editor.getData())
                            }}
                        />
                        <div className="checkboxError">
                            <FormHelperText>
                                {formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]}
                            </FormHelperText>
                        </div>
                    </>
                ) : data.type === 'ckeditor' ? (
                    <>
                        <label>{data.label}</label>
                        <CKEditor
                            config={{
                                allowedContent: true,
                            }}
                            type="classic"
                            initData={formik.values[data.name]}
                            onChange={(event, editor) => {
                                formik.setFieldValue(data.name, event.editor.getData())
                            }}
                            disableEnforceFocus
                            readOnly={data.readOnly}
                            onInstanceReady={({ editor }) => {
                                editor.setData(formik.values[data.name])
                            }}
                        />
                        <div className="checkboxError">
                            <FormHelperText>
                                {formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]}
                            </FormHelperText>
                        </div>
                    </>
                ) : data.type === 'password_checker' ? (
                    <>
                        <PasswordStrength
                            data={data}
                            field={data.field ? data.field : 'password'}
                        />
                    </>
                ) : data.type === 'date' ? (
                    data.upperLabel ? (
                        <>
                            <label className="datePickerLabel">{data.label}</label>
                            <KeyboardDatePicker
                                margin="0"
                                autoOk={true}
                                showTodayButton={true}
                                id={data.name}
                                name={data.name}
                                inputVariant="outlined"
                                format={dateFormat}
                                placeholder={data.placeholder || ''}
                                disabled={data.disabled}
                                disableFuture={data.disableFuture || false}
                                disablePast={data.disablePast || false}
                                maxDate={data.maxDate || moment('01/01/2100')}
                                maxDateMessage={
                                    data.maxDateMessage || 'Date should not be after maximal date'
                                }
                                minDate={data.minDate || moment('01/01/1900')}
                                minDateMessage={
                                    data.minDateMessage || 'Date should not be before minimal date'
                                }
                                className="customDatepicker"
                                value={
                                    data.filter
                                        ? formik.values.filters[data.name].value || null
                                        : formik.values[data.name] || null
                                }
                                inputValue={
                                    data.filter
                                        ? formik.values.filters[data.name].value || null
                                        : formik.values[data.name] || null
                                }
                                onChange={(val) => {
                                    data.filter
                                        ? formik.setFieldValue(
                                              `filters.${data.name}.value`,
                                              converDate(val),
                                          )
                                        : formik.setFieldValue(data.name, converDate(val))
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                error={formik.touched[data.name] && formik.errors[data.name]}
                                helperText={
                                    helperText(data, formik)
                                        ? helperText(data, formik)
                                        : data.helperText
                                }
                                size={data.size}
                            />
                        </>
                    ) : (
                        <KeyboardDatePicker
                            margin="0"
                            autoOk={true}
                            showTodayButton={true}
                            id={data.name}
                            inputVariant="outlined"
                            label={data.label}
                            format={data.dateFormat || dateFormat}
                            placeholder={data.placeholder || ''}
                            disabled={data.disabled}
                            disableFuture={data.disableFuture || false}
                            disablePast={data.disablePast || false}
                            maxDate={
                                data.maxDate ||
                                moment('01-01-2100').format(data.dateFormat || dateFormat)
                            }
                            maxDateMessage={
                                data.maxDateMessage || 'Date should not be after maximal date'
                            }
                            minDate={
                                data.minDate ||
                                moment('01-01-1900').format(data.dateFormat || dateFormat)
                            }
                            minDateMessage={
                                data.minDateMessage || 'Date should not be before minimal date'
                            }
                            className="customDatepicker"
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value || null
                                    : formik.values[data.name] || null
                            }
                            inputValue={
                                data.filter
                                    ? formik.values.filters[data.name].value || null
                                    : formik.values[data.name] || null
                            }
                            onChange={
                                data.onChange
                                    ? data.onChange
                                    : (val) => {
                                          data.filter
                                              ? formik.setFieldValue(
                                                    `filters.${data.name}.value`,
                                                    converDate(val, data.dateFormat),
                                                )
                                              : formik.setFieldValue(
                                                    data.name,
                                                    converDate(val, data.dateFormat),
                                                )
                                      }
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                            size={data.size}
                        />
                    )
                ) : data.type === 'dateWithCustomPicker' ? (
                    <>
                        <KeyboardDatePicker
                            margin="0"
                            autoOk={true}
                            showTodayButton={true}
                            id={data.name}
                            name={data.name}
                            open={data.open}
                            onOpen={data.onOpen ? data.onOpen : null}
                            onClose={data.onClose ? data.onClose : null}
                            inputVariant="outlined"
                            label={data.label}
                            format={dateFormat}
                            disabled={data.disabled}
                            disableFuture={data.disableFuture || false}
                            disablePast={data.disablePast || false}
                            maxDate={data.maxDate || moment('01-01-2100').format(dateFormat)}
                            maxDateMessage={
                                data.maxDateMessage || 'Date should not be after maximal date'
                            }
                            minDate={data.minDate || moment('01-01-1900').format(dateFormat)}
                            minDateMessage={
                                data.minDateMessage || 'Date should not be before minimal date'
                            }
                            className="customDatepicker"
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value || null
                                    : formik.values[data.name] || null
                            }
                            inputValue={
                                data.filter
                                    ? formik.values.filters[data.name].value || null
                                    : formik.values[data.name] || null
                            }
                            onChange={(val) => {
                                data.filter
                                    ? formik.setFieldValue(
                                          `filters.${data.name}.value`,
                                          converDate(val),
                                      )
                                    : formik.setFieldValue(data.name, converDate(val))
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                            size={data.size}
                        />
                    </>
                ) : data.type === 'month' ? (
                    <>
                        <KeyboardDatePicker
                            margin="0"
                            autoOk={true}
                            openTo="year"
                            views={['year', 'month']}
                            showTodayButton={true}
                            id={data.name}
                            name={data.name}
                            inputVariant="outlined"
                            label={data.label}
                            disablePast={data.disablePast || false}
                            maxDate={data.maxDate || moment('01-01-2100').format(dateFormat)}
                            maxDateMessage={
                                data.maxDateMessage || 'Date should not be after maximal date'
                            }
                            minDate={data.minDate || moment('01-01-1900').format(dateFormat)}
                            minDateMessage={
                                data.minDateMessage || 'Date should not be before minimal date'
                            }
                            format={monthFormat}
                            disabled={data.disabled}
                            className="customDatepicker"
                            value={formik.values[data.name]}
                            // inputValue={formik.values[data.name]}
                            onChange={(val) => {
                                formik.setFieldValue(data.name, moment(val).format(monthFormat))
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                        />
                    </>
                ) : data.type === 'datetime' ? (
                    <>
                        {data.upperLabel ? (
                            <>
                                <label>{data.label}</label>
                                <KeyboardDateTimePicker
                                    margin="0"
                                    autoOk={true}
                                    showTodayButton={true}
                                    id={data.name}
                                    name={data.name}
                                    inputVariant="outlined"
                                    format={dateTimeFormat}
                                    disabled={data.disabled}
                                    disablePast={data.disablePast || false}
                                    maxDate={
                                        data.maxDate || moment('01-01-2100').format(dateFormat)
                                    }
                                    maxDateMessage={
                                        data.maxDateMessage ||
                                        'Date should not be after maximal date'
                                    }
                                    minDate={
                                        data.minDate || moment('01-01-1900').format(dateFormat)
                                    }
                                    minDateMessage={
                                        data.minDateMessage ||
                                        'Date should not be before minimal date'
                                    }
                                    className="customDatepicker"
                                    value={formik.values[data.name]}
                                    // inputValue={formik.values[data.name]}
                                    onChange={(val) => {
                                        // console.log(val, '+++++++')
                                        formik.setFieldValue(
                                            data.name,
                                            converDateTime(val, data.utc),
                                        )
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    error={formik.touched[data.name] && formik.errors[data.name]}
                                    helperText={
                                        formik.errors[data.name] &&
                                        formik.touched[data.name] &&
                                        formik.errors[data.name]
                                    }
                                />
                            </>
                        ) : data.newdatepicker ? (
                            <>
                                <label>{data.label}</label>
                                <KeyboardDateTimePicker
                                    margin="0"
                                    autoOk={true}
                                    showTodayButton={true}
                                    id={data.name}
                                    name={data.name}
                                    inputVariant="outlined"
                                    format={dateTimeFormat}
                                    disabled={data.disabled}
                                    disablePast={data.disablePast || false}
                                    // maxDate={
                                    //     data.maxDate || moment('01-01-2100').format(dateFormat)
                                    // }
                                    maxDateMessage={
                                        data.maxDateMessage ||
                                        'Date should not be after maximal date'
                                    }
                                    // minDate={
                                    //     data.minDate || moment('01-01-1900').format(dateFormat)
                                    // }
                                    minDateMessage={
                                        data.minDateMessage ||
                                        'Date should not be before minimal date'
                                    }
                                    className="customDatepicker"
                                    value={formik.values[data.name]}
                                    // inputValue={formik.values[data.name]}
                                    onChange={(val) => {
                                        console.log(val, '+++++++')
                                        formik.setFieldValue(
                                            data.name,
                                            converDateTime(val, data.utc),
                                        )
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    error={formik.touched[data.name] && formik.errors[data.name]}
                                    helperText={
                                        formik.errors[data.name] &&
                                        formik.touched[data.name] &&
                                        formik.errors[data.name]
                                    }
                                />
                            </>
                        ) : (
                            <KeyboardDateTimePicker
                                margin="0"
                                autoOk={true}
                                showTodayButton={true}
                                id={data.name}
                                name={data.name}
                                inputVariant="outlined"
                                label={data.label}
                                format={dateTimeFormat}
                                disabled={data.disabled}
                                disablePast={data.disablePast || false}
                                maxDate={data.maxDate || moment('01-01-2100').format(dateFormat)}
                                maxDateMessage={
                                    data.maxDateMessage || 'Date should not be after maximal date'
                                }
                                minDate={data.minDate || moment('01-01-1900').format(dateFormat)}
                                minDateMessage={
                                    data.minDateMessage || 'Date should not be before minimal date'
                                }
                                className="customDatepicker"
                                value={formik.values[data.name]}
                                // inputValue={formik.values[data.name]}
                                onChange={(val) => {
                                    // console.log(val, '+++++++')
                                    formik.setFieldValue(data.name, converDateTime(val, data.utc))
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                error={formik.touched[data.name] && formik.errors[data.name]}
                                helperText={
                                    formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]
                                }
                            />
                        )}
                    </>
                ) : data.type === 'time' ? (
                    <>
                        <KeyboardTimePicker
                            margin="0"
                            autoOk={true}
                            id={data.name}
                            name={data.name}
                            inputVariant="outlined"
                            label={data.label}
                            disabled={data.disabled}
                            disablePast={data.disablePast || false}
                            className="customDatepicker"
                            value={formik.values[data.name]}
                            inputValue={formik.values[data.name]}
                            onChange={
                                data.onChange
                                    ? data.onChange
                                    : (val) => {
                                          formik.setFieldValue(
                                              data.name,
                                              moment(val).format(timeFormat),
                                          )
                                      }
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                            ampm={data.ampm ? true : false}
                        />
                    </>
                ) : data.type === 'textarea' ? (
                    <>
                        <CustomTextArea
                            id={data.id}
                            rows={data.rows}
                            value={formik.values[data.name]}
                            autoFocus={data.autoFocus}
                            name={data.name}
                            disabled={data.disabled}
                            shrink={formik.values[data.name] && true}
                            onBlur={formik.handleBlur}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            label={data.label}
                            placeholder={data.placeholder}
                            type={data.type}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                        />
                    </>
                ) : data.type === 'checkbox' ? (
                    <>
                        <CheckBox
                            checked={formik.values[data.name]}
                            label={data.label}
                            value={true}
                            disabled={data.disabled}
                            onChange={() => {
                                formik.setFieldValue(data.name, !formik.values[data.name])
                            }}
                            name={data.name}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            dataCount={data.dataCount ? data.dataCount : null}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                            tooltipTitle={data.tooltipTitle}
                        />
                    </>
                ) : data.type === 'checkboxarray' ? (
                    data.item.map((d, i) => (
                        <>
                            <CheckBox
                                name={data.name}
                                label={d.description}
                                checked={
                                    formik.values[data.name].indexOf(d.id.toString()) !== -1
                                        ? true
                                        : false
                                }
                                disabled={d.disabled || false}
                                value={d.id.toString()}
                                onChange={formik.handleChange}
                            />
                        </>
                    ))
                ) : data.type === 'radio' ? (
                    <>
                        <RadioBox
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                            title={data.noLabel ? '' : data.title}
                            name={data.name}
                            items={data.item}
                            value={formik.values[data.name]}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            customLabel={data.customLabel}
                            int={data.int}
                        />
                    </>
                ) : data.type === 'mask' ? (
                    <>
                        <CustomMaskInput
                            id={data.id}
                            maskValue={data.maskValue}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            disabled={data.disabled}
                            shrink={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                        ? true
                                        : false
                                    : formik.values[data.name]
                                    ? true
                                    : false
                            }
                            onBlur={formik.handleBlur}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            label={data.label}
                            placeholder={data.placeholder}
                            type={data.type}
                            variant={data.variant}
                            size={data.size}
                            startAdornment={data.startAdornment}
                            endAdornment={data.endAdornment}
                            error={errorCheck(data, formik)}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                            inputStyle={data.inputStyle}
                            upperLabel={data.upperLabel}
                            tooltiptitle={data.tooltiptitle}
                        />
                    </>
                ) : data.type === 'mapInput' ? (
                    <>
                        <GoogleMaps
                            id={data.id}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            disabled={data.disabled}
                            shrink={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                        ? true
                                        : false
                                    : formik.values[data.name]
                                    ? true
                                    : false
                            }
                            onBlur={formik.handleBlur}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            label={data.label}
                            placeholder={data.placeholder}
                            type={data.type}
                            variant={data.variant}
                            size={data.size}
                            pattern={data.pattern}
                            startAdornment={data.startAdornment}
                            endAdornment={data.endAdornment}
                            error={errorCheck(data, formik)}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                            inputStyle={data.inputStyle}
                            upperLabel={data.upperLabel}
                            tooltiptitle={data.tooltiptitle}
                            step={data.step}
                            min={data.min}
                            autoComplete={data.autoComplete}
                            formik={formik}
                        />
                    </>
                ) : (
                    <>
                        <CustomInput
                            id={data.id}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            disabled={data.disabled}
                            shrink={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                        ? true
                                        : false
                                    : formik.values[data.name]
                                    ? true
                                    : false
                            }
                            onBlur={formik.handleBlur}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            disablePaste={data.disablePaste}
                            label={data.label}
                            placeholder={data.placeholder}
                            type={data.type}
                            variant={data.variant}
                            size={data.size}
                            pattern={data.pattern}
                            startAdornment={data.startAdornment}
                            endAdornment={data.endAdornment}
                            error={errorCheck(data, formik)}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                            inputStyle={data.inputStyle}
                            upperLabel={data.upperLabel}
                            tooltiptitle={data.tooltiptitle}
                            step={data.step}
                            min={data.min}
                            autoComplete={data.autoComplete}
                        />
                        {data.additionalText ? data.additionalText : null}
                    </>
                )}
            </div>
        </>
    ))
    return data
}

export const handleRedirectInternal = (history, path) => {
    history.push(`/${path}`)
    window.scrollTo(0, 0)
}

export const dateFormatFront = (data) => {
    return moment(data).isValid()
        ? `${moment(data).format('MMM Do')}, ${moment(data).format('h:mm a')}`
        : '-'
}

export const dateFormatFrontDay = (data) => {
    return `${moment(data).format('MMM Do YYYY')}`
}

export const getClientTimezoneDate = (date, format) => {
    return moment(moment(date).isValid() ? date : new Date())
        .tz(moment.tz.guess())
        .format(format ? format : 'MMM Do YYYY, h:mm A z')
}

export const checkProductOpen = (date_added, user_role) => {
    let startDate = new Date(serverTime)
    let addDate = new Date(date_added)
    let milliSeconds = 0
    // if (parseInt(user_role) !== 3) {
    //   milliSeconds = 1 * 3600000;
    // } else {
    // }
    let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
    let newaddDate = new Date(incrementedTime)
    if (newaddDate > startDate) {
        return false
        // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
        // let timerTime = incrementedTime - startDate.getTime();
        // if (timerTime <= 0) {
        //   return true;
        // } else {
        //   return false;
        // }
    } else {
        return true
    }
}

export const maskEmailFront = (data) => {
    data = data.toString()
    var first4 = data.substring(0, 4)
    var last5 = data.substring(data.length - 5)
    var mask = data.substring(4, data.length - 5).replace(/[^]/g, '*')
    return first4 + mask + last5
}

export const capitalize = (data) => {
    let dataReturn = '-'
    if (data) {
        data = data.replace(/_/g, ' ').toLowerCase()
        data.split(' ')
        if (data instanceof Array) {
            dataReturn = data
                .map((word) => {
                    return word[0].toUpperCase() + word.substring(1)
                })
                .join(' ')
        } else {
            dataReturn = data.charAt(0).toUpperCase() + data.slice(1)
        }
    }
    return dataReturn
}

export const currencyFormat = (data, type, from = 'all', decimal = 2) => {
    let dataReturn = '0'
    var cu_type = global.storeConfigration?.currency_format?.value
        ? global.storeConfigration?.currency_format?.value
        : 'usd'
    if (type == 'cad' || cu_type != 'usd') {
        if (from == 'invoice') {
            dataReturn = `${global.storeConfigration?.currency_format?.value} ${parseFloat(
                data || 0,
            ).toUSFormat()}`
        } else {
            dataReturn = `${global.storeConfigration?.currency_format?.value} ${parseFloat(
                data || 0,
            ).toUSFormat(decimal)}`
        }
    } else {
        if (from == 'invoice') {
            dataReturn = `US$ ${parseFloat(data || 0).toUSFormat()}`
        } else {
            dataReturn = `US$ ${parseFloat(data || 0).toUSFormat(decimal)}`
        }
    }

    return dataReturn
}

export const dateFormatFunction = (data) => {
    let dataReturn = '-'
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(dateFormat)}`
        }
    }
    return dataReturn
}

export const dateTimeFormatFunction = (data, utc = false) => {
    let dataReturn = ' '
    if (data) {
        if (moment(data).isValid()) {
            if (utc) {
                dataReturn = `${moment(data).utc()}`
            } else {
                dataReturn = `${moment(data)}`
            }
        }
    }
    return dataReturn
}
export const dateTimeStripe = (data, utc = false) => {
    return moment(data * 1000).isValid()
        ? `${moment(data * 1000).format('MMM Do')}, ${moment(data * 1000).format('h:mm a')}`
        : '-'
}
export const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const DirectAPICAll = (method, url, data, headertype, reqConfig) => {
    return new Promise(async (resolve, reject) => {
        try {
            let type = ''
            if (headertype && headertype === 'formdata') {
                type = 'multipart/form-data'
            } else {
                type = 'application/json'
            }
            const config = {
                headers: {
                    'content-type': type,
                },
            }
            var token = axios.defaults.headers.common['authorization']
            if (reqConfig) {
                delete axios.defaults.headers.common['authorization']
            }
            if (method === 'get') {
                let res = await axios.get(`${url}`)
                // console.log('responsode from api', res)
                resolve(res)
            } else if (method === 'post') {
                if (data) {
                    let res = await axios.post(`${url}`, data, reqConfig ? reqConfig : config)
                    if (reqConfig) {
                        delete axios.defaults.headers.common['authorization']
                        axios.defaults.headers.common['authorization'] = token
                    }
                    resolve(res)
                } else {
                    let res = await axios.post(`${url}`, reqConfig ? reqConfig : config)
                    if (reqConfig) {
                        delete axios.defaults.headers.common['authorization']
                        axios.defaults.headers.common['authorization'] = token
                    }
                    resolve(res)
                }
            }
        } catch (err) {
            // console.log('responsode error from api', err)
            resolve(err)
        }
    })
}

export const useCustomMediaQuery = (queryString) => {
    const [isMatch, setIsMatch] = useState(false)
    const mqChange = (mq) => {
        setIsMatch(mq.matches)
    }

    useEffect(() => {
        const mq = window.matchMedia(queryString)
        mq.addListener(mqChange)
        mqChange(mq)

        return () => {
            mq.removeListener(mqChange)
        }
    }, [queryString])

    return isMatch
}

export const sellerNameFormat = (name) => {
    if (name.includes(' ')) {
        if (global.storeDetails.theme == 7) {
            var first_name = name.split(' ')[0]
            var last_name = name.split(' ')[1][0]
            return first_name + ' ' + last_name
        } else {
            return name
        }
    } else {
        return name
    }
}

export const removeHTMLTags = (data) => {
    let tempEle = document.createElement('span')
    tempEle.innerHTML = data
    let convertedText = tempEle.textContent || tempEle.innerText || ''
    return convertedText
}
