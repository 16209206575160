import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import './dashboard.css'
import { ListItem } from '@material-ui/core'
import AuthContext from '../../../Product/context/auth/authContext'
import { commonPaths } from '../../../utils/commonFunctions'

const DashboardPanel = ({ title, subtitle, savedcards, seller, invoices }) => {
    const { user } = useContext(AuthContext)
    return (
        <div className="dashboardLt">
            <ul>
                {user && user.role == 1 && (
                    <ListItem button>
                        <NavLink to="/dashboard/overview" activeClassName="active">
                            <span className="material-icons">dashboard</span>
                            Dashboard
                        </NavLink>
                    </ListItem>
                )}
                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard/profile">
                        <span className="material-icons">person</span>
                        My Account
                    </NavLink>
                </ListItem>

                <hr className="sdBrDvdr" />
                <h4 className="sdDvdrHdr">Buyer</h4>
                <ListItem button>
                    <NavLink activeClassName="active" to={commonPaths.mybids}>
                        <span className="material-icons">gavel</span> My Bids
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink to="/dashboard/favorites" activeClassName="active">
                        <span className="material-icons">favorite</span>
                        My Favorites
                    </NavLink>
                </ListItem>
                {invoices && (
                    <ListItem button>
                        <NavLink to="/dashboard/transactions" activeClassName="active">
                            <span className="material-icons">receipt</span>
                            Invoices
                        </NavLink>
                    </ListItem>
                )}
                <ListItem button>
                    <NavLink to="/dashboard/savedSearch" activeClassName="active">
                        <span className="material-icons">bookmark</span>
                        Saved Search
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink to="/dashboard/cards" activeClassName="active">
                        <span className="material-icons">credit_card</span>
                        Saved Cards
                    </NavLink>
                </ListItem>
                <ListItem button>
                    <NavLink to="/dashboard/preference" activeClassName="active">
                        <span className="material-icons">notifications</span>
                        Preference
                    </NavLink>
                </ListItem>
                {user && user.role == 1 && (
                    <>
                        <hr className="sdBrDvdr" />
                        <h4 className="sdDvdrHdr">Seller</h4>
                        <ListItem button>
                            <NavLink activeClassName="active" to="/sellerdashboard">
                                <span className="material-icons">store</span> My Auctions
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink activeClassName="active" to={commonPaths.mylots}>
                                <span className="material-icons">store</span> My Lots
                            </NavLink>
                        </ListItem>
                        {/* <ListItem button>
                            <NavLink activeClassName="active" to="/dashboard/buynow">
                                <span className="material-icons">shopping_basket</span> My Buynow
                            </NavLink>
                        </ListItem> */}
                    </>
                )}
                {user && user.role == 1 && (
                    <ListItem button>
                        <NavLink to="/dashboard/invoices" activeClassName="active">
                            <span className="material-icons">receipt</span>
                            Invoices
                        </NavLink>
                    </ListItem>
                )}
                {user && user.role == 0 && (
                    <ListItem button>
                        <NavLink to="/dashboard/transactions" activeClassName="active">
                            <span className="material-icons">receipt</span>
                            Transactions
                        </NavLink>
                    </ListItem>
                )}
                {user && user.role == 1 && (
                    <ListItem button>
                        <NavLink to="/dashboard/reports" activeClassName="active">
                            <span className="material-icons">description</span>
                            Reports
                        </NavLink>
                    </ListItem>
                )}
                {/* {user && user.role == 1 && (
                    <ListItem button>
                        <NavLink to="/dashboard/photouploader" activeClassName="active">
                            <span className="material-icons">collections</span>
                            Photo Uploader
                        </NavLink>
                    </ListItem>
                )} */}
                {global.storeConfigration?.remove_message_module?.value != 1 ? (
                    <ListItem button>
                        <NavLink to="/dashboard/message" activeClassName="active">
                            <span className="material-icons">question_answer</span>
                            Message
                        </NavLink>
                    </ListItem>
                ) : null}
                {global.pluginConfiguration?.ticketing?.enable == 1 && (
                    <ListItem button>
                        <NavLink to="/dashboard/mytickets" activeClassName="active">
                            <span className="material-icons">local_activity</span>
                            My Tickets
                        </NavLink>
                    </ListItem>
                )}
                {/* {user && user.role == 1 && (
                    <ListItem button>
                        <NavLink to="/onboard" activeClassName="active">
                            <span className="material-icons">payments</span>
                            Payment Gateway Onboard
                        </NavLink>
                    </ListItem>
                )} */}
            </ul>
        </div>
    )
}

export default withRouter(DashboardPanel)
