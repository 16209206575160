import React, { useContext } from 'react'
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect'
import publicIp from 'public-ip'
import AuthContext from '../../../Product/context/auth/authContext'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'

const UserActivity = ({ page }) => {
    const { user, userActivityAuctionIo } = useContext(AuthContext)
    const [params, setParams] = useState({
        userid: '',
        site_id: global?.storeDetails?.site_id,
        ipaddress: '',
        page_viewed: page,
        system: `${osName} V-${osVersion}`,
        browser: `${browserName} V-${browserVersion}`,
        link: window.location.href,
    })

    const updateParams = async () => {
        const ip = await publicIp.v4()
        setParams({
            ...params,
            userid: user?.id,
            ipaddress: ip,
        })
    }
    useEffect(() => {
        if (user) {
            updateParams()
        }
    }, [user])

    useEffect(() => {
        if (params.userid) {
            userActivityAuctionIo(params)
        }
    }, [params.userid])
    return <div></div>
}

export default UserActivity
