import React, { useContext, useEffect, useState } from 'react'
import Layout from '../Layout'
import UserContext from '../../../Product/context/user/userContext'
import './Static.css'

const Static = (props) => {
    const userContext = useContext(UserContext)
    const { static_page, getStaticPage } = userContext
    const [loading, setLoading] = useState(true)
    const getFunction = (page_id) => {
        if (page_id !== 0) {
            setLoading(true)
            getStaticPage({ page_id: page_id })
        }
    }

    useEffect(() => {
        setLoading(false)
    }, [static_page])

    useEffect(() => {
        let page_id = 0
        if (props.location.pathname) {
            if (props.location.pathname === '/press') {
                page_id = 'press'
            } else if (props.location.pathname === '/trust') {
                page_id = 'trust'
            } else if (props.location.pathname === '/terms') {
                page_id = 'terms'
            } else if (props.location.pathname === '/privacy') {
                page_id = 'privacy'
            } else if (props.location.pathname === '/cookie-policy') {
                page_id = 'cookie-policy'
            } else if (props.location.pathname === '/about') {
                page_id = 'about'
            } else if (props.location.pathname === '/blog') {
                page_id = 'blog'
            } else if (props.location.pathname === '/laad') {
                page_id = 'laad'
            } else if (props.location.pathname === '/forgotpassword') {
                page_id = 'forgotpassword'
            } else if (props.location.pathname === '/services') {
                page_id = 'services'
            } else if (props.location.pathname === '/our_process') {
                page_id = 'our_process'
            } else if (props.location.pathname === '/considerations') {
                page_id = 'considerations'
            } else if (props.location.pathname === '/news') {
                page_id = 'News'
            }

            getFunction(page_id)
        }
    }, [props.location.pathname])

    return (
        <Layout>
            <div
                className="stcPgWrpr"
                dangerouslySetInnerHTML={{
                    __html: static_page.content,
                }}
            ></div>
        </Layout>
    )
}

export default Static
