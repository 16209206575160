import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import './dashboard.css'
import { ListItem } from '@material-ui/core'
import AuthContext from '../../../Product/context/auth/authContext'
import { commonPaths } from '../../../utils/commonFunctions'
import { useTranslation } from 'react-i18next'
import { scrollTop } from '../../../Product/common/components'

const DashboardPanel = ({ title, subtitle, savedcards, seller, invoices }) => {
    const { user, active_plugins, getActivePlugins } = useContext(AuthContext)
    const { t } = useTranslation()
    return (
        <div className="dashboardLt">
            <ul>
                {user && user.role == 1 && (
                    <ListItem button onClick={() => scrollTop()}>
                        <NavLink to="/dashboard/overview" activeClassName="active">
                            <span className="material-icons">dashboard</span>
                            {t('dashboard')}
                        </NavLink>
                    </ListItem>
                )}
                <ListItem button onClick={() => scrollTop()}>
                    <NavLink activeClassName="active" to="/dashboard/profile">
                        <span className="material-icons">person</span>
                        {t('my_account')}
                    </NavLink>
                </ListItem>

                <hr className="sdBrDvdr" />
                <h4 className="sdDvdrHdr">{t('buyer')}</h4>
                <ListItem button onClick={() => scrollTop()}>
                    <NavLink activeClassName="active" to={commonPaths.mybids}>
                        <span className="material-icons">gavel</span>
                        {t('my_bid')}
                    </NavLink>
                </ListItem>
                <ListItem button onClick={() => scrollTop()}>
                    <NavLink to="/dashboard/favorites" activeClassName="active">
                        <span className="material-icons">favorite</span>
                        {t('my_fav')}
                    </NavLink>
                </ListItem>
                {invoices && (
                    <ListItem button onClick={() => scrollTop()}>
                        <NavLink to="/dashboard/transactions" activeClassName="active">
                            <span className="material-icons">receipt</span>
                            {t('Invoices')}
                        </NavLink>
                    </ListItem>
                )}
                {/* <ListItem button onClick={() => scrollTop()}>
                    <NavLink to="/dashboard/savedSearch" activeClassName="active">
                        <span className="material-icons">bookmark</span>
                        {t('saved_search')}
                    </NavLink>
                </ListItem> */}
                <ListItem button onClick={() => scrollTop()}>
                    <NavLink to="/dashboard/cards" activeClassName="active">
                        <span className="material-icons">credit_card</span>
                        {t('saved_cards')}
                    </NavLink>
                </ListItem>
                <ListItem button onClick={() => scrollTop()}>
                    <NavLink to="/dashboard/preference" activeClassName="active">
                        <span className="material-icons">notifications</span>
                        {t('preferences')}
                    </NavLink>
                </ListItem>

                <ListItem button onClick={() => scrollTop()}>
                    <NavLink to="/dashboard/transactions" activeClassName="active">
                        <span className="material-icons">receipt</span>
                        {t('trasactions')}
                    </NavLink>
                </ListItem>

                {user && user.role == 1 && (
                    <>
                        <hr className="sdBrDvdr" />
                        <h4 className="sdDvdrHdr">{t('seller')}</h4>
                        <ListItem button onClick={() => scrollTop()}>
                            <NavLink activeClassName="active" to="/post-project?type=auction">
                                <span className="material-icons">inventory</span>
                                Post Single Lot
                            </NavLink>
                        </ListItem>
                        <ListItem button onClick={() => scrollTop()}>
                            <NavLink activeClassName="active" to={commonPaths.mylots}>
                                <span className="material-icons">store</span> {t('my_lots')}
                            </NavLink>
                        </ListItem>
                        {/* <ListItem button>
                                <NavLink activeClassName="active" to="/dashboard/buynow">
                                    <span className="material-icons">shopping_basket</span> My Buynow
                                </NavLink>
                            </ListItem> */}
                    </>
                )}
                {user && user.role == 1 && (
                    <>
                        <ListItem button onClick={() => scrollTop()}>
                            <NavLink to="/dashboard/invoices" activeClassName="active">
                                <span className="material-icons">receipt</span>
                                {t('invoices')}
                            </NavLink>
                        </ListItem>
                        <ListItem button onClick={() => scrollTop()}>
                            <NavLink to="/Payoutseller" activeClassName="active">
                                <span className="material-icons">receipt</span>
                                {t('payout')}
                            </NavLink>
                        </ListItem>
                    </>
                )}

                {user && user.role == 1 && (
                    <ListItem button onClick={() => scrollTop()}>
                        <NavLink to="/dashboard/reports" activeClassName="active">
                            <span className="material-icons">description</span>
                            {t('reports')}
                        </NavLink>
                    </ListItem>
                )}
                {user &&
                    user.role == 1 &&
                    global.pluginConfiguration?.reseller_subscription?.enable && (
                        <ListItem button onClick={() => scrollTop()}>
                            <NavLink to="/dashboard/subscription" activeClassName="active">
                                <span className="material-icons">loyalty</span>
                                {t('subscription')}
                            </NavLink>
                        </ListItem>
                    )}

                {global.storeConfigration?.remove_message_module?.value != 1 ? (
                    <ListItem button onClick={() => scrollTop()}>
                        <NavLink to="/dashboard/message" activeClassName="active">
                            <span className="material-icons">question_answer</span>
                            {t('messages')}
                        </NavLink>
                    </ListItem>
                ) : null}
            </ul>
        </div>
    )
}

export default withRouter(DashboardPanel)
