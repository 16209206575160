import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import ReadMoreReact from 'read-more-react'
import ImageGallery from 'react-image-gallery'
import Toaster from '../Toaster'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import Bidhistory from '../Bidhistory'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import AuthContext from '../../Product/context/auth/authContext'
import CartContext from '../../Product/context/cart/cartContext'
import AlertContext from '../../Product/context/alert/alertContext'

import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Timer from '../../Product/common/timer'
import { messageHandlerSingle } from '../../Product/common/socketHandler'
import { socket, socketAuction } from '../../Product/common/socket'
import { makeStyles } from '@material-ui/core/styles'

import { Button, Drawer } from '@material-ui/core'
import { currencyFormat, handleRedirectInternal } from '../../Product/common/components'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import './slider.css'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { allCondition, storageOptions, unitOptions } from '../../utils'
import HowBidPopup from '../HowBiddingWorks'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import BidStatus from '../../Product/components/molecules/Bidding/BidStatus'
import { getAllMake, getMakeById, getModelById } from '../../utils/CarConstants'
import ReactPlayer from 'react-player'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { PhotoSwipeGallery } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import ProductContext from '../../Product/context/product/productContext'

export function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="tabBody">{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const StandaloneVehicle = ({ type, selectedLot, handleClose, viewPage, storeTheme, theme }) => {
    const buyerContext = useContext(BuyerContext)
    const cartContext = useContext(CartContext)
    const alertContext = useContext(AlertContext)

    const [masterCat, setMasterCat] = useState('')
    const [childCat, setChildCategory] = useState('')

    const authContext = useContext(AuthContext)
    const { setAlert } = alertContext
    const [value, setValue] = useState(0)

    const forMobile = useMediaQuery('(max-width:1024px)')

    const { user, isAuthenticated } = authContext
    const { buyer_allproducts, getAllBuyerProducts, product_view, getViewProduct } = buyerContext
    const { addToCart, buyer_cartitems, getUserCart, removeFromCart, responseStatus } = cartContext
    const productContext = useContext(ProductContext)
    const { getOverAllCategories, categories, getSubCategories, sub_categories } = productContext
    const history = useHistory()
    const [historyData, setHistoryData] = useState({})
    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [selectedProductImage, setSelectedProductImage] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [photoGallery, setPhotoGallery] = useState(false)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { id } = useParams()
    const matches = useMediaQuery('(max-width:800px)')
    const [howBidPopup, setHowBidPopup] = useState(false)
    useEffect(() => {
        if (selectedLot) {
            var data = {
                product_id: selectedLot,
            }
            if (theme == 'multiAuction') {
                data.crop_auction_type = 'buyer'
            }
            getViewProduct(data)
        }
    }, [selectedLot])
    useEffect(() => {
        if (id) {
            getViewProduct({ product_id: id })
        }
    }, [id])

    // console.log(product_view, 'product_view')
    useEffect(() => {
        if (product_view.results) {
            if (product_view.results.work_loc) {
                product_view.results.documents = product_view.results.work_loc.split(',')
            }
            if (product_view.results.custom_field_8) {
                const addr = JSON.parse(product_view.results.custom_field_8)
                product_view.results.address = addr.address
                product_view.results.zipcode = addr.zipcode
            }
            // if (product_view.results.custom_field_9) {
            //     const food = JSON.parse(product_view?.results.custom_field_9)
            //     product_view.results.storage = food.storage
            //     product_view.results.package_date = food.package_date
            //     product_view.results.selling_unit = food.selling_unit
            //     product_view.results.total_weight = food.total_weight
            // }
            setSelectedProduct({
                ...product_view.biddetails,
                ...product_view.results,
                incrementamt: product_view.incrementamt,
            })
            if (product_view?.attachments.length) {
                product_view?.attachments.forEach(function (data) {
                    data['original'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
                    }`

                    data['thumbnail'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL
                    }${global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''}${
                        data.src
                    }`
                })
                setSelectedProductImage(product_view.attachments)
            } else {
                setSelectedProductImage([])
            }

            setLoading(false)
        }
    }, [product_view.results])

    useEffect(() => {
        if (buyer_cartitems.results) {
            if (selectedProduct.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: selectedProduct.id,
                })
                position === -1 ? setDisabled(false) : setDisabled(true)
            }
        }
    }, [buyer_cartitems.results])

    const handleClick = async (e) => {
        addToCart({ id: selectedProduct.id, qty: selectedProduct.qty })
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                }
            }
        }
    }, [responseStatus])

    const viewProductRef = useRef(selectedProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = selectedProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        // console.log('reverse handler ', message, viewProductRef.current)
        messageHandlerSingle(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    const multiseller_handler = (message, type) => {
        if (parseInt(viewProductRef.current.content_head5) == 0) {
            messageHandlerSingle(
                message,
                viewProductRef.current,
                userRef.current,
                setAlert,
                setSelectedProduct,
                type,
            )
        }
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                data.id = data.pid

                if (
                    parseInt(data.id) == parseInt(viewProductRef.current.id) &&
                    window.location.href.includes('/productView')
                ) {
                    moveon_next_page()
                }
            }
            // console.log('real close update data reverse auction', data)
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    data.id = data.pid
                }
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const lotInformation = [
        // {
        //     title: (
        //         <>
        //             <span className="material-icons">description</span>Auction Details
        //         </>
        //     ),
        //     description: selectedProduct?.description,
        // },

        {
            title: (
                <>
                    <span className="material-icons">description</span>Documents
                </>
            ),
            description: selectedProduct?.documents?.map((val) => (
                <>
                    <a
                        className="docView"
                        href={`${process.env.REACT_APP_BASE_URL}uploads/${val}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="material-icons">description</span>
                    </a>
                </>
            )),
            html: 1,
        },
    ]
    // if (global.storeConfigration?.disable_contacts_field?.value != 1) {
    //     lotInformation.push({
    //         title: (
    //             <>
    //                 <span className="material-icons">contacts</span>Contacts
    //             </>
    //         ),
    //         description: selectedProduct?.custom_field_5,
    //     })
    // }
    // if (global.storeConfigration?.disable_tax_field?.value != 1) {
    //     lotInformation.push({
    //         title: (
    //             <>
    //                 <span className="material-icons">percent</span>Tax/Removal
    //             </>
    //         ),
    //         description: selectedProduct?.custom_field_4,
    //     })
    // }
    if (global.storeConfigration?.disable_terms_field?.value != 1) {
        lotInformation.push({
            title: (
                <>
                    <span className="material-icons">privacy_tip</span>Terms & Conditions
                </>
            ),
            description: selectedProduct?.custom_field_7,
        })
    }

    //useEffect(() => {
    //     if (global.pluginConfiguration?.reverse_auction?.enable) {
    //         //&& selectedProduct.market_status != 'open'
    //         console.log('ooooooooooooooooooooooooooooooooooooooooooo1')
    //         if (lotDetails) {
    //             console.log('ooooooooooooooooooooooooooooooooooooooooooo2')
    //             lotDetails.map((val, index) => {
    //                 console.log('ooooooooooooooooooooooooooooooooooooooooooo3')
    //                 if (val.id == selectedProduct.id) {
    //                     console.log('ooooooooooooooooooooooooooooooooooooooooooo4')
    //                     if (lotDetails.length > parseInt(index) + 1) {
    //                         console.log(
    //                             'ooooooooooooooooooooooooooooooooooooooooooo5',
    //                             lotDetails[index + 1].id,
    //                         )
    //                         history.push({
    //                             pathname: `/productView/${lotDetails[index + 1].id}`,
    //                             state: { lotid: lotDetails },
    //                         })
    //                     }
    //                 }
    //             })
    //         }
    //     }
    // }, [selectedProduct])
    const moveon_next_page = () => {
        if (global.pluginConfiguration?.reverse_auction?.enable) {
            //&& selectedProduct.market_status != 'open'
            // console.log('ppppppppppppppppppppppppppppppppppp0')
            if (lotDetails) {
                lotDetails.map((val, index) => {
                    // console.log(
                    //     'ppppppppppppppppppppppppppppppppppp1',
                    //     val.id,
                    //     viewProductRef.current.id,
                    // )
                    if (parseInt(val.id) == parseInt(viewProductRef.current.id)) {
                        if (lotDetails.length > parseInt(index) + 1) {
                            // console.log('ppppppppppppppppppppppppppppppppppp2')
                            setAlert('Next Item Loaded Here.Please Continue To Bid.', 'success')
                            history.push({
                                pathname: `/productView/${lotDetails[index + 1].id}`,
                                state: { lotid: lotDetails },
                            })
                        }
                    }
                })
            }
        }
    }

    const handlePhotoSwipeClose = () => {
        setPhotoGallery(false)
    }

    useEffect(() => {
        getOverAllCategories({ parent: 1 }, '1')
    }, [])

    useEffect(() => {
        setMasterCat(categories)
    }, [categories])

    useEffect(() => {
        if (Object.keys(selectedProduct).length !== 0) {
            getSubCategories({ parent_id: selectedProduct.category_id })
        }
    }, [selectedProduct])

    useEffect(() => {
        setChildCategory(sub_categories)
    }, [sub_categories])

    // console.log('mastercat value', masterCat, childCat)

    return (
        <>
            {Object.keys(selectedProduct).length !== 0 && (
                <div className="vehicleProduct">
                    {isAuthenticated ? (
                        selectedProduct.auction ? (
                            type === 'buynow' ? null : (
                                <>
                                    <BidStatus bidTopStatus={selectedProduct.bidtopstatus} />
                                </>
                            )
                        ) : null
                    ) : null}
                    <div className="mpTop">
                        <div>
                            <Button onClick={() => history.goBack()} className="mb-3">
                                <span className="material-icons mr-2">keyboard_backspace</span>Back
                            </Button>
                            <h3 className="plTitle">
                                <span
                                    className="pdtTtle"
                                    dangerouslySetInnerHTML={{
                                        __html: selectedProduct.title,
                                    }}
                                />
                                <div className="lotChip">Lot# {selectedProduct.id}</div>
                            </h3>
                        </div>
                        {selectedProduct?.auction ? (
                            <>
                                {selectedProduct.market_status != 'open' ||
                                selectedProduct.productClosed ? (
                                    'Closed'
                                ) : selectedProduct.extend || selectedProduct.agent_id ? (
                                    <span className="tmm">
                                        <Timer
                                            date_added={selectedProduct.date_added}
                                            date_closed={selectedProduct.date_closed}
                                            withText={1}
                                            endText={'Ends in:'}
                                            startText={'Starts in:'}
                                            theme="multiseller"
                                        ></Timer>
                                    </span>
                                ) : (
                                    <span>
                                        <Timer
                                            date_added={selectedProduct.date_added}
                                            date_closed={selectedProduct.date_closed}
                                            withText={1}
                                            endText={'Ends in' + ':'}
                                            startText={'Starts in' + ':'}
                                            theme="multiseller"
                                        ></Timer>
                                    </span>
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>

                    {theme === 'multiAuction' ? (
                        <>
                            {selectedProduct.market_status == 'closed' ? (
                                <h6 style={{ float: 'right', color: '#81daf3' }}>
                                    <span>Unsold</span>
                                </h6>
                            ) : selectedProduct.market_status == 'sold' ? (
                                <h6 style={{ float: 'right', color: '#81daf3' }}>
                                    {`Sold Bid : ${currencyFormat(selectedProduct.wprice)}`}
                                </h6>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        ''
                    )}
                    <div className="imgGallery">
                        <ImageGallery
                            items={selectedProductImage}
                            thumbnailPosition={forMobile ? 'bottom' : 'left'}
                            showNav={false}
                            showBullets={false}
                            showFullscreenButton={true}
                            showPlayButton={false}
                            onErrorImageURL={`${global?.storeDetails?.logoValue}`}
                            originalClass="slidImg img-fluid"
                        />
                    </div>
                    <div className="pvsCnt">
                        <div className="photoGallery">
                            <h4>Photo Gallery</h4>
                            <div className="phtSwpr">
                                <PhotoSwipeGallery
                                    isOpen={photoGallery}
                                    items={selectedProductImage?.map((data, index) => {
                                        return {
                                            src: data.original,
                                            w: 1200,
                                            h: 700,
                                            title: `image_${index}`,
                                        }
                                    })}
                                    // options={options}
                                    // thumbnailContent={getThumbnailContent}
                                    onClose={handlePhotoSwipeClose}
                                />
                            </div>
                        </div>
                        <div className="pvsRight">
                            <div className="dscrptnFld">
                                <h4>Description</h4>
                                <div
                                    className="content"
                                    dangerouslySetInnerHTML={{
                                        __html: selectedProduct?.description,
                                    }}
                                />
                            </div>
                            <hr />
                            <h4 className="mb-3">Highlights</h4>
                            <div className="vehicleDetails">
                                <ul>
                                    <li>
                                        <label>Make</label>
                                        <span>{selectedProduct?.category_id}</span>
                                    </li>
                                    <li>
                                        <label>Year</label>
                                        <span>{selectedProduct?.custom_field_8}</span>
                                    </li>
                                    {/* <li>
                                        <label>Fuel Type</label>
                                        <span>DIESEL</span>
                                    </li> */}
                                    <li>
                                        <label>Body Type</label>
                                        <span>{selectedProduct?.custom_field_2}</span>
                                    </li>
                                    <li>
                                        <label>Engine</label>
                                        <span>{selectedProduct?.custom_field_4}</span>
                                    </li>
                                    <li>
                                        <label>
                                            {selectedProduct?.qcdby == 'bike'
                                                ? 'Front Brake'
                                                : 'DriveTrain'}
                                        </label>
                                        <span>{selectedProduct?.custom_field_3}</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <label>Model</label>
                                        <span>{selectedProduct?.subcategory}</span>
                                    </li>
                                    {selectedProduct.qcdby == 'car' ? (
                                        <li>
                                            <label>{'KMS Driven'}</label>
                                            <span>{selectedProduct?.content_head5}KM</span>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {selectedProduct?.qcdby == 'bike' ? (
                                        <li>
                                            <label>Color</label>
                                            <span>{selectedProduct?.content_head3}</span>
                                        </li>
                                    ) : (
                                        <li>
                                            <label>Transmission</label>
                                            <span>{selectedProduct?.custom_field_1}</span>
                                        </li>
                                    )}

                                    {selectedProduct?.qcdby == 'bike' ? (
                                        <li>
                                            <label>Mileage</label>
                                            <span>{selectedProduct?.content_head4} KM/L</span>
                                        </li>
                                    ) : (
                                        <li>
                                            <label>Exterior Color</label>
                                            <span>{selectedProduct?.content_head3}</span>
                                        </li>
                                    )}

                                    {selectedProduct?.qcdby == 'bike' ? (
                                        ''
                                    ) : (
                                        <li>
                                            <label>Interior Color</label>
                                            <span>{selectedProduct?.content_head4}</span>
                                        </li>
                                    )}

                                    {selectedProduct?.qcdby == 'bike' ? (
                                        <li>
                                            <label>Rear Brake</label>
                                            <span>{selectedProduct?.custom_field_1}</span>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                </ul>
                            </div>
                            <div className="bdngWrapper">
                                <div className="dtInfo">
                                    {selectedProduct?.auction ? (
                                        <>
                                            {selectedProduct.market_status != 'open' ||
                                            selectedProduct.productClosed ? (
                                                <h5>Auction Closed: date</h5>
                                            ) : (
                                                <>
                                                    <h5>
                                                        <span className="material-icons">
                                                            today
                                                        </span>
                                                        <span>Auction Opens</span>
                                                        {moment(selectedProduct.date_added)
                                                            .tz('America/New_York')
                                                            .format('MMM Do YYYY, h:mm a')}
                                                    </h5>
                                                    <h5>
                                                        <span className="material-icons">
                                                            today
                                                        </span>
                                                        <span>Auction Closes</span>
                                                        {moment(selectedProduct.date_closed)
                                                            .tz('America/New_York')
                                                            .format('MMM Do YYYY, h:mm a')}
                                                    </h5>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="mssBidItem">
                                    <div className="productInfo">
                                        {selectedProduct?.auction ? (
                                            <>
                                                <h5>
                                                    <span>Current Bid</span>
                                                    {currencyFormat(selectedProduct.wprice)}
                                                </h5>
                                                <h5>
                                                    <span>Minimum Bid</span>
                                                    {currencyFormat(selectedProduct.next_bid)}
                                                </h5>
                                                {selectedProduct.buynow ? (
                                                    <h5>
                                                        <span>Buy Now Price</span>
                                                        {currencyFormat(selectedProduct.bprice)}
                                                    </h5>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <h5>
                                                    <span>Buy Now Price</span>
                                                    {currencyFormat(selectedProduct.bprice)}
                                                </h5>
                                            </>
                                        )}
                                    </div>
                                    {selectedProduct.market_status != 'open' ||
                                    selectedProduct.productClosed ? (
                                        ''
                                    ) : (
                                        <div className="sl-pbids slidView">
                                            {isAuthenticated ? (
                                                <>
                                                    {selectedProduct.auction ? (
                                                        type === 'buynow' ? null : (
                                                            <Fragment>
                                                                {selectedProduct.user_id ==
                                                                user.id ? (
                                                                    <p className="cntBdOwn">
                                                                        You cannot bid on your own
                                                                        product!
                                                                    </p>
                                                                ) : (
                                                                    <>
                                                                        <Bidding
                                                                            data={selectedProduct}
                                                                            hard="1"
                                                                        />
                                                                        {selectedProduct?.bids >
                                                                        0 ? (
                                                                            <Bidding
                                                                                data={
                                                                                    selectedProduct
                                                                                }
                                                                                hard="0"
                                                                            />
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Fragment>
                                                        )
                                                    ) : null}
                                                    {theme != 'multiAuction' && (
                                                        <>
                                                            {selectedProduct.auction &&
                                                            type !== 'buynow' ? (
                                                                <>
                                                                    {selectedProduct?.bids > 0 ? (
                                                                        <>
                                                                            <Button
                                                                                className={`mssBidHistory ${
                                                                                    selectedProduct.user_id ==
                                                                                    user.id
                                                                                        ? 'w-100'
                                                                                        : ''
                                                                                }`}
                                                                                onClick={() =>
                                                                                    selectedProduct.bids &&
                                                                                    selectedProduct.bids !==
                                                                                        0 &&
                                                                                    !selectedProduct.is_past &&
                                                                                    setHistoryData({
                                                                                        product_id:
                                                                                            selectedProduct.id,
                                                                                        end: selectedProduct.date_closed,
                                                                                    })
                                                                                }
                                                                            >
                                                                                <span className="material-icons">
                                                                                    history
                                                                                </span>
                                                                                View bid history
                                                                            </Button>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            ) : null}
                                                        </>
                                                    )}

                                                    {(parseInt(selectedProduct.buynow) == 1 &&
                                                        parseInt(selectedProduct.auction) == 0) ||
                                                    (parseInt(selectedProduct.wprice) <=
                                                        parseInt(selectedProduct.bprice) &&
                                                        parseInt(selectedProduct.buynow) == 1 &&
                                                        parseInt(selectedProduct.auction) == 1) ? (
                                                        <>
                                                            <div className="sl-pbids slidView">
                                                                <div className="row d-flex flex-nowrap">
                                                                    {selectedProduct.user_id ==
                                                                    user.id ? (
                                                                        <p className="cntBdOwn">
                                                                            You cannot buy your own
                                                                            product!
                                                                        </p>
                                                                    ) : (
                                                                        <button
                                                                            className="pink-btn pbtn-sm"
                                                                            onClick={handleClick}
                                                                            disabled={disabled}
                                                                        >
                                                                            {disabled ? (
                                                                                'Added to cart'
                                                                            ) : (
                                                                                <span className="d-flex align-items-center justify-content-center">
                                                                                    Buy Now
                                                                                    <span className="material-icons ml-2">
                                                                                        shopping_cart
                                                                                    </span>
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <br />
                                                        </>
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {selectedProduct?.custom_field_9.length > 0 && (
                                <div className="vdosWrpr">
                                    <hr />
                                    <h4 className="mb-3">Videos</h4>
                                    <div className="vdoGridWrpr">
                                        {selectedProduct?.custom_field_9
                                            .split(',')
                                            .map((data, index) => (
                                                <>
                                                    <ReactPlayer
                                                        light={false}
                                                        controls={true}
                                                        url={data?.trim()}
                                                    />
                                                </>
                                            ))}
                                    </div>
                                </div>
                            )}
                            <div className="docInfo my-4">
                                {lotInformation.map((data, index) => (
                                    <>
                                        <Accordion key={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel${index}}d-content`}
                                                id={`panel${index}}d-header`}
                                            >
                                                <h6 className="accTitle">{data.title}</h6>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {data.html ? (
                                                    <div className="dcmntVw">
                                                        {data.description}
                                                    </div>
                                                ) : (
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.description,
                                                        }}
                                                    ></p>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* <div className="pvsBottom"></div> */}

                    {/* {lotInformation && Object.keys(lotInformation).length ? (
                            <>
                                <AppBar position="static">
                                    <Tabs
                                        value={value}
                                        variant={matches ? 'scrollable' : 'fullWidth'}
                                        onChange={handleChange}
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                    >
                                        {lotInformation.map((data, index) => (
                                            <Tab label={data.title} {...a11yProps({ index })} />
                                        ))}
                                    </Tabs>
                                </AppBar>
                                {lotInformation.map((data, index) => (
                                    <TabPanel value={value} index={index} key={index}>
                                        {data.html ? (
                                            data.description
                                        ) : (
                                            <div
                                                className="content"
                                                dangerouslySetInnerHTML={{
                                                    __html: data.description,
                                                }}
                                            />
                                        )}
                                    </TabPanel>
                                ))}
                            </>
                        ) : null} */}

                    <Bidhistory
                        modaltitle="Bid history"
                        open={Object.keys(historyData).length !== 0}
                        value={historyData}
                        handleClose={() => setHistoryData({})}
                    />
                    <HowBidPopup open={howBidPopup} handleClose={() => setHowBidPopup(false)} />
                </div>
            )}
        </>
    )
}

export default StandaloneVehicle
