import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import './Bio.css'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import userContext from '../../../Product/context/user/userContext'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'

const BioCard = (props) => {
    const { isAuthenticated, user, loadUserDetails } = useContext(AuthContext)
    const { follow } = useContext(userContext)
    const history = useHistory()
    const { id } = useParams()
    const [followed, setFollowed] = useState(true)
    const isArtTheme = document.querySelector('body').classList.contains('artTheme')
    const params = new URLSearchParams(location.search)
    const user_type = params.get('type') ? params.get('type') : ''
    const addDefaultSrc = (e) => {
        e.target.src = `${global?.storeDetails?.logoValue}`
    }
    // const userRef = useRef(user)
    const userFollow = () => {
        if (!isAuthenticated) {
            return handleRedirectInternal(history, 'login')
        }
        follow({ follow: followed === 1 ? 0 : 1, user_id: id })
    }
    console.log(user_type, 'user_type')
    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const user_type = params.get('type') ? params.get('type') : ''

        loadUserDetails(
            user_type == 'Artist'
                ? {
                      user_id: id,
                      status: 'open,sold',
                      contenthead5: user_type == 'Artist' ? params.get('search') : '',
                      user: user?.id,
                      page: 1,
                      limit: 10,
                  }
                : {
                      user_id: id,
                      status: 'open,sold',
                      user: user?.id,
                      page: 1,
                      limit: 10,
                  },
        )
    }, [user, id])

    // useEffect(() => {
    //     viewProductRef.current = userProductsData
    //     userRef.current = user
    // })

    // useEffect(() => {
    //     if (Object.keys(userDetails).length) {
    //         setFollowed(userDetails.followed)
    //         setUserData(userDetails)
    //     }
    // }, [userDetails])

    // useEffect(() => {
    //     if (Object.keys(userDetails).length) {
    //         socket.on('follow_count', (data) => {
    //             followHandler(data, setUserData, user, id, setFollowed)
    //         })
    //     }
    // }, [userDetails])

    return (
        <div
            className="bioCard"
            key={props.index}
            onClick={() =>
                props.handleClick
                    ? props.handleClick()
                    : handleRedirectInternal(history, props.redirectUrl)
            }
        >
            <img src={props.avatar} onError={addDefaultSrc} />
            {isArtTheme ? (
                <div className="crdDtlCnt">
                    <div>
                        <h5>{props.title}</h5>
                        <span className="artCreator">Digital Art Creator</span>
                    </div>
                    {user?.id == id ? null : (
                        <>
                            {props.sellerFollow ? (
                                <PrimaryButton
                                    label={
                                        <>
                                            <span className="material-icons mr-1">add</span>
                                            follow
                                        </>
                                    }
                                    onClick={userFollow}
                                />
                            ) : (
                                <SecondaryButton label={<>unfollow</>} onClick={userFollow} />
                            )}
                        </>
                    )}
                </div>
            ) : (
                <div className="crdDtlCnt">
                    <h5>
                        {props.title?.slice(0, 10)}
                        {props.title?.length > 10 ? '...' : ''}
                    </h5>
                </div>
            )}
        </div>
    )
}

export default BioCard
