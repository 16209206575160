import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer, SwipeableDrawer, ListItem } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SlidePanel from '../../../Component/SlidePanel'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { messageHandler } from '../../../Product/common/socketHandler'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import { socket, socketAuction } from '../../../Product/common/socket'
import Slider from 'react-slick'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { handleRedirectInternal } from '../../../Product/common/components'
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HeaderSearchComponent from '../Header/HeaderSearch'
import { sustainabilityOptions } from '../../../utils'
import Timer from '../../../Product/common/timer'
import moment from 'moment'
import SlidePopup from '../../../Component/SlidePanel/SlidePopup'
import { setAuthToken } from '../../../Product/common/api'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const history = useHistory()

    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const {
        search_allproducts,
        getAllSearchProducts,
        search_allauctions,
        getAllSearchAuctions,
        getUpcomingList,
        upcoming_list,
    } = productContext
    const [auctionView, setAuctionView] = useState('Grid')

    const { isAuthenticated, user } = authContext
    const { setAlert } = alertContext

    const classes = useStyles()

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalItems, setTotalItem] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    const [viewAuction, setViewAuction] = useState([])

    const [upcomingProducts, setUpcomingProducts] = useState([])

    const forMobile = useMediaQuery('(max-width:1024px)')

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const [viewType] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('type'),
    )
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: 12,
            page: 1,
            orderby: '6',
            location: [],
            state: [],
            city: [],
            category: catg ? [catg] : [],
            contenthead5: [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            startdate: params.get('start_date') ? params.get('start_date') : '',
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            // from: '',
            closed_auction_list: 0,
            view_type: viewType == 'auction' ? 1 : viewType == 'buynow' ? 2 : 0,
            auction_io: 1,
        },
    })

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: '0',
        type: '',
    })

    const filterValues = [
        {
            title: 'View',
            type: 'radio',
            noLabel: true,
            name: 'view_type',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'All Items' },
                { id: 1, description: 'Single Lots' },
                { id: 2, description: 'Buy Now' },
            ],
            formik: formik,
        },

        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories
                .sort((a, b) => a.description.localeCompare(b.description))
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
        {
            title: 'Upcoming Auctions',
            type: 'radio',
            noLabel: true,
            name: 'startdate',
            item: viewAuction.map((data, index) => ({
                id: data.date_added,
                description: moment(data.date_added).format('MMM Do'),
            })),
            // .filter((main) => {
            //     return true
            // }),
            formik: formik,
        },
        // {
        //     title: 'Sustainability',
        //     type: 'check',
        //     name: 'contenthead5',
        //     options: sustainabilityOptions
        //         .map((buscat) => {
        //             let busCategoryChanged = {}
        //             busCategoryChanged.description = buscat.description
        //             busCategoryChanged.name = buscat.description
        //             busCategoryChanged.value = buscat.id
        //             busCategoryChanged.id = buscat.id
        //             busCategoryChanged.active = 1
        //             return busCategoryChanged
        //         })
        //         .sort((a, b) => a.description.localeCompare(b.description))
        //         .filter((main) => {
        //             return true
        //         }),
        //     formik: formik,
        // },

        {
            title: 'Auction Status',
            type: 'radio',
            noLabel: true,
            name: 'closed_auction_list',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'Live Items' },
                { id: 1, description: 'Closed Items' },
            ],
            formik: formik,
        },

        // {
        //     title: 'Sub category',
        //     type: 'check',
        //     name: 'subcategory',
        //     options: global.searchSubCategories.filter((subcat) => {
        //         // if (
        //         //     formik &&
        //         //     formik.values &&
        //         //     formik.values.category &&
        //         //     formik.values.category.length
        //         //         ? formik.values.category.includes(subcat.level_id.toString())
        //         //         : false
        //         // ) {
        //         return true
        //         //}
        //     }),
        //     formik: formik,
        // },
    ]

    useEffect(() => {
        setLoading(false)

        if (search_allproducts.from === 'activeAuctions') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalItem(search_allproducts.total_pagecnt)
        }

        if (search_allproducts.from === 'upcomingProducts') {
            // console.log('upcoming upcomingProducts results', search_allproducts?.results)
            setUpcomingProducts(search_allproducts.results.length ? search_allproducts.results : [])
        }
    }, [search_allproducts, formik.values])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    useEffect(() => {
        if (viewType == 'upcoming' && viewAuction.length != 0) {
            formik.setFieldValue('startdate', viewAuction[0].date_added)
        }
    }, [viewType, viewAuction])

    // useEffect(() => {
    //     if (search_allauctions.from === 'upcomingHeader') {
    //         setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
    //     }
    // }, [search_allauctions])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'upcomingHeader')
    }, [auctionSearch])
    useEffect(() => {
        getUpcomingList({ date: new Date() })
    }, [])
    useEffect(() => {
        if (upcoming_list.length) {
            setViewAuction(upcoming_list)
        }
    }, [upcoming_list])
    const onHandlePage = (event, value) => {
        formik.setFieldValue('page', value)
    }
    useEffect(() => {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        setLoading(true)
        if (formik.values.closed_auction_list) {
            //formik.values.orderby = '7'
        }
        // console.log('formik values console')
        if (formik.values.view_type == 1) {
            formik.values.from = 'auction_only'
            getAllSearchProducts(formik.values, 'activeAuctions')
        } else if (formik.values.view_type == 2) {
            formik.values.from = 'buynow'
            getAllSearchProducts(formik.values, 'activeAuctions')
        } else {
            formik.values.from = ''
            getAllSearchProducts(formik.values, 'activeAuctions')
        }
    }, [isAuthenticated, formik.values])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('miles', params.get('m') ? params.get('m') : '')
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        if (isAuthenticated) {
            setSelectedProduct(id)
        } else {
            handleRedirectInternal(history, 'login')
        }
    }

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]

    const settings = {
        dots: true,
        fade: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }

    return (
        <Layout props={props}>
            <div className="customContainer mt-5 position-relative">
                {forMobile ? (
                    <div className="mblStckyFilter d-flex justify-content-between align-items-center py-3">
                        <Button
                            className="filterButton w-100"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                        >
                            Filters
                        </Button>

                        <CustomSelect
                            label="Sort by"
                            value={formik.values.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                formik.setFieldValue('orderby', event.target.value)
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>

                        {/* <Button
                            className="filterButton w-100"
                            variant="outlined"
                            onClick={() => handleRedirectInternal(history, 'dashboard/mybids')}
                        >
                            My Bids
                        </Button> */}

                        <Button
                            className="filterButton srchBtn w-100"
                            variant="outlined"
                            onClick={toggleDrawer('top', true)}
                        >
                            <span className="material-icons">search</span>
                        </Button>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center flex-wrap my-5">
                        <h2 className="auctionListTitle"></h2>

                        <div className="auctionsMiscFilter">
                            <CustomSelect
                                label="Results per page"
                                name="resultsPerPage"
                                selectType="noBorder"
                                value={formik.values.sh_limit}
                                size="small"
                                onChange={(event, editor) => {
                                    formik.setFieldValue('sh_limit', event.target.value)
                                }}
                            >
                                {pageOptions.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                label="Sort by"
                                value={formik.values.orderby}
                                size="small"
                                selectType="noBorder"
                                name="orderby"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    formik.setFieldValue('orderby', event.target.value)
                                }}
                            >
                                {sortShow.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                        </div>
                        <Button
                            className="toggleRespDrawer w-100"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                        >
                            <span className="material-icons">tune</span>
                            Filters
                        </Button>
                    </div>
                )}

                <div className="searchContainer">
                    <div className="searchLt">
                        <FilterPanel formik={formik} filterValues={filterValues} allOpen={true} />
                    </div>

                    <div className="searchRt">
                        {isLoading ? (
                            <div>
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                            </div>
                        ) : viewProduct?.length !== 0 ? (
                            <div
                                className={`lotResults ${auctionView} liquidationBuyCard ${
                                    !isAuthenticated ? 'unauth' : 'auth'
                                }`}
                            >
                                {viewProduct.map((data, index) =>
                                    auctionView === 'Grid' ? (
                                        <>
                                            {
                                                <GridProduct
                                                    data={data}
                                                    className={data.auction ? 'auction' : 'buynow'}
                                                    key={index}
                                                    type={data.auction ? 'auction' : 'buynow'}
                                                    selectedProduct={selectedProduct}
                                                    link={`/gallery/product-view/${data.id}`}
                                                    handleClick={handleClick}
                                                    timerTheme=""
                                                    sliderTheme="multiseller"
                                                    // currencyType="cad"
                                                    cardTheme="auctioneer"
                                                    bidBtnLabel="Place Bid"
                                                    showCondition={false}
                                                    showLocation={true}
                                                    timerIcon={true}
                                                    viewProduct={viewProduct}
                                                    setViewProduct={setViewProduct}
                                                />
                                            }
                                        </>
                                    ) : (
                                        <MultisellerListCard
                                            lotData={data}
                                            className={data.auction ? 'auction' : 'buynow'}
                                            key={index}
                                            selectedProduct={selectedProduct}
                                            allsearchitems={viewProduct}
                                            type="lot"
                                            handleClick={handleClick}
                                            details={`/gallery/product-view/${data.id}`}
                                            sliderTheme="multiseller"
                                            card_theme="FoodIndustry"
                                            bidBtnLabel={
                                                <>
                                                    <span className="material-icons-outlined">
                                                        gavel
                                                    </span>
                                                    Bid
                                                </>
                                            }
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                        />
                                    ),
                                )}
                            </div>
                        ) : (
                            <NoRecordsFound />
                        )}
                        <Pagination
                            count={Math.ceil(totalItems / formik.values.sh_limit)}
                            page={formik.values.page}
                            onChange={onHandlePage}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </Drawer>
            <SlidePopup
                storeTheme=""
                selectedLot={selectedProduct}
                handleClose={handleClose}
                type={props.type ? props.type : ''}
            />
            <React.Fragment>
                <SwipeableDrawer
                    className="respHeaderSearch"
                    anchor={'top'}
                    open={state['top']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('top', false)}
                    onOpen={toggleDrawer('top', true)}
                >
                    <HeaderSearchComponent />
                </SwipeableDrawer>
            </React.Fragment>
        </Layout>
    )
}

export default AuctionSearchComponent
