/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import { Button, Popover, ListItem, Divider, SwipeableDrawer, MenuItem } from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../../../utils/index'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import BecomeSeller from '../../../utils/CommonFunctionality/BecomeSeller'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import ProductContext from '../../../Product/context/product/productContext'
import Timer from '../../../Product/common/timer'
import url from 'socket.io-client-latest/lib/url'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import PaymentPendingHeader from '../../../utils/CommonFunctionality/PaymentPendingHeader'
import { isNull } from 'lodash'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const location = useLocation()

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [anchormenu1, setanchormenu1] = useState(null)
    const [auctionMenu, setAuctionMenu] = useState(null)
    const [open, setOpen] = useState(false)

    const { user, isAuthenticated, logout } = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext

    const [viewAuction, setViewAuction] = useState([])

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: '',
        type: '',
    })

    const { setAlert } = useContext(AlertContext)
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
        left: false,
    })

    const [listingAnchor, setListingAnchor] = useState(null)
    const listingOpen = Boolean(listingAnchor)

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'activeHeader')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'activeHeader') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }
    const handleMenu1 = (event) => {
        setanchormenu1(event.currentTarget)
    }

    const handleMenuAuction = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setAuctionMenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setAuctionMenu(null)
        setanchormenu1(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const handleListingClick = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setListingAnchor(event.currentTarget)
    }

    const handleListingClose = () => {
        setListingAnchor(null)
    }
    const toggleOpen = () => {
        setOpen(!open)
    }

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const logoutUser = () => {
        logout()
        history.push('/login')
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search/product-buyer-auction/${catId}`)
    }

    const handleSellClick = (path) => {
        if (isAuthenticated) {
            if (!user?.card_profileid) {
                setAlert('Kindly add account id to continue!', 'warning')
                return handleRedirectInternal(history, 'dashboard/profile')
            } else if (user && user.role == 1) {
                return handleRedirectInternal(history, path)
            } else {
                return handleRedirectInternal(history, 'becomeSeller')
            }
        } else {
            return handleRedirectInternal(history, 'becomeSeller')
        }
    }

    let newUrl = new URLSearchParams(location.search)

    return (
        <>
            {isDesktop ? (
                /* DESKTOP NAVIGATION */
                <>
                    <div className="headerTop noPrint">
                        <div className="customContainer d-flex justify-content-between align-items-center h-100">
                            <div className="d-flex align-items-center topHeadLt">
                                {contactPhone && (
                                    <h6>
                                        Contact Us!{' '}
                                        <a href={`tel:${contactPhone}`}>{contactPhone}</a> or{' '}
                                    </h6>
                                )}
                                <a href={`mailto:${contactEmail}`}>Email Us</a>
                            </div>
                            <div className="h-100">
                                <ul className="d-flex justify-content-start align-items-center">
                                    <Headernavmenu type={'auctioneer'} />

                                    {isAuthenticated ? (
                                        <>
                                            {/* {user?.role == 0 ? (
                                            <li className="headerSellBtn">
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/becomeSeller?u=1"
                                                >
                                                    Become a Seller
                                                    <span className="material-icons">paid</span>
                                                </NavLink>
                                            </li>
                                        ) : null} */}
                                            <li className="crtIcnVw">
                                                <CartIcon />
                                            </li>
                                            <li>
                                                <Button
                                                    className="headerIcons acntAftrLgn"
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleMenu}
                                                >
                                                    <span className="material-icons mr-2">
                                                        account_circle
                                                    </span>{' '}
                                                    Hi, {user?.first_name}{' '}
                                                    <span className="material-icons ml-3">
                                                        expand_more
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={'simple-menu'}
                                                    open={Boolean(anchormenu)}
                                                    anchorEl={anchormenu}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul className="headerDropMenu">
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    `${
                                                                        user && user.role == 1
                                                                            ? 'dashboard/overview'
                                                                            : 'dashboard/mybids'
                                                                    }`,
                                                                )
                                                            }
                                                        >
                                                            Dashboard
                                                        </ListItem>

                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/profile',
                                                                )
                                                            }
                                                        >
                                                            Profile
                                                        </ListItem>
                                                        <ListItem button onClick={logoutUser}>
                                                            Logout
                                                        </ListItem>
                                                    </ul>
                                                </Popover>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                className="headerTopBtn signup"
                                                onClick={() =>
                                                    handleRedirectInternal(history, 'sign-up')
                                                }
                                            >
                                                Register
                                            </Button>
                                            <Button
                                                className="headerTopBtn login"
                                                onClick={() =>
                                                    handleRedirectInternal(history, 'login')
                                                }
                                            >
                                                Sign In
                                            </Button>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <header className="mainHeader noPrint">
                        <div className="customContainer d-flex align-items-center h-100">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Logo className="headerLogo" />
                            </div>
                            <div className="headRt d-flex justify-content-between align-items-center w-100">
                                <ul className="hdrRtLt d-flex justify-content-start align-items-center">
                                    {/* <li>
                                        <PrimaryButton onClick={toggleDrawer('left', true)}>
                                            Auctions
                                        </PrimaryButton>
                                    </li>

                                    <li>
                                        <Button
                                            onClick={() =>
                                                handleRedirectInternal(history, 'search')
                                            }
                                        >
                                            Buy
                                        </Button>
                                    </li> */}

                                    {user && user.role == 1 && (
                                        <>
                                            <li className="headerBtmDivider"></li>
                                            <li>
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleMenuAuction}
                                                >
                                                    Sell
                                                </Button>

                                                <Popover
                                                    id={'simple-menu'}
                                                    open={Boolean(auctionMenu)}
                                                    anchorEl={auctionMenu}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul className="headerDropMenu">
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(history, `post-auction`)
                                                            }
                                                        >
                                                            Post Auction
                                                        </ListItem>

                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'post-project?type=auction',
                                                                )
                                                            }
                                                        >
                                                            Post Single Lot
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'post-project?type=buynow',
                                                                )
                                                            }
                                                        >
                                                            Post Buy Now
                                                        </ListItem>
                                                    </ul>
                                                </Popover>
                                            </li>
                                        </>
                                    )}

                                    <li className="srchLstCntnr">
                                        <HeaderSearch />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="customContainer">
                        <div className="headBottomNav">
                           
                        </div>
                    </div> */}
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader mobHeader customContainer noPrint d-flex justify-content-between align-items-center">
                        <div className="col-3 pl-0">
                            <Button
                                className="respNavBtn mnLftNav"
                                onClick={toggleDrawer('left', true)}
                            >
                                <span className="material-icons">menu</span>
                            </Button>
                        </div>
                        <div className="col-6 headLt d-flex justify-content-center">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="headRt col-3">
                            <ul className="d-flex justify-content-between ml-auto align-items-center">
                                {/* <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('top', true)}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li> */}

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn crtIconBtn"
                                        onClick={() => handleRedirectInternal(history, 'cart')}
                                    >
                                        <CartIcon />
                                        {/* <span className="material-icons">shopping_cart</span> */}
                                    </Button>
                                </li>
                                <li className="headCart actnIcon">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">account_circle</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <HeaderSearch />
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer mnHdrLftDrwr"
                            anchor={'left'}
                            open={state['left']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('left', false)}
                            onOpen={toggleDrawer('left', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/" className="w-100 text-center">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('left', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('left', false)}>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/" exact>
                                            <span className="material-icons">home</span> Home
                                        </NavLink>
                                    </ListItem>
                                    <hr className="sdBrDvdr" />
                                    <h4 className="sdDvdrHdr">Buyer</h4>
                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/search">
                                            <span class="material-icons">manage_search</span>
                                            Auctions
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/search">
                                            <span class="material-icons">shopping_cart</span>
                                            Buy
                                        </NavLink>
                                    </ListItem>
                                    {/* <hr className="sdBrDvdr" />
                                    <h4 className="sdDvdrHdr">Seller</h4>

                                    <ListItem button>
                                        <a
                                            className={
                                                window.location.pathname.includes('/post-auction')
                                                    ? 'active'
                                                    : 'inactive'
                                            }
                                            onClick={() => handleSellClick('post-auction')}
                                        >
                                            <span class="material-icons">gavel</span>
                                            Post Auction
                                        </a>
                                    </ListItem>

                                    <ListItem button>
                                        <a
                                            className={
                                                window.location.pathname.includes(
                                                    '/post-project',
                                                ) && newUrl.get('type') == 'auction'
                                                    ? 'active'
                                                    : 'inactive'
                                            }
                                            onClick={() =>
                                                handleSellClick('post-project?type=auction')
                                            }
                                        >
                                            <span class="material-icons">inventory</span>
                                            Post Single Lot
                                        </a>
                                    </ListItem>

                                    <ListItem button>
                                        <a
                                            className={
                                                window.location.pathname.includes(
                                                    '/post-project',
                                                ) && newUrl.get('type') == 'buynow'
                                                    ? 'active'
                                                    : 'inactive'
                                            }
                                            onClick={() =>
                                                handleSellClick('post-project?type=buynow')
                                            }
                                        >
                                            <span class="material-icons">shopping_bag</span>
                                            Post Buy Now
                                        </a>
                                    </ListItem> */}
                                    <hr className="sdBrDvdr" />
                                    <h4 className="sdDvdrHdr">More</h4>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            <span className="material-icons">groups</span>
                                            How It Works
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/faq">
                                            <span className="material-icons">quiz</span>
                                            FAQs
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            <span className="material-icons">auto_stories</span>
                                            Terms & Conditions
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/privacy_policy">
                                            <span className="material-icons">privacy_tip</span>
                                            Privacy Policy
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/contact_us">
                                            <span className="material-icons">contact_support</span>
                                            Contact Us
                                        </NavLink>
                                    </ListItem>

                                    {/* {user && user.role == 0 && (
                                        <ListItem button>
                                            <NavLink
                                                exact
                                                to="#"
                                                activeClassName="active"
                                                onClick={() => {
                                                    setOpen(true)
                                                }}
                                            >
                                                Sell
                                            </NavLink>
                                        </ListItem>
                                    )} */}
                                    {/* {user && user.role == 1 && (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    exact
                                                    to="/post-project"
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Post a product
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    exact
                                                    to="/post-auction"
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Post an auction
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    exact
                                                    to="/post-auction"
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Auction Setup
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )} */}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer acntRtDrwr"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/" className="w-100 text-center">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    {isAuthenticated && (
                                        <>
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mybids"
                                                >
                                                    <span className="material-icons">
                                                        dashboard
                                                    </span>
                                                    Dashboard
                                                </NavLink>
                                            </ListItem>
                                            <hr className="sdBrDvdr" /> */}
                                            <h4 className="sdDvdrHdr">Buyer</h4>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mybids"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Bids
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/favorites"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    My Favorites
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/savedSearch"
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    Saved Search
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/preference"
                                                >
                                                    <span className="material-icons">
                                                        notifications
                                                    </span>
                                                    Preferences
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    Saved Cards
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/transactions"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    Transactions
                                                </NavLink>
                                            </ListItem>

                                            {/* {user && user.role != 1 && (
                                                <>
                                                    <hr className="sdBrDvdr mb-0" />
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/becomeSeller?u=1"
                                                        >
                                                            <span className="material-icons">
                                                                paid
                                                            </span>
                                                            Become a Seller
                                                        </NavLink>
                                                    </ListItem>
                                                </>
                                            )} */}

                                            {/* {user && user.role == 1 && (
                                                <>
                                                    <hr className="sdBrDvdr" />
                                                    <h4 className="sdDvdrHdr">Seller</h4>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/sellerdashboard"
                                                        >
                                                            <span className="material-icons">
                                                                store
                                                            </span>
                                                            My Auctions
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/mylots"
                                                        >
                                                            <span className="material-icons">
                                                                store
                                                            </span>
                                                            My Lots
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/reports"
                                                        >
                                                            <span className="material-icons">
                                                                description
                                                            </span>
                                                            Reports
                                                        </NavLink>
                                                    </ListItem>
                                                </>
                                            )} */}

                                            <hr className="sdBrDvdr" />
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/profile"
                                                >
                                                    <span className="material-icons">person</span>
                                                    My Account
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}
                                    {/* {global.storeDetails?.header_menu ? (
                                        <>
                                            {global.storeDetails?.header_menu.length > 0
                                                ? global.storeDetails.header_menu.map((val) => (
                                                      <ListItem button>
                                                          {val.subLinks.length > 0 ? (
                                                              <li>
                                                                  <div className="nav-menu-from-store">
                                                                      <a
                                                                          target={'_blank'}
                                                                          activeClassName="active"
                                                                          href={val.path}
                                                                      >
                                                                          <span className="material-icons">
                                                                              description
                                                                          </span>

                                                                          {val.label}
                                                                      </a>

                                                                      <Button
                                                                          aria-controls="simple-menu1"
                                                                          aria-haspopup="true"
                                                                          onClick={handleMenu1}
                                                                      >
                                                                          {!Boolean(anchormenu1) ? (
                                                                              <span className="material-icons-outlined">
                                                                                  expand_more
                                                                              </span>
                                                                          ) : (
                                                                              <span className="material-icons-outlined">
                                                                                  expand_less
                                                                              </span>
                                                                          )}
                                                                      </Button>
                                                                  </div>
                                                                  <Popover
                                                                      id={'simple-menu1'}
                                                                      open={Boolean(anchormenu1)}
                                                                      anchorEl={anchormenu1}
                                                                      onClose={handleClose}
                                                                      anchorOrigin={{
                                                                          vertical: 'bottom',
                                                                          horizontal: 'right',
                                                                      }}
                                                                      transformOrigin={{
                                                                          vertical: 'top',
                                                                          horizontal: 'right',
                                                                      }}
                                                                  >
                                                                      <ul className="headerDropMenu">
                                                                          {val.subLinks.map(
                                                                              (data) => (
                                                                                  <a
                                                                                      target={
                                                                                          '_blank'
                                                                                      }
                                                                                      activeClassName="active"
                                                                                      href={
                                                                                          data.path
                                                                                      }
                                                                                  >
                                                                                      <ListItem>
                                                                                          {
                                                                                              data.label
                                                                                          }
                                                                                      </ListItem>
                                                                                  </a>
                                                                              ),
                                                                          )}
                                                                      </ul>
                                                                  </Popover>
                                                              </li>
                                                          ) : (
                                                              <li>
                                                                  <a
                                                                      target={'_blank'}
                                                                      activeClassName="active"
                                                                      href={val.path}
                                                                  >
                                                                      <span className="material-icons">
                                                                          description
                                                                      </span>

                                                                      {val.label}
                                                                  </a>
                                                              </li>
                                                          )}
                                                          ,
                                                      </ListItem>
                                                  ))
                                                : ''}
                                        </>
                                    ) : (
                                        ''
                                    )} */}
                                    <Headernavmenu type="mobile" />

                                    {!isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/login">
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        app_registration
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/how_it_works"
                                                >
                                                    <span className="material-icons">groups</span>
                                                    How It Works
                                                </NavLink>
                                            </ListItem>
                                            {/* <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        how_to_reg
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem> */}
                                        </>
                                    ) : (
                                        <>
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/becomeSeller?u=1"
                                                >
                                                    <span className="material-icons">paid</span>
                                                    Become a Seller
                                                </NavLink>
                                            </ListItem> */}
                                            <ListItem button onClick={logoutUser}>
                                                <span className="material-icons">
                                                    power_settings_new
                                                </span>
                                                Logout
                                            </ListItem>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <PaymentPendingHeader />
            {isDesktop && (
                <React.Fragment>
                    <SwipeableDrawer
                        className="catgDrawer auctions"
                        anchor={'left'}
                        open={state['left']}
                        disableBackdropTransition={!iOS}
                        disableDiscovery={iOS}
                        disableSwipeToOpen={false}
                        onClose={toggleDrawer('left', false)}
                        onOpen={toggleDrawer('left', true)}
                    >
                        <div className="catgHead">
                            <h3>Auctions</h3>
                            <Button onClick={toggleDrawer('left', false)}>
                                <span className="material-icons">chevron_left</span>
                            </Button>
                        </div>
                        {viewAuction.length ? (
                            <>
                                <ul className="catgLinks" onClick={toggleDrawer('left', false)}>
                                    {viewAuction.map((data, index) => (
                                        <ListItem
                                            button
                                            className="homeCatgInner auctions"
                                            key={index}
                                            onClick={() => handleClick(data.id)}
                                        >
                                            <div className="auctionListInner">
                                                <h5>{data.title}</h5>
                                                <p>Total lots: {data.lotCount}</p>
                                            </div>
                                            <Timer
                                                date_added={data.date_added}
                                                date_closed={data.date_closed}
                                                withText={1}
                                                endText={'Time Left'}
                                                startText="Live Auction Starts In"
                                                theme="multiseller"
                                            />
                                        </ListItem>
                                    ))}
                                </ul>
                                <div className="px-3">
                                    <PrimaryButton
                                        className="viewAllAuc"
                                        onClick={() => handleClose(history, 'auctions')}
                                        label="View all auctions"
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="noCatg">
                                <img src="/assets/svg/noProduct.svg" alt="No Categories" />
                                <h6>No Auctions Found</h6>
                            </div>
                        )}
                    </SwipeableDrawer>
                </React.Fragment>
            )}
            <CustomDialog title={'Become A Seller'} open={open} function={toggleOpen}>
                <BecomeSeller open={open} setOpen={setOpen} />
            </CustomDialog>
        </>
    )
}

export default React.memo(Header)
