import React, { useState } from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { PrevArrow, NextArrow } from './Arrows'
const settings = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
}

const news = [
    {
        id: 1,
        img: '/phill/news1.png',
        title: 'Historic Paintings ',
        description:
            "The term 'history painting' was introduced by the French Royal Academy in the seventeenth century.It was seen as the most important type (or 'genre'), of painting above portraiture, the depiction of scenes from daily life (called genre painting), landscape and still life painting.sell with us (link to contact) or visit <a target='_blank' href='https://www.tate.org.uk/art/art-terms/h/history-painting'>https://www.tate.org.uk/art/art-terms/h/history-painting</a>",
    },
    {
        id: 2,
        img: '/phill/news2.png',
        title: 'Coin Collection',
        description:
            "One of the world's most popular hobbies Coin collecting is a passion shared by millions of people across the globe. It’s a hobby for all ages, whether you’re six, sixteen or 60. It’s a hobby that can be shared with friends and family, as you embark on coin adventures together, becoming ‘coin detectives’ searching for that elusive coin. buy in reverse auction  (link to reverse auction) or visit <a target='_blank' href='https://www.royalmint.com/collect/'>https://www.royalmint.com/collect/</a>",
    },
    {
        id: 3,
        img: 'https://d3rrucls05lm37.cloudfront.net/uploads/product/0e6252d6552cb4b9bbb20d877d21427070267a43.jpeg',
        title: 'Costume Jewellery',
        description:
            "This resulted in a number of years during which sterling silver costume jewelry was produced and some can still be found in today's vintage jewelry marketplace. Modern costume jewelry incorporates a wide range of materials. High-end crystals, cubic zirconia simulated diamonds, and some semi-precious stones are used in place of precious stones. Metals include gold- or silver-plated brass, and sometimes vermeil or sterling silver. Lower-priced jewelry may still use gold plating over pewter, nickel, or other metals; items made in countries outside the United States[dubious – discuss] may contain lead. Some pieces incorporate plastic, acrylic, leather, or wood.buy in online auction (link to reverse auction) or visit <a target='_blank' href='https://museumofcostumejewelry.com/'>https://museumofcostumejewelry.com/</a>",
    },
]

const News = () => {
    const [expand, setExpand] = useState(false)

    return (
        <div className="container insta-slider-wrapper">
            <Slider {...settings} className="insta-slider uac-slider news-wrapper">
                {news.map((item, index) => {
                    return (
                        <div className="card d-none" key={item.id}>
                            <img src={item.img} alt="" className="media-left" />
                            <div className="card-body">
                                <h3>{item.title}</h3>
                                <p
                                    className={`descrptnWrpr ${
                                        expand === `exp_${index}` ? 'expndWrp' : ''
                                    }`}
                                    key={index}
                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                ></p>
                                {expand === `exp_${index}` ? (
                                    <a className="sm-link" onClick={() => setExpand(false)}>
                                        See less
                                    </a>
                                ) : (
                                    <a
                                        className="sm-link"
                                        onClick={() => setExpand(`exp_${index}`)}
                                    >
                                        See more
                                    </a>
                                )}
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default News
