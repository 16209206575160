import React, { useState, useEffect } from 'react'
import './ChangePassword.css'
import axios from 'axios'
import Toaster from '../../../Component/Toaster'
import { Formik } from 'formik'
import { profile } from '../../../Services'
import * as yup from 'yup'
import { config } from '../../../Services/common.header'
import { set } from 'lodash'
import { getParameterByName } from '../../../utils/commonFunctions'
import { passwordChangedByAdmin } from '../../../Services/Socket.service'
import { authenticationService } from '../../../Services'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { Copyrightinfo } from '../../../utils'
import { useHistory } from 'react-router-dom'
import Layout from '../Layout'
import { Logo } from '../../../utils/index'

const ChangePassword = (props) => {
    let [error, setError] = useState(undefined)
    const history = useHistory()
    let schema = yup.object().shape({
        current_password:
            window.location.pathname === '/changepasswordusers'
                ? yup.string().min(8, 'Enter min 8 character').required('Enter valid password')
                : null,
        new_password: yup
            .string()
            .min(8, 'Enter min 8 character')
            .required('Enter valid new password'),
        confirm_new_password: yup
            .string()
            .min(8, 'Enter min 8 character')
            .required('Enter valid confirm password'),
    })

    const handleSubmit = async (value) => {
        //console.log('<==============form submit===========>')
        if (value.new_password !== value.confirm_new_password) {
            //console.log('<==============password mismatch===========>')
            setError({ message: 'Password mismatch' })
            setTimeout(() => setError(undefined), 2000)
        } else {
            //console.log('<=============validation success===========>')
            if (getParameterByName('id')) {
                let body = {
                    password: value.new_password,
                    user_token: getParameterByName('id'),
                }
                //console.log('<=============forget password working===========>')
                //console.log(body)
                let changePassword = await profile.forgetPassword(body)
                if (changePassword.success === 'yes') {
                    setError({ message: 'Password updated sucessfully' })
                    setTimeout(() => {
                        setError(undefined)
                        history.push('/login')
                    }, 1000)
                } else {
                    setError({ message: changePassword.reason })
                    setTimeout(() => setError(undefined), 2000)
                }
            } else {
                //console.log('<=============changePassword working===========>')
                //console.log(value)
                let passwordResponce = await profile.changePassword({
                    ...value,
                    user_type: 'old',
                })
                if (passwordResponce.data.status === 'no') {
                    setError({ message: passwordResponce.data.message })
                    setTimeout(() => setError(undefined), 2000)
                } else {
                    setError({ message: 'Password updated sucessfully' })
                    let userData = JSON.parse(localStorage.getItem('currentUser'))
                    userData.li_id = 0
                    localStorage.setItem('currentUser', JSON.stringify(userData))
                    history.push('/login')
                }
            }
        }
    }

    useEffect(() => {
        passwordChangedByAdmin(async (err, data) => {
            if (err) {
                //return;
                //console.log('passwordChanged --', err)
            } else {
                let userData = JSON.parse(localStorage.getItem('currentUser'))
                if (userData.id == config('id')) {
                    userData.li_id = 0
                    //console.log('userData=======', userData)
                    localStorage.setItem('currentUser', JSON.stringify(userData))
                    history.push('/login')
                    //console.log('passowrd changed for this user ==============================')
                }
            }
            //console.log('passwordChanged===============', data)
        })

        if (!window.location.pathname.includes('login') && !getParameterByName('id')) {
            let userData = JSON.parse(localStorage.getItem('currentUser'))
            if (userData != undefined && userData != '') {
                //console.log('li_id==>', userData.li_id)
                let user_token = authenticationService.currentUserValue.user_token
                let user_id = authenticationService.currentUserValue.id
                //axios.defaults.headers.common["Authorization"] = "Bearer " + user_token;
                return axios
                    .post(`${process.env.REACT_APP_URL}checkPasswordChanged/`, {}, config())
                    .then(function (response) {
                        //console.log(response.data)
                        if (response.data.li_id == 0) {
                            userData.li_id = 0
                            localStorage.setItem('currentUser', JSON.stringify(userData))
                            history.push('/login')
                        }
                    })
                    .catch(function (error) {
                        //console.log(error)
                    })
            }

            if (!config('id') || userData.li_id != 1) {
                //window.location.href = window.location.origin;
                return
            }
        }
    }, [])

    return (
        <Layout>
            <div className="clearfix row vcaLogin">
                <Toaster status={error} open={error !== undefined} />
                <div className="col-12">
                    <div className="loginFormBox py-5">
                        <div className="lgnOtrWrpr">
                            <Logo className="headerLogo" />
                            <Formik
                                initialValues={{
                                    current_password: '',
                                    new_password: '',
                                    confirm_new_password: '',
                                }}
                                validationSchema={schema}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <h1 className="mb-5">Change Password</h1>
                                        {window.location.pathname === '/changepasswordusers' && (
                                            <div className="input-group bda-input">
                                                <label>Old Password</label>
                                                <input
                                                    type="password"
                                                    className={`form-control reo-input ${
                                                        errors.current_password &&
                                                        touched.current_password &&
                                                        'is-invalid'
                                                    }`}
                                                    placeholder="Enter a old password"
                                                    name="current_password"
                                                    id="current_password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.current_password &&
                                                touched.current_password ? (
                                                    <div className="invalid-feedback">
                                                        {errors.current_password}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                        <div className="input-group bda-input">
                                            <label>New Password</label>
                                            <input
                                                type="password"
                                                className={`form-control reo-input ${
                                                    errors.new_password &&
                                                    touched.new_password &&
                                                    'is-invalid'
                                                }`}
                                                placeholder="Enter a new password"
                                                name="new_password"
                                                id="new_password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.new_password && touched.new_password ? (
                                                <div className="invalid-feedback">
                                                    {errors.new_password}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="input-group bda-input">
                                            <label>Confirm Password</label>
                                            <input
                                                type="password"
                                                className={`form-control reo-input ${
                                                    errors.confirm_new_password &&
                                                    touched.confirm_new_password &&
                                                    'is-invalid'
                                                }`}
                                                placeholder="Enter a new password"
                                                name="confirm_new_password"
                                                id="confirm_new_password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.new_password && touched.confirm_new_password ? (
                                                <div className="invalid-feedback">
                                                    {errors.new_password}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="d-flex justify-content-center forgotPasswordCta">
                                            <PrimaryButton label="Change Password" type="submit" />
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-6 d-none d-lg-block pad-0">
                    <div className="forgotPasswordImgBox d-flex justify-content-between flex-column">
                        <img
                            src={global?.storeDetails?.logoValue}
                            alt={`${global?.storeDetails?.name} logo`}
                        />
                        <Copyrightinfo />
                    </div>
                </div> */}
            </div>
        </Layout>
    )
}

export default ChangePassword
