import { DateTimePicker } from '@material-ui/pickers'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { mapData } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import ProductContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
const ScheduleLocalPickup = ({
    auctionData,
    sellerData,
    transferOtp,
    invoice_id,
    local_pickup_date,
}) => {
    const { updatelocalpickup, responseStatus } = useContext(ProductContext)
    const { setAlert } = useContext(AlertContext)
    const [btnLoading, setBtnLoading] = useState(false)
    const [storeTimings] = useState(
        global.pluginConfiguration?.schedule_pickup?.range_key
            ? JSON.parse(global.pluginConfiguration?.schedule_pickup?.range_key)
            : [],
    )
    const formik = useFormik({
        initialValues: {
            pickup_date: null,
            pickup_time: null,
        },
        onSubmit: (values) => {
            if (!values.pickup_date || !values.pickup_time) {
                return setAlert('Invalid Pickup Date And Time!', 'error')
            }
            setBtnLoading(true)
            updatelocalpickup({
                inv: invoice_id,
                sche_localpick: new Date(`${values.pickup_date} ${values.pickup_time}`),
                pickUpTime: 1,
                check_slot: 1,
                auction_io: 1,
            })
        },
    })

    const fields = [
        {
            label: 'Date ',
            placeholder: 'Date ',
            type: 'date',
            disablePast: true,
            class: `col-md-7`,
            name: 'pickup_date',
            formik: formik,
            dateFormat: 'MM-DD-YYYY',
            onChange: async (date) => {
                const storeTime = storeTimings.find(
                    (val) => val.day === moment(date).format('dddd'),
                )
                console.log(date, storeTime)
                await formik.setFieldTouched('pickup_date', true)
                if (storeTime) {
                    if (storeTime.range_from == storeTime.range_to) {
                        await formik.setFieldValue('pickup_date', moment(date).format('MM-DD-YYYY'))
                        await formik.setErrors({
                            pickup_date: `Store Is Leave On That Day! Kindly Select Other Date!`,
                        })
                    } else {
                        await formik.setFieldValue('pickup_date', moment(date).format('MM-DD-YYYY'))
                    }
                }
            },
        },
        {
            label: ' Time',
            placeholder: ' Time',
            type: 'time',
            disablePast: true,
            class: `col-md-5`,
            name: 'pickup_time',
            formik: formik,
            disabled: formik.values.pickup_date ? false : true,
            onChange: async (time) => {
                //console.log(time)
                const storeTime = storeTimings.find(
                    (val) => val.day === moment(formik.values.pickup_date).format('dddd'),
                )
                const minTime = moment(storeTime ? storeTime.range_from : '00:00', 'hh:mm A')
                const maxTime = moment(storeTime ? storeTime.range_to : '00:00', 'hh:mm A')
                const currentTime = time.format('hh:mm A')
                await formik.setFieldTouched('pickup_time', true)
                //console.log(currentTime)
                if (minTime.isBefore(time) && maxTime.isAfter(time)) {
                    //console.log(moment(time))
                    await formik.setFieldValue('pickup_time', moment(time).format('hh:mm A'))
                } else {
                    await formik.setFieldValue('pickup_time', moment(time).format('hh:mm A'))
                    await formik.setErrors({
                        pickup_time: `Select Between ${
                            storeTime ? storeTime.range_from : '00:00'
                        } and ${storeTime ? storeTime.range_to : '00:00'}`,
                    })
                }
            },
            dateTimeFormat: 'MM-DD-YYYY hh:mm A',
            ampm: true,
        },
    ]
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'updatelocalpickup') {
                setBtnLoading(false)
            }
        }
    }, [responseStatus])
    useEffect(() => {
        formik.setFieldValue('pickup_time', null)
    }, [formik.values.pickup_date])
    return global.pluginConfiguration?.schedule_pickup?.enable == 1 ? (
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                    <h5 className="mb-2">
                        <b>Hours of Operation</b>
                    </h5>
                    {storeTimings.map((val) => (
                        <p>
                            {val.day}:{' '}
                            {val.range_from == val.range_to
                                ? 'Leave'
                                : `${val.range_from} - ${val.range_to}`}
                        </p>
                    ))}
                </div>
                <div className="col-sm-7">
                    {global.storeConfigration?.include_auction_local_pickup?.value != 1 ? (
                        <>
                            <h5 className="mb-2">
                                <b>Local Pickup Address</b>
                            </h5>

                            <div>
                                {auctionData.address
                                    ? auctionData.address
                                    : sellerData.address1 + ' ' + sellerData?.address2}
                            </div>
                            <div>
                                {auctionData.city ? auctionData.city : sellerData.city}{' '}
                                {auctionData.state ? auctionData.state : sellerData.state}{' '}
                                {auctionData.zipcode ? auctionData.zipcode : sellerData.zipcode}
                            </div>
                        </>
                    ) : null}
                    {global.storeConfigration?.disabled_phone_from_posting?.value == 0 ||
                    global.storeConfigration?.disabled_phone_from_posting == undefined ? (
                        <div>Phone: {auctionData.phone ? auctionData.phone : sellerData.phone}</div>
                    ) : (
                        ''
                    )}
                    {global.storeConfigration?.payment_interval?.value == 1 ? (
                        <div>Pickup Code: {transferOtp}</div>
                    ) : (
                        ''
                    )}
                    <h5 className="mt-3 mb-0">
                        <strong>{global.storeConfigration?.local_pickup_description?.value}</strong>
                    </h5>
                    {local_pickup_date ? (
                        <h5 className="mb-2">
                            Your Scheduled Pickup Date{' '}
                            <b>{moment(local_pickup_date).format('MMM-DD-YYYY hh:mm A')}</b>
                        </h5>
                    ) : (
                        <>
                            <div className="row">
                                <h5 className="mb-2 col-12">
                                    <b>Select Schedule Pickup Date And Time</b>
                                </h5>
                                {mapData(fields)}
                                <div className="col-12">
                                    <PrimaryButton
                                        label={btnLoading ? 'Loading...' : 'Submit'}
                                        disabled={btnLoading}
                                        onClick={formik.isValid ? formik.handleSubmit : () => false}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    ) : null
}

export default ScheduleLocalPickup
