import React from 'react'
import { FacebookShareButton, FacebookIcon } from 'react-share'
const Facebook = ({ path, title }) => {
    return (
        <>
            <FacebookShareButton
                url={
                    window.location.href.includes(global?.storeDetails?.id)
                        ? `${window.location.origin}/${global?.storeDetails?.id}${path}`
                        : `${window.location.origin}${path}`
                }
                quote={title}
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
        </>
    )
}

export default Facebook
