import {
    BID_PRODUCT,
    GET_VIEW_PRODUCT,
    GET_BID_HISTORY,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_BUYER,
    GET_ALL_TRANSACTION,
    GET_BUYERS_LIST,
    GET_CHAT_HISTORY,
    GET_SHIPPING_RATE,
    GET_MAKEREQUEST_LIST,
    CLEAR_BUYER_STATE,
} from './buyerTypes'

export default (state, action) => {
    switch (action.type) {
        case BID_PRODUCT:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_VIEW_PRODUCT:
            return {
                ...state,
                product_view: action.payload,
            }
        case GET_BID_HISTORY:
            return {
                ...state,
                product_bidhistory: action.payload,
            }
        case GET_ALL_BUYER:
            return {
                ...state,
                buyer_allproducts: action.payload,
            }
        case GET_ALL_TRANSACTION:
            return {
                ...state,
                buyer_alltransaction: action.payload,
            }
        case GET_BUYERS_LIST:
            return {
                ...state,
                list_buyers: action.payload,
            }
        case GET_CHAT_HISTORY:
            return {
                ...state,
                chat_history: action.payload,
            }
        case GET_SHIPPING_RATE:
            return {
                ...state,
                shipping_rate_calculation: action.payload,
            }
        case GET_MAKEREQUEST_LIST:
            return {
                ...state,
                list_make_request: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case CLEAR_BUYER_STATE:
            return {
                ...state,
                buyer_allproducts: {
                    results: [],
                    total_pagecnt: 0,
                    total_only: 0,
                },
            }
        default:
            return state
    }
}
