import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Popup from '../../../Product/components/organisms/Popup'
import { Copyrightinfo, FooterLogo } from '../../../utils'
import MetaTag from '../../../utils/CommonFunctionality/SEO/MetaTag'
import { Button, Popover, ListItem, Divider, SwipeableDrawer } from '@material-ui/core'
import Footernav from '../../../utils/FooterNavigation/footernav'
import './Footer.css'

function Footer() {
    const [openModal, setModal] = useState(false)
    const [anchormenu, setanchormenu] = useState(null)

    const changeDialogStatus = () => {
        setModal(!openModal)
    }

    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagram = global?.storeDetails?.instagram
    return (
        <footer className="footer-main-standard noPrint">
            <div className="container">
                <div className="ftrWrprVw d-flex align-items-start justify-content-between">
                    <div className="lgAdCntnr">
                        <div className="ftrLgCntnr">
                            <FooterLogo className="footerLogo" />
                        </div>
                        <div className="adrsFlds d-md-block d-none">
                            <p>
                                {global?.storeSeller?.address1}{' '}
                                {global?.storeSeller?.address2 != '' &&
                                    global?.storeSeller?.address2 != null && (
                                        <>
                                            <br />
                                            {global?.storeSeller?.address2}
                                        </>
                                    )}
                                <br />
                                {global?.storeSeller?.city}, {global?.storeSeller?.state}
                                <br />
                                {global?.storeSeller?.country}, {global?.storeSeller?.zip}
                            </p>
                        </div>
                    </div>
                    <div className="ftrLnkWrpr d-flex align-items-start">
                        <div className="lnkGrdTb">
                            <h4>COMPANY</h4>
                            <ul className="footerLinks">
                                {global.storeConfigration?.external_schedule_pickup_link?.value && (
                                    <li>
                                        <a href="#" onClick={() => setModal(true)}>
                                            Schedule Pickup
                                        </a>
                                    </li>
                                )}
                                {global.storeDetails?.terms_condition && (
                                    <li>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            Terms & Conditions
                                        </NavLink>
                                    </li>
                                )}
                                {global.storeDetails?.terms && (
                                    <li>
                                        <NavLink activeClassName="active" to="/faq">
                                            FAQs
                                        </NavLink>
                                    </li>
                                )}
                                {global.storeDetails?.privacy && (
                                    <li>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            About Us
                                        </NavLink>
                                    </li>
                                )}

                                {global.storeDetails.privacy_policy ? (
                                    <li>
                                        <NavLink activeClassName="active" to="/privacy_policy">
                                            Privacy Policy
                                        </NavLink>
                                    </li>
                                ) : (
                                    ''
                                )}
                                {global.storeConfigration?.contact_us?.value ? (
                                    <li>
                                        <NavLink activeClassName="active" to="/contact_us">
                                            Contact Us
                                        </NavLink>
                                    </li>
                                ) : (
                                    ''
                                )}
                                {global.storeConfigration?.extra_static_pages?.value ? (
                                    <li>
                                        <NavLink activeClassName="active" to="/return_policy">
                                            Return Policy
                                        </NavLink>
                                    </li>
                                ) : (
                                    ''
                                )}
                                <Footernav />
                            </ul>
                        </div>

                        <div className="lnkGrdTb">
                            <h4>CONNECT</h4>
                            <ul className="footerLinks">
                                {facebookLink && (
                                    <li className="facebook">
                                        <a
                                            href={facebookLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Facebook"
                                        >
                                            <i className="fab fa-facebook"></i> Facebook
                                        </a>
                                    </li>
                                )}
                                {twitterLink && (
                                    <li className="pinterest">
                                        <a
                                            href={twitterLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Twitter"
                                        >
                                            Twitter
                                        </a>
                                    </li>
                                )}
                                {googleLink && (
                                    <li className="instagram">
                                        <a
                                            href={googleLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Linkedin"
                                        >
                                            Linkedin
                                        </a>
                                    </li>
                                )}
                                {instagram && (
                                    <li className="instagram">
                                        <a
                                            href={instagram}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Linkedin"
                                        >
                                            <i className="fab fa-instagram"></i>
                                            Instagram
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div className="lnkGrdTb">
                            <h4>CONTACT</h4>
                            <ul className="footerLinks">
                                {contactEmail && (
                                    <li>
                                        <a href={`mailto:${contactEmail}`} className="cvc-link">
                                            {contactEmail}
                                        </a>
                                    </li>
                                )}
                                {global.storeConfigration?.hide_phone?.value != 1 && contactPhone && (
                                    <li>
                                        <a href={`tel:${contactPhone}`} className="cvc-link">
                                            {contactPhone}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div className="lnkGrdTb d-md-none">
                            <h4>ADDRESS</h4>
                            <div className="adrsFlds">
                                <p>
                                    {global?.storeSeller?.address1}{' '}
                                    {global?.storeSeller?.address2 != '' &&
                                        global?.storeSeller?.address2 != null && (
                                            <>
                                                <br />
                                                {global?.storeSeller?.address2}
                                            </>
                                        )}
                                    <br />
                                    {global?.storeSeller?.city}, {global?.storeSeller?.state}
                                    <br />
                                    {global?.storeSeller?.country}, {global?.storeSeller?.zip}
                                </p>
                            </div>
                        </div>

                        {/* <ul className="footer-main-standard-social-list">
                            {facebookLink && (
                                <li className="facebook">
                                    <a
                                        href={facebookLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Facebook"
                                    >
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                </li>
                            )}
                            {twitterLink && (
                                <li className="pinterest">
                                    <a
                                        href={twitterLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Twitter"
                                    >
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                            )}
                            {googleLink && (
                                <li className="instagram">
                                    <a
                                        href={googleLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Linkedin"
                                    >
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                </li>
                            )}
                            {instagram && (
                                <li className="instagram">
                                    <a
                                        href={instagram}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Linkedin"
                                    >
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            )}
                        </ul> */}

                        {/* <div className="d-flex align-items-center justify-content-center flex-wrap">
                            {contactEmail && (
                                <>
                                    <a href={`mailto:${contactEmail}`} className="cvc-link mr-2">
                                        <span className="material-icons">drafts</span>
                                        {contactEmail}
                                    </a>
                                    <span>|</span>
                                </>
                            )}
                            {contactPhone && (
                                <a href={`tel:${contactPhone}`} className="cvc-link ml-2">
                                    <span className="material-icons">call</span>
                                    {contactPhone}
                                </a>
                            )}
                        </div> */}
                    </div>
                </div>
            </div>
            {global.storeConfigration?.meta_tag_pixel_pinacle?.value ? <MetaTag /> : ''}
            <div className="footer-copy-right">
                <Copyrightinfo />
                {/* <div className="container">
                </div> */}
            </div>
            <Popup
                open={openModal}
                size="md"
                handleClose={changeDialogStatus}
                modaltitle="Schedule Pickup"
            >
                <div className="schedulePickup">
                    <iframe
                        height={600}
                        src={global.storeConfigration?.external_schedule_pickup_link?.value}
                    ></iframe>
                </div>
            </Popup>
        </footer>
    )
}

export default Footer
