/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Button, Popover, ListItem, Divider, SwipeableDrawer, MenuItem } from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../../../utils/index'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import BecomeSeller from '../../../utils/CommonFunctionality/BecomeSeller'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import ProductContext from '../../../Product/context/product/productContext'
import Timer from '../../../Product/common/timer'
import { socketAuction } from '../../../Product/common/socket'
import PaymentPendingHeader from '../../../utils/CommonFunctionality/PaymentPendingHeader'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [auctionMenu, setAuctionMenu] = useState(null)
    const [open, setOpen] = useState(false)

    const { user, isAuthenticated, logout } = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions, search_allauctionproducts, getLotList } =
        productContext

    const [viewAuction, setViewAuction] = useState([])

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: '',
        type: '',
    })

    const { setAlert } = useContext(AlertContext)
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
        left: false,
    })

    const [listingAnchor, setListingAnchor] = useState(null)
    const listingOpen = Boolean(listingAnchor)

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'activeHeader')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'activeHeader') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    useEffect(() => {
        socketAuction.on('pluginMode', (data) => {
            if (global.storeDetails.site_id === data.site_id) {
                if (data.type === 'reverse_auction') {
                    if (parseInt(data.enable) == 0) {
                        // console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn', data)
                        setAlert('Reverse Auction Disabled By Seller', 'warning')
                    }
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            }
        })
    }, [])

    useEffect(() => {
        if (
            window.location.href.includes('/reverse-auction') ||
            window.location.href.includes('?id_new=')
        ) {
            if (!global.pluginConfiguration?.reverse_auction?.enable) {
                window.location.href = `/${global.storeDetails.id}/search`
            }
        }
    }, [])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleMenuAuction = (event) => {
        setAuctionMenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setAuctionMenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const handleListingClick = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setListingAnchor(event.currentTarget)
    }

    const handleListingClose = () => {
        setListingAnchor(null)
    }
    const toggleOpen = () => {
        setOpen(!open)
    }

    const filterValues = [
        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories.filter((main) => {
                return true
            }),
        },
    ]

    const logoutUser = () => {
        logout()
        history.push('/login')
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search/product-buyer-auction/${catId}`)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [window.location.pathname])

    return (
        <>
            {isDesktop ? (
                /* DESKTOP NAVIGATION */
                <header className="mainHeader noPrint">
                    <div className="customContainer">
                        <div className="topHdr d-flex align-items-center justify-content-between">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Logo className="headerLogo" />
                                <HeaderSearch />
                            </div>
                            <div className="headRt d-flex justify-content-start align-items-center">
                                {isAuthenticated ? (
                                    <ul className="d-flex justify-content-start align-items-center">
                                        <li>
                                            <CartIcon />
                                        </li>
                                        <li>
                                            <Button
                                                className="headerIcons"
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={handleMenu}
                                            >
                                                <span className="material-icons">
                                                    account_circle
                                                </span>
                                            </Button>

                                            <Popover
                                                id={'simple-menu'}
                                                open={Boolean(anchormenu)}
                                                anchorEl={anchormenu}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <ul className="headerDropMenu">
                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleClose(
                                                                history,
                                                                `${
                                                                    user && user.role == 1
                                                                        ? 'dashboard/overview'
                                                                        : 'dashboard/mybids'
                                                                }`,
                                                            )
                                                        }
                                                    >
                                                        Dashboard
                                                    </ListItem>

                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleClose(
                                                                history,
                                                                'dashboard/profile',
                                                            )
                                                        }
                                                    >
                                                        Profile
                                                    </ListItem>
                                                    <ListItem button onClick={logoutUser}>
                                                        Logout
                                                    </ListItem>
                                                </ul>
                                            </Popover>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul className="lgnSgnWrpr d-flex justify-content-start align-items-center">
                                        <span className="material-icons">account_circle</span>
                                        <li className="headerSellBtn">
                                            <NavLink activeClassName="active" to="/login">
                                                Login
                                            </NavLink>{' '}
                                            <span>/</span>{' '}
                                            <NavLink activeClassName="active" to="/sign-up">
                                                Sign up
                                            </NavLink>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                        <div className="bmtHdr d-flex align-items-center justify-content-between">
                            <div className="lftNvInfo">
                                <ul className="d-flex justify-content-start align-items-center">
                                    <li className="headerSellBtn">
                                        <NavLink activeClassName="active" to="/" exact>
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className="headerSellBtn">
                                        <NavLink activeClassName="active" to="/auctions" exact>
                                            Classic Auction
                                        </NavLink>
                                    </li>
                                    {global.pluginConfiguration?.reverse_auction?.enable ? (
                                        <li className="headerSellBtn">
                                            <NavLink activeClassName="active" to="/reverse-auction">
                                                Reverse Auction
                                            </NavLink>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    <li className="headerSellBtn">
                                        <NavLink activeClassName="active" to="/news">
                                            News
                                        </NavLink>
                                    </li>
                                    <li className="headerSellBtn">
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            How it Works
                                        </NavLink>
                                    </li>
                                    <li className="headerSellBtn">
                                        <NavLink activeClassName="active" to="/aboutus">
                                            About
                                        </NavLink>
                                    </li>

                                    <Headernavmenu type="desk" />
                                </ul>
                            </div>
                            {global.storeConfigration?.disable_phone_number?.value == 1 ? (
                                <div className="rtCntctInfo">
                                    <a href={`tel:${contactPhone}`}>{contactPhone}</a>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </header>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader mobHeader customContainer noPrint d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('top', true)}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>

                                <li className="headCart">
                                    <CartIcon />
                                </li>

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <HeaderSearch />
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/" exact>
                                            <span className="material-icons">home</span> Home
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/auctions">
                                            <span class="material-icons">manage_search</span>
                                            Auctions
                                        </NavLink>
                                    </ListItem>

                                    {/* <ListItem button>
                                        <NavLink activeClassName="active" exact to="/how_it_works">
                                            <span class="material-icons">style</span>
                                            Lifestyle Services
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/gallery">
                                            <span class="material-icons">collections</span>
                                            Shop The Gallery
                                        </NavLink>
                                    </ListItem> */}
                                    {/* {user && user.role == 0 && (
                                        <ListItem button>
                                            <NavLink
                                                exact
                                                to="#"
                                                activeClassName="active"
                                                onClick={() => {
                                                    setOpen(true)
                                                }}
                                            >
                                                Sell
                                            </NavLink>
                                        </ListItem>
                                    )} */}
                                    {user && user.role == 1 && (
                                        <>
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    exact
                                                    to="/post-project"
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Post a product
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    exact
                                                    to="/post-auction"
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Post an auction
                                                </NavLink>
                                            </ListItem> */}
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    exact
                                                    to="/post-auction"
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Auction Setup
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/dashboard/profile">
                                            <span className="material-icons">person</span>
                                            Profile
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/dashboard/favorites">
                                            <span className="material-icons">favorite</span>
                                            Favorites
                                        </NavLink>
                                    </ListItem>

                                    {isAuthenticated && (
                                        <>
                                            {user && user.role == 1 ? (
                                                <>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/overview"
                                                        >
                                                            <span className="material-icons">
                                                                dashboard
                                                            </span>
                                                            Dashboard
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/sellerdashboard"
                                                        >
                                                            <span className="material-icons">
                                                                gavel
                                                            </span>
                                                            My Auctions
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/reports"
                                                        >
                                                            <span className="material-icons">
                                                                description
                                                            </span>
                                                            Reports
                                                        </NavLink>
                                                    </ListItem>

                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/onboard"
                                                        >
                                                            <span className="material-icons">
                                                                payments
                                                            </span>
                                                            Payment Gateway Onboard
                                                        </NavLink>
                                                    </ListItem>
                                                </>
                                            ) : (
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/mybids"
                                                    >
                                                        <span className="material-icons">
                                                            gavel
                                                        </span>
                                                        My Bids
                                                    </NavLink>
                                                </ListItem>
                                            )}

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    Saved Cards
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/savedSearch"
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    Saved Search
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/preference"
                                                >
                                                    <span className="material-icons">
                                                        notifications
                                                    </span>
                                                    Notification Preferences
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/transactions"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    Transactions
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            <span className="material-icons">groups</span>
                                            About Us
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/faq">
                                            <span className="material-icons">quiz</span>
                                            FAQs
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            <span className="material-icons">auto_stories</span>
                                            Terms & Conditions
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            <span className="material-icons">quiz</span>
                                            How It Works
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/contact_us">
                                            <span className="material-icons">contact_support</span>
                                            Contact Us
                                        </NavLink>
                                    </ListItem>

                                    <Headernavmenu type="mobile" />

                                    {!isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/login">
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        how_to_reg
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <ListItem button onClick={logoutUser}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItem>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <PaymentPendingHeader />
            <React.Fragment>
                <SwipeableDrawer
                    className="catgDrawer auctions"
                    anchor={'left'}
                    open={state['left']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                >
                    <div className="catgHead">
                        <h3>Auctions</h3>
                        <Button onClick={toggleDrawer('left', false)}>
                            <span className="material-icons">chevron_left</span>
                        </Button>
                    </div>
                    {viewAuction.length ? (
                        <>
                            <ul className="catgLinks" onClick={toggleDrawer('left', false)}>
                                {viewAuction.map((data, index) => (
                                    <ListItem
                                        button
                                        className="homeCatgInner auctions"
                                        key={index}
                                        onClick={() => handleClick(data.id)}
                                    >
                                        <div className="auctionListInner">
                                            <h5>{data.title}</h5>
                                            {/* <p>Total lots: {data.lotCount}</p> */}
                                        </div>
                                        <Timer
                                            date_added={data.date_added}
                                            date_closed={data.date_closed}
                                            withText={1}
                                            endText={'Time Left'}
                                            startText="Live Auction Starts In"
                                            theme="multiseller"
                                        />
                                    </ListItem>
                                ))}
                            </ul>
                            <Button
                                className="viewAllAuc"
                                onClick={() => handleClose(history, 'auctions')}
                            >
                                View all auctions
                            </Button>
                        </>
                    ) : (
                        <div className="noCatg">
                            <img src="/assets/svg/noProduct.svg" alt="No Categories" />
                            <h6>No Auctions Found</h6>
                        </div>
                    )}
                </SwipeableDrawer>
            </React.Fragment>
            <CustomDialog title={'Become A Seller'} open={open} function={toggleOpen}>
                <BecomeSeller open={open} setOpen={setOpen} />
            </CustomDialog>
        </>
    )
}

export default React.memo(Header)
