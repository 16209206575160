import React, { useState, useEffect, useContext } from 'react'
import Slider from 'react-slick'
import { Link, useHistory } from 'react-router-dom'
import { PrevArrow, NextArrow } from './Arrows'
import ProductContext from '../../../Product/context/product/productContext'
import { dateFormatFront, handleRedirectInternal } from '../../../Product/common/components'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'

const settings = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
}

const auctions = [
    {
        id: 1,
        img: '/phill/ua-img.png',
        title: 'Fine Arts Auction',
        type: 'Online Auction',
        date: 'Feb 26th to March 17th',
        no_lots: 126,
    },
    {
        id: 2,
        img: '/phill/ug-img1.png',
        title: 'Antique Furniture Auction',
        type: 'Online Auction',
        date: 'Feb 26th to March 17th',
        no_lots: 126,
    },
    {
        id: 3,
        img: '/phill/ua-img.png',
        title: 'Fine Arts Auction',
        type: 'Online Auction',
        date: 'Feb 26th to March 17th',
        no_lots: 126,
    },
]

const UpcomingAuctions = ({ title }) => {
    const history = useHistory()
    const productContext = useContext(ProductContext)
    const { search_allauctions, getAllSearchAuctions } = productContext
    const [viewAuction, setViewAuction] = useState([])

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: 1,
        lot_images: 1,
        type: '',
        timed_live: 'timed',
        is_auction: 1,
    })

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'upcoming')
    }, [auctionSearch])

    useEffect(() => {
        if (search_allauctions.from === 'upcoming') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    return (
        <div className="container insta-slider-wrapper">
            {viewAuction.length > 0 ? (
                <Slider {...settings} className="insta-slider uac-slider upComingSlider">
                    {viewAuction.map((item, index) => {
                        return (
                            <div className="card" key={item.id}>
                                <img
                                    src={process.env.REACT_APP_PRODUCT_IMAGE_URL + item.file_name}
                                    alt=""
                                    className="media-left"
                                />
                                <div className="card-body">
                                    <h3>{item.title}</h3>
                                    {/* <p>Forward Auction</p> */}
                                    <p className="ua-date">
                                        <i className="material-icons">calendar_today</i>
                                        {dateFormatFront(item.date_added)}
                                    </p>
                                    {/* <p className="ua-lots">{item.lotCount} lots</p> */}
                                    <PrimaryButton
                                        className="btn btn-white-trans"
                                        onClick={() => {
                                            handleRedirectInternal(
                                                history,
                                                `search/product-buyer-auction/${item.id}`,
                                            )
                                        }}
                                    >
                                        View Auction
                                    </PrimaryButton>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            ) : (
                <NoRecordsFound />
            )}
        </div>
    )
}

export default UpcomingAuctions
